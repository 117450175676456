import {
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import { db } from "../firebase";

/**
 * Sends a message by adding a document to the "messages" collection in Firestore.
 *
 * @param phoneNumber - The recipient's phone number without the country code.
 * @param customOrderId - The custom order ID associated with the message.
 * @param message - The message to send.
 */

export function getFormalName(name: string): string {
  // Step 1: Trim the string to remove leading and trailing spaces
  const trimmedName = name.trim();

  // Step 2: Split the string into an array of words
  const words = trimmedName.split(" ");

  // Step 3: Remove the last word if there is more than one word
  if (words.length > 1) {
    words.pop();
  }

  // Step 4: Join the remaining words back into a single string
  return words.join(" ");
}

export async function sendSMS(
  phoneNumber: string,
  customOrderId: string,
  message: string
): Promise<void> {
  const messagesCollection = collection(db, "messages");

  console.log(
    "pjone number",
    phoneNumber,
    "customOrderId",
    customOrderId,
    "message",
    message
  );

  let suffix = 1;
  const maxSuffix = 50;
  let docId: string;
  let docRef;
  let docSnap;

  try {
    while (suffix <= maxSuffix) {
      docId = `${customOrderId}${suffix}`;
      docRef = doc(messagesCollection, docId);
      docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        await setDoc(docRef, {
          to: "+47" + phoneNumber,
          from: "Fikse",
          body: message,
        });
        console.log(`Message sent successfully with ID: ${docId}`);
        return;
      }

      suffix += 1;
    }

    throw new Error(
      `Unable to find an available document ID for customOrderId: ${customOrderId}`
    );
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
}

export async function getPhoneNrFromOrder(
  orderId: string,
  b2cOrder: boolean
): Promise<string> {
  const orderRef = doc(db, `${b2cOrder ? "b2cOrders" : "workwearOrders"}`, orderId);
  const orderSnap = await getDoc(orderRef);
  if (!orderSnap.exists()) {
    throw new Error("Order does not exist");
  }
  const orderData = orderSnap.data();
  return orderData.contactInfo.phoneNumber;
}

export async function deviateNoticeToCustomer(
  orderType: string,
  orderId: string,
  t: any
) {
  try {
    const orderRef = doc(db, orderType, orderId);
    const orderSnapshot = await getDoc(orderRef);

    if (orderSnapshot.exists()) {
      const currentOrderData = orderSnapshot.data();
      console.log("Current order data", currentOrderData);

      console.log("Order updated successfully with deviation activity");
      const phoneNumber = currentOrderData.contactInfo.phone;
      const username = getFormalName(currentOrderData.contactInfo.name);
      const userEmail = currentOrderData.email;
      let userDomain = "";

      if (
        userEmail &&
        (userEmail.endsWith("oslo.kommune.no") ||
          userEmail.endsWith("osloskolen.no") ||
          userEmail.endsWith("fikse.co") ||
          userEmail.endsWith("ntnu.no") ||
          userEmail.endsWith("egms.no"))
      ) {
        userDomain = "https://oslo.kommune.fikse.co/a/";
      } else {
        userDomain = "https://fikse.co/a/";
      }
      const message =
        t("hello") +
        " " +
        username +
        ",\n\n" +
        t("deviationMessage") +
        "\n\n" +
        userDomain +
        orderId.slice(0, 5);

      await sendSMS(phoneNumber, orderId, message);
      console.log("SMS sent to customer, order deviated", phoneNumber, orderId);
    } else {
      console.log("Order does not exist");
    }
  } catch (error) {
    console.error("Error updating order:", error);
  }
}

export const cancelOrderNoticeToCustomer = async (
  orderType: string,
  orderId: string,
  t: any
) => {
  try {
    const orderRef = doc(db, orderType, orderId);
    console.log("OD3");

    // Get the current document
    const orderSnapshot = await getDoc(orderRef);

    if (orderSnapshot.exists()) {
      const currentOrderData = orderSnapshot.data();

      console.log("Order updated successfully with cancellation activity");
      const phoneNumber = currentOrderData.contactInfo.phone;
      const username = getFormalName(currentOrderData.contactInfo.name);
      const userEmail = currentOrderData.email;
      let userDomain = "";

      if (
        userEmail &&
        (userEmail.endsWith("oslo.kommune.no") ||
          userEmail.endsWith("osloskolen.no") ||
          userEmail.endsWith("fikse.co") ||
          userEmail.endsWith("ntnu.no") ||
          userEmail.endsWith("egms.no"))
      ) {
        userDomain = "https://oslo.kommune.fikse.co/a/";
      } else {
        userDomain = "https://fikse.co/a/";
      }
      const message =
        t("hello") +
        " " +
        username +
        ",\n\n" +
        t("cancelMessage") +
        "\n\n" +
        userDomain +
        orderId.slice(0, 5);

      await sendSMS(phoneNumber, orderId, message);
      console.log("SMS sent to customer, order cancelled", phoneNumber, orderId);
    } else {
      console.log("Order does not exist");
    }
  } catch (error) {
    console.error("Error updating order:", error);
  }
};
