import React, { useState } from "react";

import { RotateCcw } from "lucide-react";
import { useTranslation } from "react-i18next";

import ModalTile from "../../components/ModalTile";
import { fallbackImageUrl } from "../../components/newOrder/OrderModal";
import { ActivityItem, Order, orderStates as fullOrderState } from "../OrderPage";
import OrderCardDetails from "./OrderCardDetails";

// Add new prop for handling reverts
const OrderLog: React.FC<{
  order: Order;
  activity: any[];
  onRevert?: (activityId: number) => Promise<void>;
}> = ({ order, activity, onRevert }) => {
  const { t } = useTranslation();
  const [revertModalOpen, setRevertModalOpen] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState<number | null>(null);
  const [isReverting, setIsReverting] = useState(false);

  const currentUserEmail = localStorage.getItem("uid");
  const userRoles = localStorage.getItem("roles")?.split(",") || [];

  //Brukes for å filterer bort unskede flyter i ordere
  const orderStates = order?.theyDeliver
    ? fullOrderState.filter((state) => state !== "orderStateCustomerDropOff")
    : fullOrderState;

  const lastActivityWithOrderState = [...activity]
    .reverse()
    .find((event) => orderStates.includes(event.content));

  const handleRevertClick = (activityId: number) => {
    setSelectedActivityId(activityId);
    setRevertModalOpen(true);
  };

  const handleConfirmRevert = async () => {
    if (!selectedActivityId || !onRevert) return;

    setIsReverting(true);
    try {
      await onRevert(selectedActivityId);
      setRevertModalOpen(false);
    } catch (error) {
      console.error("Failed to revert activity:", error);
    } finally {
      setIsReverting(false);
    }
  };

  const canRevert = (event: ActivityItem, eventIdx: number) => {
    if (eventIdx !== activity.length - 1) return false; // Only allow reverting last activity
    if (event.handler == currentUserEmail) return true; // Must be created by current user
    if (userRoles.includes("operator")) return true; // Must be an operator

    const content = event.content;
    // Can revert only if matches one of the showUpdateStatus conditions
    return (
      content === "orderStateCreated" ||
      content === "orderStatePickedUpFromTailor" ||
      (content === "orderStateDeliverToTailor" && userRoles.includes("tailor")) ||
      ((content === "orderStateCustomerDropOff" || content === "orderStateRepaired") &&
        userRoles.includes("transporter")) ||
      (content === "orderStatePickedUpFromTailor" && userRoles.includes("courier"))
    );
  };

  return (
    <div>
      <div
        className={`hidden ${
          order.orderInfo.some((info) => info.imageUrl !== fallbackImageUrl)
            ? "min-h-[calc(55vh-7rem)]"
            : ""
        } md:block`}
      >
        <OrderCardDetails order={order} />
      </div>

      <p className="border-b border-black p-4 pt-16 text-base">
        {t("workwearOrderPage.trackingLog")}{" "}
      </p>
      <ul role="list" className="-mb-8 min-h-[478px]">
        {activity.map((event, eventIdx) => {
          const message = event.content
            ? t(`workwearOrderPage.${event.content}`)
            : event.message;
          const timestamp = event.dateTime || event.timestamp;
          const formattedTime = `${new Date(timestamp).toLocaleDateString(
            "en-GB"
          )} ${new Date(timestamp).toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
          })}`;
          return (
            <li
              key={event.id || `${timestamp}-${eventIdx}`}
              className={`border-b border-black p-4 text-base ${
                eventIdx === activity.length - 1
                  ? "bg-fikseBeige text-black"
                  : "text-fikseGrey"
              }`}
            >
              <span className="flex items-center justify-between">
                <span>{message}</span>
                {canRevert(event, eventIdx) && (
                  <button
                    onClick={() => handleRevertClick(event.id)}
                    disabled={isReverting}
                    className="hover:text-fikseBlueHover inline-flex items-center gap-2 text-base font-bold text-fikseRed disabled:opacity-50"
                  >
                    <RotateCcw className="h-4 w-4" />
                    {t("revert")}
                  </button>
                )}
                <span className="ml-1 flex items-center gap-2">
                  <time dateTime={timestamp}>{formattedTime}</time>
                </span>
              </span>
            </li>
          );
        })}

        {orderStates
          .slice(orderStates.slice(1).indexOf(lastActivityWithOrderState?.content) + 2)
          .map((state, index) => (
            <li
              key={`future-state-${index}`}
              className="border-b border-black p-4 text-base text-fikseGrey"
            >
              {t(`workwearOrderPage.${state}`)}
            </li>
          ))}
      </ul>

      <ModalTile
        open={revertModalOpen}
        onClose={() => setRevertModalOpen(false)}
        title={t("revertActivity")}
      >
        <div className="space-y-6">
          <p className="text-base">{t("revertConfirmation")}</p>
          <div className="flex w-full border-t border-black">
            <button
              type="button"
              className="flex-1 border-r border-black bg-fikseBeige p-4 text-base hover:bg-fikseBeigeHover"
              onClick={() => setRevertModalOpen(false)}
              disabled={isReverting}
            >
              {t("cancel")}
            </button>
            <button
              type="button"
              className="hover:bg-fikseRedHover flex-1 bg-fikseRed p-4 text-base"
              onClick={handleConfirmRevert}
              disabled={isReverting}
            >
              {t("confirm")}
            </button>
          </div>
        </div>
      </ModalTile>
    </div>
  );
};

export default OrderLog;
