import { useEffect, useState } from "react";

import { addDoc, collection, getDocs } from "firebase/firestore";

// Update imports
import { db } from "../../firebase";
import EditTailorModal from "./EditTailorModal";

// Import modal component

export interface Tailor {
  id: string;
  name: string;
  orgNumber: string;
  address: string;
  contactPerson: {
    name: string;
    phone: string;
    email?: string; // Changed to required field
  };
  cut: {
    agreeBefore: boolean;
    cleaning: number | null;
    repairs: number | null;
  };
  preVerifiedEmails?: string[];
  moderatorEmails?: string[]; // Add this line
}

export default function TailorOverview() {
  const [tailors, setTailors] = useState<Tailor[]>([]);
  const [stats, setStats] = useState({
    totalTailors: 0,
    agreeBefore: 0,
    cleaning: 0,
    repairs: 0,
  });
  const [selectedTailor, setSelectedTailor] = useState<Tailor | null>(null);

  const isSpecialId = (id: string) => {
    const specialIds = [
      "ADMINBYPASS",
      "COURIERtoDropOffLocation",
      "TAILORtoFikseFerdig",
      "TRANSPORTERtoTailor",
    ];
    return specialIds.includes(id);
  };

  useEffect(() => {
    const fetchTailors = async () => {
      const querySnapshot = await getDocs(collection(db, "tailors"));
      const tailorList: Tailor[] = querySnapshot.docs
        .filter((doc) => !isSpecialId(doc.id))
        .map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            name: data.name || "",
            orgNumber: data.orgNumber || "",
            address: data.address || "",
            contactPerson: {
              name: data.contactPerson?.name || "",
              phone: data.contactPerson?.phone || "",
              email: data.contactPerson?.email || "", // Added email
            },
            cut: {
              agreeBefore: data.cut?.agreeBefore || false,
              cleaning: data.cut?.cleaning || null,
              repairs: data.cut?.repairs || null,
            },
            preVerifiedEmails: data.preVerifiedEmails || [], // Added this
            moderatorEmails: data.moderatorEmails || [], // Added this
          };
        });

      setTailors(tailorList);

      const totalTailors = tailorList.length;
      const agreeBefore = tailorList.filter((tailor) => tailor.cut?.agreeBefore).length;
      const cleaning = tailorList.filter(
        (tailor) => tailor.cut?.cleaning !== null
      ).length;
      const repairs = tailorList.filter((tailor) => tailor.cut?.repairs !== null).length;

      setStats({ totalTailors, agreeBefore, cleaning, repairs });
    };

    fetchTailors();
  }, []);
  const handleAddTailor = async () => {
    const name = prompt("Enter tailor name:");
    if (!name) return;

    const orgNumber = prompt("Enter organization number:");
    if (!orgNumber) return;

    const newTailor = {
      name,
      orgNumber,
      address: "",
      contactPerson: {
        name: "",
        phone: "",
        email: "",
      },
      cut: {
        agreeBefore: false,
        cleaning: null,
        repairs: null,
      },
      preVerifiedEmails: [],
      moderatorEmails: [],
    };

    try {
      const docRef = await addDoc(collection(db, "tailors"), newTailor);
      setTailors([...tailors, { ...newTailor, id: docRef.id }]);
    } catch (error) {
      console.error("Error adding tailor:", error);
    }
  };

  return (
    <div className="m-12 mx-8">
      <h2 className="mb-4 text-2xl font-bold text-gray-900">Tailors</h2>

      <div className="mb-6 grid grid-cols-1 gap-0 sm:grid-cols-2 lg:grid-cols-4">
        <div className="border border-black bg-white p-4 shadow">
          <div className="text-gray-500">Total number of tailors</div>
          <div className="text-2xl font-bold text-gray-900">{stats.totalTailors}</div>
        </div>
        <div className="border border-black bg-white p-4 shadow">
          <div className="text-gray-500">Agree price before repair</div>
          <div className="text-2xl font-bold text-gray-900">{stats.agreeBefore}</div>
        </div>
        <div className="border border-black bg-white p-4 shadow">
          <div className="text-gray-500">Cleaning agreements</div>
          <div className="text-2xl font-bold text-gray-900">{stats.cleaning}</div>
        </div>
        <div className="border border-black bg-white p-4 shadow">
          <div className="text-gray-500">Repair agreements</div>
          <div className="text-2xl font-bold text-gray-900">{stats.repairs}</div>
        </div>
      </div>

      <ul role="list" className="grid grid-cols-1 gap-0 lg:grid-cols-4 xl:gap-0">
        {tailors.map((tailor) => (
          <li
            key={tailor.id}
            className="overflow-hidden border border-black border-gray-200 p-6"
          >
            <h3 className="text-lg font-bold text-gray-900">{tailor.name}</h3>
            <p className="text-sm text-gray-500">Doc ID: {tailor.id}</p>
            <p className="text-sm text-gray-500">Org no: {tailor.orgNumber}</p>
            <p className="text-sm text-gray-500">Address: {tailor.address}</p>
            <p className="text-sm text-gray-500">
              Contact person: {tailor.contactPerson?.name || "N/A"}
            </p>
            <p className="text-sm text-gray-500">
              Phone: {tailor.contactPerson?.phone || "N/A"}
            </p>
            <p className="text-sm text-gray-500">
              Email: {tailor.contactPerson?.email || "N/A"}
            </p>
            <div className="mt-4">
              <h4 className="text-md font-semibold text-gray-900">
                Agreements with Fikse - Cut:
              </h4>
              {tailor.cut?.agreeBefore ? (
                <p className="text-sm text-gray-500">Agreed before repair</p>
              ) : (
                <>
                  {tailor.cut?.cleaning !== null && (
                    <p className="text-sm text-gray-500">
                      Cleaning: {(tailor.cut?.cleaning || 0) * 100}%
                    </p>
                  )}
                  {tailor.cut?.repairs !== null && (
                    <p className="text-sm text-gray-500">
                      Repairs and adjustments: {(tailor.cut?.repairs || 0) * 100}%
                    </p>
                  )}
                </>
              )}
            </div>
            <div className="mt-4">
              <h4 className="text-md font-semibold text-gray-900">
                Pre-verified Emails:
              </h4>
              {tailor.preVerifiedEmails?.map((email, index) => (
                <p key={index} className="text-sm text-gray-500">
                  {email}
                </p>
              ))}
            </div>
            <div className="mt-4">
              <h4 className="text-md font-semibold text-gray-900">Moderator Emails:</h4>
              {tailor.moderatorEmails?.map((email, index) => (
                <p key={index} className="text-sm text-gray-500">
                  {email}
                </p>
              ))}
            </div>
            <button
              className="mt-4 text-sm text-[#8D4304] hover:underline"
              onClick={() => setSelectedTailor(tailor)}
            >
              Edit
            </button>
          </li>
        ))}
      </ul>

      {selectedTailor && (
        <EditTailorModal
          tailor={selectedTailor}
          onClose={() => setSelectedTailor(null)}
          onSave={(updatedTailor) => {
            setTailors((prevTailors) =>
              prevTailors.map((tailor) =>
                tailor.id === updatedTailor.id ? updatedTailor : tailor
              )
            );
            setSelectedTailor(null);
          }}
        />
      )}

      <button
        className="mt-8 rounded bg-[#8CD7FF] px-4 py-2 text-white hover:bg-[#7bc4eb]"
        onClick={handleAddTailor}
      >
        Add New Tailor
      </button>
    </div>
  );
}
