import { useState } from "react";

import { Dialog } from "@headlessui/react";
import { doc, updateDoc } from "firebase/firestore";

import { db } from "../../firebase";
import { Tailor } from "./TailorOverview";

interface EditTailorModalProps {
  tailor: Tailor;
  onClose: () => void;
  onSave: (updatedTailor: Tailor) => void;
}

const isContactPersonKey = (key: string): key is keyof Tailor["contactPerson"] => {
  return ["name", "phone", "email"].includes(key);
};

const isCutKey = (key: string): key is keyof Tailor["cut"] => {
  return ["agreeBefore", "cleaning", "repairs"].includes(key);
};

export default function EditTailorModal({
  tailor,
  onClose,
  onSave,
}: EditTailorModalProps) {
  const [formData, setFormData] = useState(tailor);
  const [newPreVerifiedEmail, setNewPreVerifiedEmail] = useState("");
  const [newModeratorEmail, setNewModeratorEmail] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name.startsWith("contactPerson.")) {
      const key = name.split(".")[1];
      if (isContactPersonKey(key)) {
        setFormData({
          ...formData,
          contactPerson: { ...formData.contactPerson, [key]: value },
        });
      }
    } else if (name.startsWith("cut.")) {
      const key = name.split(".")[1];
      if (isCutKey(key)) {
        setFormData({
          ...formData,
          cut: {
            ...formData.cut,
            [key]:
              value === ""
                ? null
                : key === "agreeBefore"
                  ? !formData.cut.agreeBefore
                  : parseFloat(value) / 100,
          },
        });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleAddPreVerifiedEmail = () => {
    if (
      newPreVerifiedEmail &&
      !formData.preVerifiedEmails?.includes(newPreVerifiedEmail)
    ) {
      setFormData({
        ...formData,
        preVerifiedEmails: [...(formData.preVerifiedEmails || []), newPreVerifiedEmail],
      });
      setNewPreVerifiedEmail("");
    }
  };

  const handleRemovePreVerifiedEmail = (email: string) => {
    setFormData({
      ...formData,
      preVerifiedEmails: formData.preVerifiedEmails?.filter((e) => e !== email) || [],
    });
  };

  const handleAddModeratorEmail = () => {
    if (newModeratorEmail && !formData.moderatorEmails?.includes(newModeratorEmail)) {
      setFormData({
        ...formData,
        moderatorEmails: [...(formData.moderatorEmails || []), newModeratorEmail],
      });
      setNewModeratorEmail("");
    }
  };

  const handleRemoveModeratorEmail = (email: string) => {
    setFormData({
      ...formData,
      moderatorEmails: formData.moderatorEmails?.filter((e) => e !== email) || [],
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const tailorRef = doc(db, "tailors", formData.id);

    const updateData = {
      name: formData.name,
      orgNumber: formData.orgNumber,
      address: formData.address,
      contactPerson: formData.contactPerson,
      cut: formData.cut,
      preVerifiedEmails: formData.preVerifiedEmails,
      moderatorEmails: formData.moderatorEmails,
    };

    await updateDoc(tailorRef, updateData);
    onSave(formData);
  };

  return (
    <Dialog open={true} onClose={onClose} className="relative z-10">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <Dialog.Panel className="relative w-full max-w-md transform overflow-hidden bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
            <div className="">
              <Dialog.Title
                as="h3"
                className="text-lg font-semibold leading-6 text-gray-900"
              >
                Edit Tailor
              </Dialog.Title>
              <form className="mt-2 space-y-4" onSubmit={handleSubmit}>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-[#8CD7FF] focus:ring-[#8CD7FF] sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Org Number
                  </label>
                  <input
                    type="text"
                    name="orgNumber"
                    value={formData.orgNumber}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-[#8CD7FF] focus:ring-[#8CD7FF] sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Address
                  </label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-[#8CD7FF] focus:ring-[#8CD7FF] sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Contact person name
                  </label>
                  <input
                    type="text"
                    name="contactPerson.name"
                    value={formData.contactPerson.name}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-[#8CD7FF] focus:ring-[#8CD7FF] sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Contact person phone
                  </label>
                  <input
                    type="text"
                    name="contactPerson.phone"
                    value={formData.contactPerson.phone}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-[#8CD7FF] focus:ring-[#8CD7FF] sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Contact person email
                  </label>
                  <input
                    type="email"
                    name="contactPerson.email"
                    value={formData.contactPerson.email}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-[#8CD7FF] focus:ring-[#8CD7FF] sm:text-sm"
                  />
                </div>

                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Agree price before repair
                  </label>
                  <input
                    type="checkbox"
                    name="cut.agreeBefore"
                    checked={formData.cut.agreeBefore}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        cut: { ...formData.cut, agreeBefore: e.target.checked },
                      })
                    }
                    className="form-checkbox mt-1 h-5 w-5 border-gray-300 p-2 text-[#8CD7FF] shadow-sm focus:border-[#8CD7FF] focus:ring-[#8CD7FF] sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Cleaning (%)
                  </label>
                  <input
                    type="number"
                    name="cut.cleaning"
                    placeholder="Specify cleaning percentage"
                    value={
                      formData.cut.cleaning !== null ? formData.cut.cleaning * 100 : ""
                    }
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-[#8CD7FF] focus:ring-[#8CD7FF] sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Repairs and Adjustments (%)
                  </label>
                  <input
                    type="number"
                    name="cut.repairs"
                    placeholder="Specify repairs percentage"
                    value={
                      formData.cut.repairs !== null ? formData.cut.repairs * 100 : ""
                    }
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-[#8CD7FF] focus:ring-[#8CD7FF] sm:text-sm"
                  />
                </div>

                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Pre-verified Emails
                  </label>
                  {formData.preVerifiedEmails?.map((email) => (
                    <div key={email} className="flex items-center">
                      <span className="text-sm">{email}</span>
                      <button
                        type="button"
                        onClick={() => handleRemovePreVerifiedEmail(email)}
                        className="ml-2 text-red-600"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <div className="flex">
                    <input
                      type="email"
                      value={newPreVerifiedEmail}
                      onChange={(e) => setNewPreVerifiedEmail(e.target.value)}
                      className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-[#8CD7FF] focus:ring-[#8CD7FF] sm:text-sm"
                    />
                    <button
                      type="button"
                      onClick={handleAddPreVerifiedEmail}
                      className="ml-2 bg-[#8CD7FF] px-3 py-2"
                    >
                      Add
                    </button>
                  </div>
                </div>

                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Moderator Emails
                  </label>
                  {formData.moderatorEmails?.map((email) => (
                    <div key={email} className="flex items-center">
                      <span className="text-sm">{email}</span>
                      <button
                        type="button"
                        onClick={() => handleRemoveModeratorEmail(email)}
                        className="ml-2 text-red-600"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <div className="flex">
                    <input
                      type="email"
                      value={newModeratorEmail}
                      onChange={(e) => setNewModeratorEmail(e.target.value)}
                      className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-[#8CD7FF] focus:ring-[#8CD7FF] sm:text-sm"
                    />
                    <button
                      type="button"
                      onClick={handleAddModeratorEmail}
                      className="ml-2 bg-[#8CD7FF] px-3 py-2"
                    >
                      Add
                    </button>
                  </div>
                </div>

                <div className="mt-5 flex justify-between space-x-4 sm:mt-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center bg-[#8CD7FF] px-3 py-2 text-sm font-semibold shadow-sm hover:bg-[#8CD7FF] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#8CD7FF]"
                  >
                    Save Changes
                  </button>
                  <button
                    onClick={onClose}
                    className="inline-flex w-full justify-center border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#8CD7FF]"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
