import React from "react";

import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

interface ProgressChartProps {
  data: Array<{ name: string; value: number }>;
  title?: string;
}

const ProgressChart: React.FC<ProgressChartProps> = ({ data, title }) => {
  const maxValue = Math.max(...data.map((item) => item.value)) + 10;
  const reversedData = [...data].reverse();

  // Define the color values once to ensure consistency
  const chartColor = "#DDFE9B";
  const fillOpacity = 0.8;

  return (
    <div className="w-full">
      {title && <h2 className="mb-6 text-2xl font-semibold">{title}</h2>}
      <div className="relative h-96 w-full">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={data} margin={{ top: 10, right: 100, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" horizontal vertical={false} />
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tick={{ fill: "#666" }}
            />
            <YAxis
              domain={[0, maxValue]}
              axisLine={false}
              tickLine={false}
              tick={{ fill: "#666" }}
              ticks={[0, 20, 40, 60, 80]}
            />
            <Area
              type="monotone"
              dataKey="value"
              stroke={chartColor}
              fill={chartColor}
              fillOpacity={fillOpacity}
            />
          </AreaChart>
        </ResponsiveContainer>

        {/* Percentage indicators on the right */}
        <div className="absolute right-0 top-0 flex h-full flex-col justify-between py-10">
          {reversedData.map((item, index) => (
            <div
              key={index}
              className="mb-2 flex flex-col items-center rounded-xl p-3 shadow-sm"
              style={{
                minWidth: "80px",
                backgroundColor: chartColor,
                opacity: fillOpacity,
              }}
            >
              <div className="text-2xl font-bold">{item.value}%</div>
              <div className="text-sm">{item.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressChart;
