import { useEffect, useState } from "react";

import { collection, doc, getDocs, updateDoc } from "firebase/firestore";

import { getCurrentUserEmail } from "../auth";
import ModalTile from "../components/ModalTile";
import { db } from "../firebase";

interface Retail {
  id: string;
  name: string;
  orgNumber: string;
  addresses: string[];
  contactPerson: {
    name: string;
    phone: string;
    email: string;
  };
  preVerifiedEmails: string[];
  moderatorEmails: string[];
}

type ModalType =
  | "addAddress"
  | "removeAddress"
  | "addPreverified"
  | "removePreverified"
  | "addModerator"
  | "removeModerator"
  | null;

interface ModalData {
  retailId: string;
  value?: string;
}

export default function RetailModerator() {
  const [retailers, setRetailers] = useState<Retail[]>([]);
  const [userEmail, setUserEmail] = useState("");
  const [modalType, setModalType] = useState<ModalType>(null);
  const [modalData, setModalData] = useState<ModalData | null>(null);
  const [newValue, setNewValue] = useState("");

  useEffect(() => {
    const fetchRetailers = async () => {
      const email = getCurrentUserEmail();
      setUserEmail(email);

      const querySnapshot = await getDocs(collection(db, "retail"));
      const retailerList: Retail[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Retail[];

      // Filter to show retailers where user is either contact person or in moderatorEmails
      const filteredRetailers = retailerList.filter(
        (retail) =>
          retail.contactPerson?.email === email || retail.moderatorEmails?.includes(email)
      );

      setRetailers(filteredRetailers);
    };

    fetchRetailers();
  }, []);

  const handleUpdateRetailer = async (retailerId: string, updates: Partial<Retail>) => {
    const retailRef = doc(db, "retail", retailerId);

    // Remove contactPerson from updates if it exists
    const { contactPerson, ...safeUpdates } = updates;

    try {
      await updateDoc(retailRef, safeUpdates);
      setRetailers(
        retailers.map((r) => (r.id === retailerId ? { ...r, ...safeUpdates } : r))
      );
    } catch (error) {
      console.error("Error updating retail:", error);
    }
  };

  const handleRemoveEmail = async (retailerId: string, emailToRemove: string) => {
    const retail = retailers.find((r) => r.id === retailerId);
    if (!retail) return;

    setModalType("removePreverified");
    setModalData({ retailId: retailerId, value: emailToRemove });
  };

  const handleRemoveAddress = async (retailerId: string, addressToRemove: string) => {
    const retail = retailers.find((r) => r.id === retailerId);
    if (!retail) return;

    setModalType("removeAddress");
    setModalData({ retailId: retailerId, value: addressToRemove });
  };

  const handleRemoveModeratorEmail = async (
    retailerId: string,
    emailToRemove: string
  ) => {
    const retail = retailers.find((r) => r.id === retailerId);
    if (!retail) return;

    setModalType("removeModerator");
    setModalData({ retailId: retailerId, value: emailToRemove });
  };

  const handleConfirmRemove = async () => {
    if (!modalData) return;

    const retail = retailers.find((r) => r.id === modalData.retailId);
    if (!retail) return;

    switch (modalType) {
      case "removeAddress":
        await handleUpdateRetailer(modalData.retailId, {
          addresses: retail.addresses.filter((addr) => addr !== modalData.value),
        });
        break;
      case "removePreverified":
        await handleUpdateRetailer(modalData.retailId, {
          preVerifiedEmails: retail.preVerifiedEmails.filter(
            (email) => email !== modalData.value
          ),
        });
        break;
      case "removeModerator":
        await handleUpdateRetailer(modalData.retailId, {
          moderatorEmails: retail.moderatorEmails.filter(
            (email) => email !== modalData.value
          ),
        });
        break;
    }

    setModalType(null);
    setModalData(null);
    setNewValue("");
  };

  const handleConfirmAdd = async () => {
    if (!modalData || !newValue) return;

    const retail = retailers.find((r) => r.id === modalData.retailId);
    if (!retail) return;

    // Convert email to lowercase for email-type additions
    const valueToAdd = modalType === "addAddress" ? newValue : newValue.toLowerCase();

    switch (modalType) {
      case "addAddress":
        await handleUpdateRetailer(modalData.retailId, {
          addresses: [...retail.addresses, valueToAdd],
        });
        break;
      case "addPreverified":
        await handleUpdateRetailer(modalData.retailId, {
          preVerifiedEmails: [...retail.preVerifiedEmails, valueToAdd],
        });
        break;
      case "addModerator":
        await handleUpdateRetailer(modalData.retailId, {
          moderatorEmails: [...(retail.moderatorEmails || []), valueToAdd],
        });
        break;
    }

    setModalType(null);
    setModalData(null);
    setNewValue("");
  };

  const renderModalContent = () => {
    if (!modalData) return null;

    const retail = retailers.find((r) => r.id === modalData.retailId);
    if (!retail) return null;

    switch (modalType) {
      case "removeAddress":
      case "removePreverified":
      case "removeModerator":
        return (
          <div className="p-4">
            <p className="mb-4">
              Are you sure you want to remove this{" "}
              {modalType === "removeAddress" ? "address" : "email"}?
            </p>
            <p className="mb-4 font-medium">{modalData.value}</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setModalType(null)}
                className="rounded px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmRemove}
                className="rounded bg-red-600 px-4 py-2 text-white hover:bg-red-700"
              >
                Remove
              </button>
            </div>
          </div>
        );

      case "addAddress":
      case "addPreverified":
      case "addModerator":
        return (
          <div className="p-4">
            <label className="block">
              <span className="text-gray-700">
                {modalType === "addAddress" ? "New Address" : "New Email"}:
              </span>
              <input
                type={modalType === "addAddress" ? "text" : "email"}
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
              />
            </label>
            <div className="mt-4 flex justify-end gap-4">
              <button
                onClick={() => setModalType(null)}
                className="rounded px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmAdd}
                className="rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
              >
                Add
              </button>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="m-12 mx-8">
      <h2 className="mb-4 text-2xl font-bold text-gray-900">Retail Management</h2>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {retailers.map((retail) => (
          <div
            key={retail.id}
            className="rounded-lg border border-gray-200 bg-white p-6 shadow transition-shadow hover:shadow-lg"
          >
            <h3 className="text-lg font-bold">{retail.name}</h3>
            <p className="text-sm text-gray-600">Org number: {retail.orgNumber}</p>

            {/* Add contact info display */}
            <div className="mt-2 border-t border-gray-100 pt-2">
              <div className="text-sm text-gray-500">
                <p>Contact: {retail.contactPerson.name}</p>
                <p>Phone: {retail.contactPerson.phone}</p>
                <p>Email: {retail.contactPerson.email}</p>
              </div>
            </div>

            {/* Collapsible sections for better mobile view */}
            <details className="mt-4">
              <summary className="cursor-pointer font-medium">Addresses</summary>
              <div className="mt-2 pl-2">
                <ul className="space-y-1">
                  {retail.addresses.map((address, index) => (
                    <li key={index} className="flex items-center justify-between text-sm">
                      <span>{address}</span>
                      <button
                        onClick={() => handleRemoveAddress(retail.id, address)}
                        className="ml-2 text-red-600 hover:text-red-800"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
                <button
                  className="mt-2 text-sm text-blue-600 hover:text-blue-800"
                  onClick={() => {
                    setModalType("addAddress");
                    setModalData({ retailId: retail.id });
                  }}
                >
                  + Add Address
                </button>
              </div>
            </details>

            <details className="mt-4">
              <summary className="cursor-pointer font-medium">
                Pre-verified Emails
              </summary>
              <div className="mt-2 pl-2">
                <ul className="space-y-1">
                  {retail.preVerifiedEmails.map((email, index) => (
                    <li key={index} className="flex items-center justify-between text-sm">
                      <span className="break-all">{email}</span>
                      <button
                        onClick={() => handleRemoveEmail(retail.id, email)}
                        className="ml-2 shrink-0 text-red-600 hover:text-red-800"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
                <button
                  className="mt-2 text-sm text-blue-600 hover:text-blue-800"
                  onClick={() => {
                    setModalType("addPreverified");
                    setModalData({ retailId: retail.id });
                  }}
                >
                  + Add Pre-verified Email
                </button>
              </div>
            </details>

            <details className="mt-4">
              <summary className="cursor-pointer font-medium">Moderator Emails</summary>
              <div className="mt-2 pl-2">
                <ul className="space-y-1">
                  {retail.moderatorEmails?.map((email, index) => (
                    <li key={index} className="flex items-center justify-between text-sm">
                      <span className="break-all">{email}</span>
                      <button
                        onClick={() => handleRemoveModeratorEmail(retail.id, email)}
                        className="ml-2 shrink-0 text-red-600 hover:text-red-800"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
                <button
                  className="mt-2 text-sm text-blue-600 hover:text-blue-800"
                  onClick={() => {
                    setModalType("addModerator");
                    setModalData({ retailId: retail.id });
                  }}
                >
                  + Add Moderator Email
                </button>
              </div>
            </details>
          </div>
        ))}
      </div>
      <ModalTile
        open={modalType !== null}
        onClose={() => {
          setModalType(null);
          setModalData(null);
          setNewValue("");
        }}
        title={modalType?.includes("remove") ? "Confirm Removal" : "Add New Item"}
      >
        {renderModalContent()}
      </ModalTile>
    </div>
  );
}
