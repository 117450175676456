import React, { useEffect, useState } from "react";

import { collection, getDocs, query } from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

import { getCurrentUserEmail } from "../auth";
import { db } from "../firebase";
import { Order } from "../pages/OrderPage";
import ClaimsBarChart from "./graphs/ClaimsBarChart";
import DonutChart from "./graphs/DonutChart";
import FinancialTrendChart from "./graphs/FinancialTrendChart";
import HalfCircleGauge from "./graphs/HalfCircleGauge";
import HorizontalGauge from "./graphs/HorizontalGauge";
import ProgressChart from "./graphs/ProgressChart";
import StackedBarChart from "./graphs/StackedBarChart";

interface AnalyticsSummary {
  totalOrders: number;
  totalRevenue: number;
  averageOrderValue: number;
  dailyStats: {
    [date: string]: {
      orders: number;
      revenue: number;
    };
  };
  dailyRetailStats: {
    [date: string]: {
      [retailId: string]: number;
    };
  };
  yearlyAverages?: { year: number; value: number }[];
  quarterlyAverages?: { quarter: string; value: number }[];
  monthlyClaimStats?: { name: string; value: number }[];
}

interface Retail {
  id: string;
  name: string;
  contactPerson: {
    email: string;
  };
  moderatorEmails?: string[];
}

interface YearlyClaimStats {
  retailId: string;
  retailName: string;
  averageClaimRate: number;
  monthlyStats: { month: string; value: number }[];
}

const RetailAnalytics: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState(true);
  const [analytics, setAnalytics] = useState<AnalyticsSummary>({
    totalOrders: 0,
    totalRevenue: 0,
    averageOrderValue: 0,
    dailyStats: {},
    dailyRetailStats: {},
  });
  const [userRetails, setUserRetails] = useState<Retail[]>([]);
  const [selectedRetails, setSelectedRetails] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [selectedRetailForTrend, setSelectedRetailForTrend] = useState<string>("");
  const [yearlyClaimStats, setYearlyClaimStats] = useState<YearlyClaimStats[]>([]);

  const fetchUserRetails = async () => {
    try {
      const userEmail = await getCurrentUserEmail();
      if (!userEmail) {
        console.log("No user email found");
        return;
      }

      const retailRef = collection(db, "retail");
      const retailSnapshot = await getDocs(retailRef);
      const userRetails = retailSnapshot.docs
        .map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            }) as Retail
        )
        .filter(
          (retail) =>
            retail.contactPerson?.email === userEmail ||
            retail.moderatorEmails?.includes(userEmail)
        );

      console.log("Fetched retailers:", userRetails);
      setUserRetails(userRetails);
      setSelectedRetails(userRetails.map((retail) => retail.id));
      return userRetails;
    } catch (error) {
      console.error("Error fetching user retails:", error);
      return [];
    }
  };

  const calculateYearlyAverages = (
    orders: Order[]
  ): { year: number; value: number }[] => {
    const yearlyTotals: { [key: number]: { sum: number; count: number } } = {};

    orders.forEach((order) => {
      const year = new Date(order.date).getFullYear();
      const price = parseFloat(order.totalPrice || "0");

      if (!yearlyTotals[year]) {
        yearlyTotals[year] = { sum: 0, count: 0 };
      }
      yearlyTotals[year].sum += price;
      yearlyTotals[year].count += 1;
    });

    return Object.entries(yearlyTotals)
      .map(([year, data]) => ({
        year: parseInt(year),
        // Calculate potential savings as 50% of average order value
        value: (data.sum / data.count) * 0.5,
      }))
      .sort((a, b) => a.year - b.year);
  };

  const calculateQuarterlyAverages = (
    orders: Order[]
  ): { quarter: string; value: number }[] => {
    const quarterlyTotals: { [key: string]: { sum: number; count: number } } = {};

    orders.forEach((order) => {
      const date = new Date(order.date);
      const year = date.getFullYear();
      const quarter = Math.floor(date.getMonth() / 3) + 1;
      const quarterKey = `Q${quarter} ${year}`;
      const price = parseFloat(order.totalPrice || "0");

      if (!quarterlyTotals[quarterKey]) {
        quarterlyTotals[quarterKey] = { sum: 0, count: 0 };
      }
      quarterlyTotals[quarterKey].sum += price;
      quarterlyTotals[quarterKey].count += 1;
    });

    return Object.entries(quarterlyTotals)
      .map(([quarter, data]) => ({
        quarter,
        // Calculate potential savings as 50% of average order value
        value: (data.sum / data.count) * 0.5,
      }))
      .sort((a, b) => {
        // Sort by year and quarter
        const [q1, y1] = a.quarter.split(" ");
        const [q2, y2] = b.quarter.split(" ");
        return y1 === y2 ? q1.localeCompare(q2) : parseInt(y1) - parseInt(y2);
      });
  };

  const calculateMonthlyClaimAverages = (orders: Order[]) => {
    const retailClaims: { [key: string]: { claims: number; total: number } } = {};

    orders.forEach((order) => {
      if (!order.retailId) return;

      const retail = userRetails.find((r) => r.id === order.retailId);
      if (!retail) return;

      if (!retailClaims[retail.name]) {
        retailClaims[retail.name] = { claims: 0, total: 0 };
      }

      retailClaims[retail.name].total++;
      if (order.claim === true) {
        retailClaims[retail.name].claims++;
      }
    });

    return Object.entries(retailClaims)
      .map(([name, stats]) => ({
        name,
        value: Math.round((stats.claims / stats.total) * 100),
      }))
      .sort((a, b) => b.value - a.value);
  };

  const calculateYearlyClaimStats = (orders: Order[], year: number) => {
    const stats: YearlyClaimStats[] = [];

    userRetails.forEach((retail) => {
      const retailOrders = orders.filter(
        (order) =>
          order.retailId === retail.id && new Date(order.date).getFullYear() === year
      );

      const monthlyStats = Array.from({ length: 12 }, (_, i) => {
        const monthOrders = retailOrders.filter(
          (order) => new Date(order.date).getMonth() === i
        );

        const claimRate =
          monthOrders.length > 0
            ? (monthOrders.filter((order) => order.claim === true).length /
                monthOrders.length) *
              100
            : 0;

        return {
          month: new Date(year, i).toLocaleString("default", { month: "short" }),
          value: Math.round(claimRate),
        };
      });

      const yearlyAverage =
        retailOrders.length > 0
          ? (retailOrders.filter((order) => order.claim === true).length /
              retailOrders.length) *
            100
          : 0;

      stats.push({
        retailId: retail.id,
        retailName: retail.name,
        averageClaimRate: Math.round(yearlyAverage),
        monthlyStats,
      });
    });

    return stats;
  };

  const getWeekNumber = (date: Date): string => {
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const weekNumber = Math.ceil(
      ((date.getTime() - startOfYear.getTime()) / 86400000 + startOfYear.getDay() + 1) / 7
    );
    return `${date.getFullYear()}-W${weekNumber.toString().padStart(2, "0")}`;
  };

  const fetchAnalytics = async () => {
    if (selectedRetails.length === 0) return;

    setLoading(true);
    try {
      const ordersRef = collection(db, "b2cOrders");
      const q = query(ordersRef);
      const querySnapshot = await getDocs(q);

      const orders: Order[] = querySnapshot.docs
        .map((doc) => ({
          ...(doc.data() as Order),
          id: doc.id,
        }))
        .filter((order) => order.retailId && selectedRetails.includes(order.retailId));

      // Move these calculations after orders are filtered
      const yearlyAverages = calculateYearlyAverages(orders);
      const quarterlyAverages = calculateQuarterlyAverages(orders);
      const monthlyClaimStats = calculateMonthlyClaimAverages(orders);
      const yearlyStats = calculateYearlyClaimStats(orders, selectedYear);
      setYearlyClaimStats(yearlyStats);

      // Replace the daily stats calculation with weekly stats
      const weeklyStats: { [week: string]: { orders: number; revenue: number } } = {};
      const weeklyRetailStats: { [week: string]: { [retailId: string]: number } } = {};
      let totalRevenue = 0;
      let totalOrders = 0;

      querySnapshot.docs.forEach((doc) => {
        const order = doc.data() as Order;
        if (!order.retailId || !selectedRetails.includes(order.retailId)) return;

        const orderDate = new Date(order.date);
        if (orderDate >= startDate && orderDate <= endDate) {
          const weekKey = getWeekNumber(orderDate);
          const revenue = parseFloat(order.totalPrice || "0");

          if (!weeklyStats[weekKey]) {
            weeklyStats[weekKey] = { orders: 0, revenue: 0 };
            weeklyRetailStats[weekKey] = {};
          }

          if (!weeklyRetailStats[weekKey][order.retailId]) {
            weeklyRetailStats[weekKey][order.retailId] = 0;
          }

          weeklyRetailStats[weekKey][order.retailId]++;
          weeklyStats[weekKey].orders += 1;
          weeklyStats[weekKey].revenue += revenue;
          totalRevenue += revenue;
          totalOrders += 1;
        }
      });

      setAnalytics({
        totalOrders,
        totalRevenue,
        averageOrderValue: totalOrders > 0 ? totalRevenue / totalOrders : 0,
        dailyStats: weeklyStats,
        dailyRetailStats: weeklyRetailStats,
        yearlyAverages,
        quarterlyAverages,
        monthlyClaimStats,
      });
    } catch (error) {
      console.error("Error fetching analytics:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserRetails();
    // Re-run this effect if the URL hash changes
  }, [window.location.hash]);

  useEffect(() => {
    if (selectedRetails.length > 0) {
      fetchAnalytics();
    }
  }, [selectedRetails, startDate, endDate, selectedYear]); // Add selectedYear to dependencies

  const exampleDataForClaimsBarChart = [
    { name: "Paleet", value: 10 },
    { name: "Høyer woman", value: 23 },
    { name: "Storo", value: 17 },
    { name: "Online", value: 30 },
  ];
  const dataGraph = [
    { year: 2023, value: 640 },
    { year: 2024, value: 431 },
    { year: 2025, value: 725 },
    { year: 2026, value: 970 },
  ];

  const exampleDataForDonutChart = [
    { name: "Top notch", value: 45 },
    { name: "Acceptable", value: 30 },
    { name: "Very good", value: 25 },
  ];

  const sampleData = [
    { name: "Q4 24", value: 90 },
    { name: "Q1 25", value: 37 },
    { name: "Q2 25", value: 59 },
    { name: "Q3 25", value: 10 },
  ];

  const getStackedBarData = () => {
    const dates = Object.keys(analytics.dailyRetailStats).sort();
    return dates.map((date) => {
      const dataPoint: any = { date };
      userRetails.forEach((retail) => {
        dataPoint[retail.name] = analytics.dailyRetailStats[date][retail.id] || 0;
      });
      return dataPoint;
    });
  };

  const formatWeekDisplay = (weekKey: string): string => {
    const [year, week] = weekKey.split("-W");
    const weekNumber = parseInt(week);
    return `Week ${weekNumber}, ${year}`;
  };

  return (
    <div className="min-h-screen p-4">
      <div className="mb-8 space-y-4">
        <h2 className="text-2xl font-bold">Retail Analytics</h2>
        <div className="flex flex-wrap gap-4">
          <div className="w-full md:w-64">
            <label className="block text-sm font-medium text-gray-700">
              Select Retailers
            </label>
            <Select
              isMulti
              options={userRetails.map((retail) => ({
                value: retail.id,
                label: retail.name,
              }))}
              value={userRetails
                .filter((retail) => selectedRetails.includes(retail.id))
                .map((retail) => ({
                  value: retail.id,
                  label: retail.name,
                }))}
              onChange={(selected) =>
                setSelectedRetails(selected ? selected.map((item) => item.value) : [])
              }
              className="basic-select"
              classNamePrefix="select"
            />
          </div>
          <div className="w-full md:w-auto">
            <label className="block text-sm font-medium text-gray-700">From Date</label>
            <DatePicker
              selected={startDate}
              onChange={(date: Date | null) => date && setStartDate(date)}
              className="w-full rounded border border-black p-2"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="w-full md:w-auto">
            <label className="block text-sm font-medium text-gray-700">To Date</label>
            <DatePicker
              selected={endDate}
              onChange={(date: Date | null) => date && setEndDate(date)}
              className="w-full rounded border border-black p-2"
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
      </div>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="space-y-8">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
            <div className="rounded-lg border border-black p-4">
              <h3 className="text-sm font-medium text-gray-500">Total Orders</h3>
              <p className="mt-1 text-3xl font-semibold">{analytics.totalOrders}</p>
            </div>
            <div className="rounded-lg border border-black p-4">
              <h3 className="text-sm font-medium text-gray-500">Total Revenue</h3>
              <p className="mt-1 text-3xl font-semibold">
                {analytics.totalRevenue.toFixed(2)} NOK
              </p>
            </div>
            <div className="rounded-lg border border-black p-4">
              <h3 className="text-sm font-medium text-gray-500">Average Order Value</h3>
              <p className="mt-1 text-3xl font-semibold">
                {analytics.averageOrderValue.toFixed(2)} NOK
              </p>
            </div>
          </div>

          <div className="rounded-lg border border-black p-4">
            <h3 className="mb-4 text-lg font-medium">Weekly Statistics</h3>
            <div className="max-h-96 overflow-y-auto">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                      Week
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                      Orders
                    </th>
                    <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                      Revenue
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {Object.entries(analytics.dailyStats)
                    .sort((a, b) => b[0].localeCompare(a[0]))
                    .map(([weekKey, stats]) => (
                      <tr key={weekKey}>
                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                          {formatWeekDisplay(weekKey)}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                          {stats.orders}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                          {stats.revenue.toFixed(2)} NOK
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="mt-8 rounded-lg border border-black p-4">
            <h3 className="mb-4 text-lg font-medium">Daily Orders by Retailer</h3>
            <StackedBarChart
              data={getStackedBarData()}
              retailers={userRetails.map((retail) => retail.name)}
            />
          </div>
        </div>
      )}
      <FinancialTrendChart
        data={
          analytics.quarterlyAverages?.map((q) => ({
            year:
              parseInt(q.quarter.split(" ")[1]) +
              (q.quarter.startsWith("Q1")
                ? 0
                : q.quarter.startsWith("Q2")
                  ? 0.25
                  : q.quarter.startsWith("Q3")
                    ? 0.5
                    : 0.75),
            value: Math.round(q.value),
            quarter: q.quarter,
          })) || []
        }
        width={600}
        height={300}
        className="Average Quarterly Savings"
      />
      <ClaimsBarChart
        data={analytics.monthlyClaimStats || []}
        title="Claims: Monthly average (%)"
        width={600}
        height={400}
        className="my-custom-class"
        rotateLabels={true}
      />
      <HalfCircleGauge value={16} total={100} name="Paleet" />
      <HorizontalGauge value={76} total={100} name="Paleet" label="Repair" />
      <DonutChart
        data={exampleDataForDonutChart}
        width={800}
        height={400}
        className="my-custom-class"
      />
      <ProgressChart
        data={sampleData}
        title="LCA analysis: Carbon footprint and waste reduction"
      />
      <div className="mt-8 border-b border-black pb-8">
        <div className="mb-4 flex items-center gap-4">
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
            className="rounded border border-gray-300 px-3 py-2"
          >
            {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(
              (year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              )
            )}
          </select>

          <select
            value={selectedRetailForTrend}
            onChange={(e) => setSelectedRetailForTrend(e.target.value)}
            className="rounded border border-gray-300 px-3 py-2"
          >
            <option value="">All Retailers</option>
            {userRetails.map((retail) => (
              <option key={retail.id} value={retail.id}>
                {retail.name}
              </option>
            ))}
          </select>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          <div>
            <h3 className="mb-4 text-lg font-medium">Monthly Claim Trends</h3>
            <FinancialTrendChart
              data={
                selectedRetailForTrend
                  ? yearlyClaimStats
                      .find((stat) => stat.retailId === selectedRetailForTrend)
                      ?.monthlyStats.map((stat) => ({
                        year: selectedYear,
                        value: stat.value,
                        quarter: stat.month,
                      })) || []
                  : yearlyClaimStats[0]?.monthlyStats.map((stat, index) => ({
                      year: selectedYear,
                      value: Math.round(
                        yearlyClaimStats.reduce(
                          (acc, retailStat) => acc + retailStat.monthlyStats[index].value,
                          0
                        ) / yearlyClaimStats.length
                      ),
                      quarter: stat.month,
                    })) || []
              }
              width={600}
              height={300}
              className="Monthly Claims"
            />
          </div>

          <div>
            <h3 className="mb-4 text-lg font-medium">Claims Distribution</h3>
            <ClaimsBarChart
              data={yearlyClaimStats.map((stat) => ({
                name: stat.retailName,
                value: stat.averageClaimRate,
              }))}
              title={`Average Claims Rate (${selectedYear})`}
              width={600}
              height={300}
              className="my-custom-class"
              rotateLabels={true}
            />
          </div>
        </div>

        <div className="mt-8 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {yearlyClaimStats.map((stat) => (
            <div key={stat.retailId} className="flex flex-col items-center">
              <h4 className="mb-2 text-sm font-medium">{stat.retailName}</h4>
              <HalfCircleGauge
                value={stat.averageClaimRate}
                total={100}
                name={stat.retailName}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RetailAnalytics;
