import { Fragment, useEffect, useRef, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { getAuth } from "firebase/auth";
import { use } from "i18next";
import { useTranslation } from "react-i18next";

import { signInWithMicrosoft } from "../../auth";
// Import translation hook
import { ReactComponent as ChevronRightIcon } from "../../images/icons-fikse/chevron.svg";
import useAuth from "../../useAuth";

interface WorkwearModalProps {
  open: boolean;
  onClose: () => void;
}

const authorizedEmailDomains = (email: string): boolean => {
  return (
    email.endsWith("oslo.kommune.no") ||
    email.endsWith("osloskolen.no") ||
    email.endsWith("fikse.co") ||
    email.endsWith("ntnu.no") ||
    email.endsWith("egms.no")
  );
};

const WorkwearModal: React.FC<WorkwearModalProps> = ({ open, onClose }) => {
  const cancelButtonRef = useRef(null);
  const [email, setEmail] = useState("");
  const { t, i18n } = useTranslation(); // Initialize translation hook
  const user = useAuth();
  useEffect(() => {
    const storedEmail = localStorage.getItem("uid");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  useEffect(() => {
    if (open) {
      handleContinue();
    }
  }, [open, user]);

  const handleContinue = () => {
    if (user) {
      const isNorwegian = i18n.language === "no";

      if (authorizedEmailDomains(email)) {
        localStorage.setItem(
          "serviceCategory",
          isNorwegian ? "NO-Workwear/Ahlsell-0924" : "EN-Workwear/Ahlsell-0924"
        );
      } else {
        localStorage.setItem(
          "serviceCategory",
          isNorwegian ? "NO-Clothes-0924" : "EN-Clothes-0924"
        );
      }
      //localStorage.setItem("uid", email);

      onClose();
    }
  };

  const isEmailValid = email.includes("@") && email.includes(".");

  const handleSignIn = () => {
    signInWithMicrosoft();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 flex items-end justify-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="translate-y-full"
            enterTo="translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
          >
            <Dialog.Panel className="w-full transform bg-white text-left shadow-xl transition-all">
              {!user && (
                <>
                  <Dialog.Title
                    as="h3"
                    className="flex justify-between p-4 text-base leading-6 text-gray-900"
                  >
                    <div className="text-lg font-light">{t("workwearModal.title")}</div>
                    <button
                      type="button"
                      className="my-auto inline-flex justify-center bg-white px-4 py-2 text-lg text-gray-500 focus:outline-none"
                      onClick={onClose}
                      ref={cancelButtonRef}
                    >
                      X
                    </button>
                  </Dialog.Title>
                  <div className="mt-4">
                    <button
                      type="button"
                      className="${ inline-flex w-full items-center justify-between border-b border-t border-black bg-fikseGreen p-4 text-lg text-black"
                      onClick={async () => {
                        await signInWithMicrosoft();
                        if (localStorage.getItem("uid")) window.location.reload();
                      }}
                    >
                      {t("signInMicrosoft")}
                      <ChevronRightIcon className="mt-1 h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="my-4 text-center text-sm text-gray-500">
                    {t("workwearModal.footer")}
                  </div>
                </>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default WorkwearModal;
