// /src/pages/OrderPage/OrderPage.tsx
import React, { useEffect, useRef, useState } from "react";

import { set } from "date-fns";
import { getAuth } from "firebase/auth";
import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { useParams } from "react-router-dom";

import { connectRolesWithLocalStorge, getCurrentUserEmail } from "../auth";
import AwaitingVerification from "../components/AwaitingVerification";
import DeviationPromt from "../components/DeviationPromt";
import FormattedTimestamp from "../components/FormatTimeAndDate";
import ModalTile from "../components/ModalTile";
import PreCancleOrderProps from "../components/PreCancleOrder";
import { sendSMS } from "../components/contact";
import { newActivity } from "../components/firebaseCommands";
import AvvikIOrdre, { cancelOrderInFirestore } from "../components/newOrder/AvvikInOrder";
import ConfirmationView from "../components/newOrder/ConfirmationView";
import DropOffLocation from "../components/newOrder/DropOffLocation";
import { fallbackImageUrl } from "../components/newOrder/OrderModal";
import { initiateLateCheckout } from "../components/utilities/payment";
import { app, db } from "../firebase";
import animationData from "../images/FikseAnimation.json";
import { ReactComponent as FikseLogo } from "../images/fikse-logo.svg";
import { ReactComponent as ChevronRightIcon } from "../images/icons-fikse/chevron.svg";
import { ReactComponent as DeliverIcon } from "../images/icons-fikse/deliver.svg";
import { ReactComponent as SignIcon } from "../images/icons-fikse/sign.svg";
import OrderCard, { finalEventsStateTailor } from "./OrderPage/OrderCard";
import OrderCardDetails from "./OrderPage/OrderCardDetails";
import OrderLog from "./OrderPage/OrderLog";
import OrderTabs from "./OrderPage/OrderTabs";
import SwipeBottomBar from "./swipeBottomBar";

/* Oppdatert OrderInfo- og Order-typer */

interface OrderPageProps {
  database: string;
}
export interface OrderInfo {
  item: string;
  problem: string;
  fix_type: string;
  description: string;
  price_nok: number;
  customerComment?: string;
  imageUrl: string;
  work?: string; // Optional work field
  tailorNote?: string; // Optional tailor note field
  previousPrice?: number[]; // Optional previous price field
  count?: number; // Optional count field
  event?: Event[]; // Optional event field // Optional event field
}

export interface Event {
  date: string;
  status: string;
  description?: string;
  handler: string;
  Price?: number;
  tailorNote?: string;
  oldPrice?: number;
  newPrice?: number;
}

export interface Order {
  id: string;
  date: string;
  orderInfo: OrderInfo[];
  totalPrice: string;
  deliveryMethod: string;
  retrieveMethod: string;
  contactInfo: {
    department: string;
    name: string;
    phone: string;
    address?: string;
  };
  receiveMarketing: boolean;
  email: string;
  paid: boolean;
  qrCodeUrl?: string;
  activity: ActivityItem[];
  resourceNumber?: string;
  deliveryAddress?: string;
  deliveryDepartment?: string;
  retrieveAddress?: string;
  tailorsNote?: string;
  orderCreationUrl?: string;
  claim?: boolean;
  claimNote?: string;
  theyDeliver?: boolean;
  brand?: string;
  retailId?: string;
  customerRef?: string;
  test?: boolean;
}

export interface ActivityItem {
  id: number;
  content: string;
  message?: string;
  date: string;
  dateTime: string;
  iconBackground: string;
  deviationPrice?: number;
  deviationdeResponse?: string;
  handler?: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const LoadingSpinner: React.FC = () => (
  <div className="flex items-center justify-center py-10">
    <Lottie options={defaultOptions} />
  </div>
);

const ErrorBanner: React.FC<{ message: string }> = ({ message }) => {
  const { t } = useTranslation();
  return (
    <div
      className="relative mb-6 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
      role="alert"
    >
      <strong className="font-bold">{t("error")}</strong>
      <span className="block sm:inline">{message}</span>
    </div>
  );
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const orderStates: string[] = [
  "orderStateCreated",
  "orderStateCustomerDropOff",
  "orderStateDeliverToTailor",
  "orderStateRepaired",
  "orderStatePickedUpFromTailor",
  "orderStateDelivered",
];

const WorkwearOrderPage: React.FC<OrderPageProps> = ({ database }) => {
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showUpdateStatus, setShowUpdateStatus] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showDropOff, setShowDropOff] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [focusedOrderItems, setFocusedOrderItems] = useState<number>(
    window.location.hash ? parseInt(window.location.hash.slice(1)) - 1 : -1
  );
  const [AvvikModal, setAvvikModal] = useState<boolean>(false);
  const [actionNeedsToBeTaken, setActionNeedsToBeTaken] = useState<boolean>(false);

  // Add list of approved status elements
  const approvedLastElements = [
    "changesApproved",
    "cancelledByTailor",
    "accepted",
    "paid",
    "verified",
  ];

  //Brukes for å filterer bort unskede flyter i ordere
  const filteredOrderStates = order?.theyDeliver
    ? orderStates.filter((state) => state !== "orderStateCustomerDropOff")
    : orderStates;

  const { t } = useTranslation();
  const hasEffectRun = useRef(false);
  const lastActivityContent = order?.activity[order?.activity.length - 1].content;
  const finishedOrder: boolean =
    lastActivityContent === filteredOrderStates[filteredOrderStates.length - 1];
  const signleItemOrder: boolean = order?.orderInfo.length === 1;

  const fetchOrder = async () => {
    try {
      setLoading(true);
      setError(null);

      if (!orderId) {
        throw new Error(t("workwearOrderPage.noOrderId"));
      }

      const orderRef = doc(db, database, orderId);
      const orderSnap = await getDoc(orderRef);

      if (!orderSnap.exists()) {
        throw new Error(t("workwearOrderPage.orderNotFound"));
      }

      const data = orderSnap.data() as Omit<Order, "id">;

      setOrder({ id: orderId, ...data });
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(t("workwearOrderPage.unknownError"));
      }
    } finally {
      const randomDelay = Math.floor(Math.random() * (850 - 600 + 1)) + 600;
      const minLoadingTime = new Promise((resolve) => setTimeout(resolve, randomDelay));
      await Promise.all([minLoadingTime]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasEffectRun.current) return;
    hasEffectRun.current = true;

    fetchOrder();
  }, [orderId]);

  useEffect(() => {
    //Oppdaterer roles fortløpende ved endring i firebase
    let mounted = true;
    const unsubscribe = getAuth().onAuthStateChanged(async (user) => {
      if (user && mounted) {
        try {
          await connectRolesWithLocalStorge(user.email || "");
          console.log("Roles updated successfully");
        } catch (error) {
          console.error("Failed to update roles:", error);
          // Could add error handling UI here
        }
      }
    });

    return () => {
      mounted = false;
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const verifiedUid = localStorage.getItem("verifiedUid");
    const uid = localStorage.getItem("uid");

    if (verifiedUid === uid) {
      setIsVerified(true);
    }
  }, []);

  useEffect(() => {
    if (window.location.hash === "#newOrder") {
      setShowConfirmation(true);
    }

    if (window.location.hash === "#scanned" || window.location.hash === "#checkPayment") {
      setFocusedOrderItems(-1);
    }
  }, []);

  /*Conditions to check if bottom bar is visible*/
  useEffect(() => {
    const roles = localStorage.getItem("roles");
    const lastIndexOf = order?.activity ? order.activity.length - 1 : -1;

    setShowUpdateStatus(false);

    if (
      order?.activity &&
      (order.activity[lastIndexOf].message === "Bestilling opprettet" ||
        order.activity[lastIndexOf].content === "orderStateCreated" ||
        order.activity[lastIndexOf].content === "orderStatePickedUpFromTailor")
    ) {
      setShowUpdateStatus(true);
    }

    if (
      order?.activity &&
      order.activity[lastIndexOf].content === "orderStateDeliverToTailor" &&
      roles?.includes("tailor") /*&&      allItemsApproved*/
    ) {
      const allItemsApproved = order?.orderInfo.every((item) => {
        // If no events exist, return true

        if (!item.event || item.event.length === 0) {
          return true;
        }

        // Get the last event's status
        const lastEventStatus = item.event[item.event.length - 1].status;

        // Check if the last status is in approvedLastElements
        return approvedLastElements.includes(lastEventStatus);
      });

      setActionNeedsToBeTaken(!allItemsApproved || false);

      setShowUpdateStatus(true);
    }

    if (
      order?.activity &&
      (order.activity[lastIndexOf].content === "orderStateCustomerDropOff" ||
        order.activity[lastIndexOf].content === "orderStateRepaired") &&
      roles?.includes("transporter")
    ) {
      setShowUpdateStatus(true);
    }

    if (
      order?.activity &&
      order.activity[lastIndexOf].content === "orderStatePickedUpFromTailor" &&
      roles?.includes("courier")
    ) {
      setShowUpdateStatus(true);
    }
    // ...rest of your existing effect code...
  }, [order, filteredOrderStates]);

  const handleStatusUpdate = async (newStatus: string) => {
    if (newStatus) {
      const orderRef = doc(db, database, orderId!);
      const userEmail = getCurrentUserEmail();

      const updatedActivity = [
        ...order!.activity,
        {
          id: order!.activity.length + 1,
          content: newStatus,
          date: new Date().toLocaleDateString(),
          dateTime: new Date().toISOString(),
          iconBackground: "bg-blue-500",
          handler: userEmail, // Add handler field
        },
      ];

      await updateDoc(orderRef, {
        currentStatus: newStatus,
        activity: updatedActivity,
      });

      /*@andreas-dity Fjerner denne, sletter den innen 5. februar 2024
      let smsMessage = "";
      if (newStatus === "Done at tailor") {
        smsMessage =
          "Din ordre er ferdig hos skredderen. Vi vil varsle deg når plagget er tilbake på utleveringsstedet.";
      } else if (newStatus === "Delivered back to dropoff") {
        smsMessage = `Plagget ditt er levert tilbake til utleveringsstedet. Du kan hente det på ${
          order!.deliveryAddress
            ? order!.deliveryAddress
            : "det angitte utleveringsstedet"
        }. Husk å hente plagget så snart som mulig.`;
      }
      if (smsMessage && order!.contactInfo.phone) {
        try {
          const messageData = {
            to: "+47" + order!.contactInfo.phone,
            from: "Fikse",
            body: smsMessage,
            sentAt: new Date().toISOString(),
            orderId: orderId,
          };
          await setDoc(doc(collection(db, "messages")), messageData);
          console.log("SMS message added to collection");
        } catch (error) {
          console.error("Error adding SMS message to collection: ", error);
        }
      }*/
      fetchOrder();
      setIsModalOpen(false);
    } else {
      alert("Vennligst velg en status for å oppdatere.");
    }
  };

  const handleProceed = () => {
    setShowConfirmation(false);
    window.history.replaceState(null, "", " ");
  };

  const email = localStorage.getItem("uid") || "";

  //denne her ser ut til å være en funksjon som sender en epost til kunden om at ordren er mottatt. Men ikke fullført?
  const handleSendOrderConfirmation = async () => {
    if (order) {
      try {
        const functions = getFunctions(undefined, "europe-west1");
        const sendOrderConfirmation = httpsCallable(
          functions,
          "sendOrderConfirmationEmail"
        );

        const response = await sendOrderConfirmation({
          email: order.email,
          orderInfo: order.orderInfo,
          contactInfo: order.contactInfo,
          orderId: order.id,
        });

        const data = response.data as { success: boolean }; // Type-casting response.data

        if (data.success) {
          alert("Ordrebekreftelse sendt!");
        } else {
          alert("Noe gikk galt under sending av e-post.");
        }
      } catch (error) {
        console.error("Error sending order confirmation:", error);
        alert("Noe gikk galt under sending av e-post.");
      }
    }
  };

  const selectedFocusIndex = async (index: number) => {
    await setFocusedOrderItems(index);
  };

  const handleStatusUpdateClick = async () => {
    setLoading(true);
    let newStatus;
    switch (lastActivityContent) {
      case filteredOrderStates[0]:
        newStatus = filteredOrderStates[1];
        break;
      case filteredOrderStates[1]:
        newStatus = filteredOrderStates[2];
        break;
      case filteredOrderStates[2]:
        newStatus = filteredOrderStates[3];
        break;
      case filteredOrderStates[3]:
        newStatus = filteredOrderStates[4];
        break;
      case filteredOrderStates[4]:
        newStatus = filteredOrderStates[5];
        break;
      default:
        newStatus = filteredOrderStates[1];
        break;
    }

    if (
      newStatus === filteredOrderStates[filteredOrderStates.length - 2] &&
      order?.resourceNumber //siden det er en resourceNumber, så aktiveres denne kun for workwear.
    ) {
      //if løkke som aktiveres når en er på nest siste steg. siste er plukket opp av kunde. nest siste er klar for plukking opp.
      const firstName = order?.contactInfo.name.split(" ")[0];
      await sendSMS(
        order?.contactInfo.phone!,
        orderId!,
        `Hei! Din bestilling er klar for henting på  ${order?.deliveryDepartment}. Når du henter, marker “Jeg har hentet pakken”. Se bestillingen din: oslo.kommune.fikse.co/a/${orderId?.slice(0, 5)}`
      );
      const functions = getFunctions(app, "europe-west1");
      const workwearPickupFirstNotefication = httpsCallable(
        functions,
        "workwearPickupFirstNotefication"
      );
      await workwearPickupFirstNotefication({
        email: order?.email,
        orderID: orderId,
        pickUp: order?.deliveryDepartment,
      });
    } else if (newStatus == "orderStateDelivered" && order?.resourceNumber) {
      await sendSMS(
        order?.contactInfo.phone!,
        orderId!,
        `Hei,\n\n takk for at du bestilte reparasjon hos Oslo kommune via Fikse. \n\n Dette er et innovasjonsprosjekt, og vi vil gjerne ha din tilbakemelding på din opplevelse av løsningen. Svar på en kort undersøkelse her:\n http://shorturl.fikse.co/OsloKommune`
      );
      console.log("SMS sent to customer end of workwear, order delivered");
    }
    if (!finishedOrder) {
      setSelectedStatus(newStatus); // Update the state if needed elsewhere
      handleStatusUpdate(newStatus);
    } // Pass}
  };

  const handleRevertActivity = async (activityId: number) => {
    if (!order || !orderId) return;

    try {
      setLoading(true);

      // Find the activity to revert
      const activityIndex = order.activity.findIndex((a) => a.id === activityId);
      if (activityIndex === -1) return;

      // Get the previous activity's content as the new current status
      const newCurrentStatus =
        activityIndex > 0 ? order.activity[activityIndex - 1].content : orderStates[0];

      // Remove the last activity
      const updatedActivity = order.activity.slice(0, -1);

      // Update Firestore
      const orderRef = doc(db, database, orderId);
      await updateDoc(orderRef, {
        currentStatus: newCurrentStatus,
        activity: updatedActivity,
      });

      // Refresh order data
      await fetchOrder();
    } catch (error) {
      console.error("Failed to revert activity:", error);
      setError(t("workwearOrderPage.revertError"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mx-auto min-h-screen">
      {" "}
      {/* max-w-7xl*/}
      {loading && <LoadingSpinner />}
      {error && <ErrorBanner message={error} />}
      {!loading && !error && (
        <>
          {!isVerified ? (
            <AwaitingVerification email={email} />
          ) : showConfirmation ? (
            <ConfirmationView />
          ) : (
            <>
              {/*Header */}
              <h1 className="mb-8 pl-4 text-3xl">
                {t("workwearOrderPage.order")}: {order?.id.slice(0, 5)}
              </h1>
              {/*Avvik  SLETT */}
              {/*order?.activity &&
                order.activity[order.activity.length - 1].content ===
                  "orderStateDeliverToTailor" &&
                localStorage.getItem("roles")?.includes("tailor") && (
                  <>
                    <button
                      className="text-lef mb-14 w-full border-b border-t border-black bg-fikseRed p-4 text-base"
                      onClick={() => setAvvikModal(true)}
                    >
                      {
                        "dette er test tekst, denne må byttes ut, denne aktividere modal for avvik"
                      }
                    </button>

                    <ModalTile
                      open={AvvikModal}
                      onClose={() => setAvvikModal(false)}
                      title={t("CancelOrderTempTitle")}
                    >
                      <div className="space-y-6">
                        <p className="text-base">{t("cancelOrderConfirmation")}</p>
                        <div className="flex w-full border-t border-black">
                          <button
                            type="button"
                            className="flex-1 border-r border-black bg-fikseBeige p-4 text-base hover:bg-fikseBeigeHover"
                            onClick={() => setAvvikModal(false)}
                          >
                            {t("goBack")}
                          </button>
                          <button
                            type="button"
                            className="hover:bg-fikseRedHover flex-1 bg-fikseRed p-4 text-base"
                            onClick={() => {}}
                          >
                            {t("confirm")}
                          </button>
                        </div>{" "}
                      </div>
                    </ModalTile>
                  </>
                )*/}
              {/*tabs */}
              {!window.location.hash && (
                <OrderTabs
                  order={order!}
                  onChange={selectedFocusIndex}
                  activeTab={focusedOrderItems}
                />
              )}
              {!order?.paid &&
                orderId &&
                database != "workwearOrders" &&
                window.location.hash !== "#checkPayment" && (
                  <>
                    <div
                      className="mb-10 flex w-full cursor-pointer items-center justify-between border-y border-black bg-fikseRed p-4 text-left text-base"
                      onClick={() => initiateLateCheckout(orderId, database)}
                    >
                      <span>{t("orderIsNotPaid")}</span>
                      <ChevronRightIcon aria-hidden="true" />
                    </div>
                  </>
                )}
              {order?.activity[order.activity.length - 1].message && (
                <>
                  <div className="flex w-full items-center border-t border-black bg-fikseBlue p-4 text-left text-base">
                    <SignIcon className="mr-2 h-6 w-6" />
                    <span>{t("markPackage") + " " + orderId?.slice(0, 5)}</span>
                  </div>
                  <div className="mb-10 flex w-full items-center border-b border-t border-black bg-fikseBlue p-4 text-left text-base">
                    <DeliverIcon className="mr-2 h-6 w-6" />
                    <span>
                      {t("nextStep")}
                      {order?.theyDeliver
                        ? "Ahlsell"
                        : order?.deliveryDepartment +
                          t("nextStepTwo") +
                          order?.deliveryAddress}{" "}
                      {t("nextStepThree")}
                    </span>
                  </div>
                </>
              )}
              {actionNeedsToBeTaken && (
                <>
                  <div className="mb-10 flex w-full items-center border-b border-t border-black bg-fikseBlue p-4 text-left text-base">
                    <SignIcon className="mr-2 h-6 w-6" />
                    <span>Venter svar fra kunde på endringer.</span>
                  </div>
                </>
              )}
              {lastActivityContent === "orderDeviation" && (
                <DeviationPromt
                  newPrice={order?.activity[order.activity.length - 1].deviationPrice}
                  tailorsNote={order?.tailorsNote}
                  onDeclinedClick={async () => {
                    setLoading(true);
                    await cancelOrderInFirestore(database, orderId!, t);
                    window.location.reload();
                  }}
                  onAcceptClick={async () => {
                    setLoading(true);
                    await newActivity(database, orderId!, "orderStateDeliverToTailor");
                    window.location.reload();
                  }}
                />
              )}
              {order &&
                (localStorage.getItem("verifiedUid") === localStorage.getItem("uid") ? (
                  <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
                    <OrderCard
                      order={order}
                      activity={order.activity}
                      index={focusedOrderItems}
                      database={database}
                    />
                    <OrderLog
                      order={order}
                      activity={order.activity}
                      onRevert={handleRevertActivity}
                    />
                    <div className="p-8" />
                  </div>
                ) : (
                  <AwaitingVerification email={email} />
                ))}{" "}
              {/*order?.activity &&
                order.activity[order.activity.length - 1].content ===
                  "orderStateDeliverToTailor" &&
                localStorage.getItem("roles")?.includes("tailor") && (
                  <AvvikIOrdre
                    orderType=database
                    orderId={orderId!}
                    onButtonClick={() => setLoading(true)}
                  />
                )*/}
              {/*BottomBar*/}
              {showUpdateStatus && !actionNeedsToBeTaken && (
                <>
                  <SwipeBottomBar
                    onSwipe={handleStatusUpdateClick}
                    text={
                      (order &&
                        order.activity &&
                        `${
                          order?.activity.length == 1
                            ? t("workwearOrderPage.orderStateDeliveredBar")
                            : t(`workwearOrderPage.${lastActivityContent}Bar`)
                        }`) ||
                      ""
                    }
                  />
                  {localStorage.getItem("roles")?.includes("operator") && (
                    <PreCancleOrderProps Order={order!} Database={database} />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default WorkwearOrderPage;
