// HalfCircleGauge.tsx
import React from "react";

interface HalfCircleGaugeProps {
  value: number;
  total: number;
  name: string;
}

const HalfCircleGauge: React.FC<HalfCircleGaugeProps> = ({ value, total, name }) => {
  // Calculate percentage
  const percentage: number = Math.min(Math.round((value / total) * 100), 100);

  // SVG parameters
  const radius: number = 70;
  const strokeWidth: number = 30;
  const viewBoxSize: number = 200;
  const center: number = viewBoxSize / 2;

  // Calculate the arc path
  const calculateArc = (percentValue: number): string => {
    const angle: number = (percentValue / 100) * 180;
    const startAngle: number = 180;
    const endAngle: number = startAngle + angle;

    // Convert angles to radians
    const startRad: number = (startAngle * Math.PI) / 180;
    const endRad: number = (endAngle * Math.PI) / 180;

    // Calculate end points
    const x1: number = center + radius * Math.cos(startRad);
    const y1: number = center + radius * Math.sin(startRad);
    const x2: number = center + radius * Math.cos(endRad);
    const y2: number = center + radius * Math.sin(endRad);

    // Create arc flag
    const largeArcFlag: number = percentValue > 50 ? 1 : 0;

    return `M ${x1} ${y1} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2}`;
  };

  return (
    <div className="flex items-center gap-4 p-4">
      <div className="relative">
        <svg viewBox={`0 0 ${viewBoxSize} ${viewBoxSize / 1.5}`} className="w-64">
          {/* Background arc */}
          <path
            d={calculateArc(100)}
            fill="none"
            stroke="#8CD7FF"
            strokeWidth={strokeWidth}
            strokeLinecap="butt"
          />

          {/* Percentage arc */}
          <path
            d={calculateArc(percentage)}
            fill="none"
            stroke="#D3FF8C"
            strokeWidth={strokeWidth}
            strokeLinecap="butt"
          />

          {/* Percentage text */}
          <text
            x={center}
            y={center - 10}
            textAnchor="middle"
            className="text-3xl font-bold"
            fill="#1F2937"
          >
            {percentage}%
          </text>
        </svg>
      </div>

      {/* Name label */}
      <div className="text-xl text-gray-700">{name}</div>
    </div>
  );
};

// Example usage
const ExampleUsage: React.FC = () => {
  return (
    <div className="p-8">
      <HalfCircleGauge value={16} total={100} name="Paleet" />
    </div>
  );
};

export default HalfCircleGauge;
