import React from "react";

import { Cell, Pie, PieChart } from "recharts";

interface DataPoint {
  name: string;
  value: number;
  color?: string;
}

interface DonutChartProps {
  data: DataPoint[];
  width?: number;
  height?: number;
  className?: string;
}

const DonutChart: React.FC<DonutChartProps> = ({
  data,
  width = 600,
  height = 400,
  className = "",
}) => {
  const defaultColors = [
    "#9cd5fb", // Høyer woman - light blue
    "#275cae", // Storo - navy blue
    "#D3FF8C", // Online - light green
  ];

  // Sort data alphabetically and assign letters
  const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name));
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const enrichedData = sortedData.map((point, index) => ({
    ...point,
    letter: letters[index],
    fill: point.color || defaultColors[index % defaultColors.length],
  }));

  // Calculate total for percentage
  const total = enrichedData.reduce((sum, item) => sum + item.value, 0);

  return (
    <div className={`relative ${className}`}>
      <div className="flex items-center">
        <PieChart width={width / 2} height={height}>
          <Pie
            data={enrichedData}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={100}
            dataKey="value"
            startAngle={90}
            endAngle={450}
          >
            {enrichedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.fill} />
            ))}
          </Pie>
          {/* Add letters in the segments */}
          {enrichedData.map((entry, index) => {
            const angle = (entry.value / total) * 360;
            const previousAngles = enrichedData
              .slice(0, index)
              .reduce((sum, item) => sum + (item.value / total) * 360, 0);
            const currentAngle = previousAngles + angle / 2;
            const radius = 80; // Adjust this for letter positioning
            const x =
              Math.cos(((currentAngle - 90) * Math.PI) / 180) * radius + width / 4;
            const y =
              Math.sin(((currentAngle - 90) * Math.PI) / 180) * radius + height / 2;

            return (
              <text
                key={`letter-${index}`}
                x={x}
                y={y}
                textAnchor="middle"
                dominantBaseline="middle"
                fill="black"
                fontSize={16}
                fontWeight="bold"
              >
                {entry.letter}
              </text>
            );
          })}
        </PieChart>

        {/* Legend */}
        <div className="ml-8 space-y-4">
          {enrichedData.map((item, index) => (
            <div key={`legend-${index}`} className="flex items-center space-x-2">
              <div className="text-2xl">
                {item.letter} {item.name}
              </div>
              <div className="ml-4 text-4xl">
                {Math.round((item.value / total) * 100)}%
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
