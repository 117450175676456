import { useEffect, useState } from "react";

import { getAuth } from "firebase/auth";

import {
  getMicrosoftGraphInfo,
  signInWithMicrosoftHighDataRetrical,
  signOutUser,
} from "../auth";
import NavBar from "../components/NavBar";

export default function TestMedMicrosoftOslo() {
  // Add new state for logs
  const [logs, setLogs] = useState<string[]>([]);
  const [graphData, setGraphData] = useState<any>(null);
  const [tokenData, setTokenData] = useState<any>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMicrosoftUser, setIsMicrosoftUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth = getAuth();

  // Add custom logging function
  const customLog = (message: string, data?: any) => {
    const logMessage = data ? `${message}: ${JSON.stringify(data, null, 2)}` : message;
    console.log(logMessage);
    setLogs((prev) => [...prev, logMessage]);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setIsLoggedIn(!!user);
      if (user) {
        const provider = user.providerData.find(
          (provider) => provider.providerId === "microsoft.com"
        );
        setIsMicrosoftUser(!!provider);

        if (provider) {
          handleCheckPermissions();
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const handleCheckPermissions = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (!user) return;

      // Get the token result which contains claims
      const tokenResult = await user.getIdTokenResult();
      customLog("Token data", tokenResult);
      setTokenData(tokenResult);

      // Also try to get Graph data to test permissions
      const data = await getMicrosoftGraphInfo();
      customLog("Graph data", data);
      setGraphData(data);
    } catch (error) {
      customLog("Feil ved sjekking av tilganger", error);
    }
    setLoading(false);
  };

  const formatDataForEmail = () => {
    const emailBody = `
=== CONSOLE LOGS START ===
${logs.join("\n")}
=== CONSOLE LOGS END ===

=== TOKEN DATA START ===
${JSON.stringify(tokenData?.claims, null, 2)}
=== TOKEN DATA END ===

=== GRAPH DATA START ===
${JSON.stringify(graphData, null, 2)}
=== GRAPH DATA END ===
    `;
    return encodeURIComponent(emailBody);
  };

  const handleEmailShare = () => {
    const subject = encodeURIComponent("Microsoft Integration Test Data");
    const body = formatDataForEmail();
    window.location.href = `mailto:simon@fikse.co?subject=${subject}&body=${body}`;
  };

  const renderTokenInfo = () => {
    if (!tokenData) return null;

    return (
      <div className="space-y-4">
        <h3 className="text-lg font-semibold">Token Informasjon:</h3>
        <div className="rounded border bg-gray-50 p-4">
          <div className="mb-4">
            <h4 className="font-medium">Utstedt av:</h4>
            <p className="text-sm">{tokenData.claims.iss}</p>
          </div>
          <div className="mb-4">
            <h4 className="font-medium">Organisasjon:</h4>
            <p className="text-sm">{tokenData.claims.aud}</p>
          </div>
          <div>
            <h4 className="font-medium">Claims:</h4>
            <pre className="whitespace-pre-wrap text-sm">
              {JSON.stringify(tokenData.claims, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    );
  };

  const renderGraphData = () => {
    if (!graphData) return null;

    return (
      <div className="space-y-4">
        <h3 className="text-lg font-semibold">Graph API Tilgangstesting:</h3>
        <div className="rounded border bg-gray-50 p-4">
          <pre className="whitespace-pre-wrap text-sm">
            {JSON.stringify(graphData, null, 2)}
          </pre>
        </div>
      </div>
    );
  };

  return (
    <>
      <NavBar />
      <div className="container mx-auto p-4">
        {!isLoggedIn && (
          <button
            onClick={signInWithMicrosoftHighDataRetrical}
            className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          >
            Logg inn med Microsoft
          </button>
        )}

        {isLoggedIn && !isMicrosoftUser && (
          <div>
            <p className="mb-4">Du er ikke logget inn med Microsoft.</p>
            <button
              onClick={async () => {
                await signOutUser();
                await signInWithMicrosoftHighDataRetrical();
              }}
              className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
            >
              Bytt til Microsoft-innlogging
            </button>
          </div>
        )}

        {isLoggedIn && isMicrosoftUser && (
          <div className="space-y-6">
            <div className="flex gap-4">
              <button
                onClick={handleCheckPermissions}
                className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 disabled:opacity-50"
                disabled={loading}
              >
                {loading ? "Sjekker tilganger..." : "Sjekk Microsoft tilganger"}
              </button>

              <button
                onClick={signOutUser}
                className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
              >
                Logg ut
              </button>

              {(tokenData || graphData) && (
                <button
                  onClick={handleEmailShare}
                  className="rounded bg-green-500 px-4 py-2 font-bold text-white hover:bg-green-700"
                >
                  Send data til simon@fikse.co
                </button>
              )}
            </div>

            {loading && (
              <div className="text-gray-600">Henter tilgangsinformasjon...</div>
            )}

            {tokenData && renderTokenInfo()}
            {graphData && renderGraphData()}
          </div>
        )}
      </div>
    </>
  );
}
