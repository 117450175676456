import React, { useEffect, useState } from "react";

import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { bool } from "prop-types";
import { useTranslation } from "react-i18next";

import ModalTile from "../components/ModalTile";
import { ContactItem, ContentBlock, Language } from "./types";

interface ContactUsBlockProps {
  block: ContentBlock;
  index: number;
  offset?: number;
}

const ContactUsBlock: React.FC<ContactUsBlockProps> = ({ block }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const languagePicker = (input: Language[]) => {
    return input.find((item) => item.language === currentLanguage)?.text ?? "";
  };

  console.log("XXblock", block);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    firm: "", // Add firm field
    message: "",
  });

  const [validMessage, setValidMessage] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    setValidMessage(
      Boolean(formData.name && formData.email && formData.firm && formData.message)
    );
  }, [formData]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  interface ContactFormResponse {
    success: boolean;
    inquiryNumber: number;
    message: string;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      console.log("Starting form submission");
      const functions = getFunctions(getApp(), "europe-west1");
      console.log("Got functions instance");

      const sendContactUsEmail = httpsCallable(functions, "sendContactUsEmail");
      console.log("Created callable");
      const result = sendContactUsEmail(formData);
      console.log("Got result:", result);

      // Clear form and show confirmation
      setFormData({
        name: "",
        email: "",
        phone: "",
        firm: "",
        message: "",
      });
      setShowConfirmation(true);
    } catch (error: any) {
      console.error("Error submitting form:", error);
      const errorMessage =
        error?.message || "There was an error submitting your message. Please try again.";
      alert(errorMessage);
    }
  };

  return (
    <div className="border-b border-black pt-4 text-sm sm:text-base">
      <div className="p-4 px-16 text-3xl">
        {block._type === "contactUsBlock" &&
          Array.isArray(block.title) &&
          languagePicker(block.title)}
      </div>{" "}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="px-16">
          <div className="grid grid-cols-1 md:grid-cols-2">
            {/* First row */}
            <div>
              <label htmlFor="name" className="mb-2 block">
                {t("contactUs.name")}
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mb-4 w-full border border-black p-2 text-base"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="mb-2 block">
                {t("contactUs.email")}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mb-4 w-full border border-black p-2 text-base"
                required
              />
            </div>

            {/* Second row */}
            <div>
              <div className="mb-2 flex items-center gap-2">
                <label htmlFor="phone" className="block">
                  {t("contactUs.phone")}
                </label>
                <label htmlFor="phone" className="text-color-gray-500 text-xs">
                  {t("contactUs.nonManadatory")}
                </label>
              </div>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="mb-4 w-full border border-black p-2 text-base"
              />
            </div>
            <div>
              <label htmlFor="firm" className="mb-2 block">
                {t("contactUs.firm")}
              </label>
              <input
                type="text"
                id="firm"
                name="firm"
                value={formData.firm}
                onChange={handleChange}
                className="mb-4 w-full border border-black p-2 text-base"
                required
              />
            </div>
          </div>

          <div>
            <label htmlFor="message" className="mb-2 block">
              {t("contactUs.message")}
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder={t("contactUs.contactUsMessage")}
              className="w-full border border-black p-2"
              required
            />
          </div>
        </div>

        <button
          type="submit"
          className={`w-full border-t border-black ${validMessage ? "bg-fikseGreen" : "bg-fikseGreenLight"} p-4`}
        >
          {t("contactUs.sendButtonText")}
        </button>
      </form>
      <ModalTile
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        title={t("contactUs.confirmationTitle")}
      >
        <div className="p-6">
          <p className="text-lg">{t("contactUs.confirmationMessage")}</p>
        </div>
      </ModalTile>
    </div>
  );
};

export default ContactUsBlock;
