import React from "react";

import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";

interface DataPoint {
  year: number;
  value: number;
  quarter: string; // Add this new property
}

interface FinancialTrendChartProps {
  data: DataPoint[];
  width?: number;
  height?: number;
  className?: string;
}

const FinancialTrendChart: React.FC<FinancialTrendChartProps> = ({
  data,
  width = 600,
  height = 300,
  className = "",
}) => {
  // Validate data is sorted by year
  React.useEffect(() => {
    const isSorted = data.every(
      (point, index) => index === 0 || point.year >= data[index - 1].year
    );
    if (!isSorted) {
      console.warn(
        "FinancialTrendChart: Data should be sorted by year for optimal display"
      );
    }
  }, [data]);

  // Transform data to include quarter display
  const formattedData = data.map((point) => ({
    ...point,
    displayLabel: point.quarter, // Use the quarter string directly
  }));

  return (
    <div className={`w-full max-w-2xl p-4 ${className}`}>
      <LineChart
        width={width}
        height={height}
        data={formattedData}
        margin={{ top: 20, right: 30, left: 30, bottom: 20 }}
      >
        <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
        <XAxis dataKey="displayLabel" axisLine={false} tickLine={false} />
        <YAxis hide={true} domain={["auto", "auto"]} />
        <Line
          type="monotone"
          dataKey="value"
          stroke="#000000"
          strokeWidth={2}
          dot={{ stroke: "#000000", strokeWidth: 2, r: 4, fill: "#000000" }}
        />
        {/* Value Labels */}
        {formattedData.map((entry, index) => (
          <g key={`label-${index}`}>
            <text
              x={Math.max(
                30,
                Math.min(
                  index * ((width - 60) / (formattedData.length - 1)) + 30,
                  width - 30
                )
              )}
              y={
                height -
                60 - // Increased padding from 20 to 40
                entry.value /
                  (Math.max(...formattedData.map((d) => d.value)) / (height * 0.6))
              }
              textAnchor="middle"
              fill="#000000"
              fontSize={24} // Reduced font size
            >
              {`€ ${entry.value}`}
            </text>
          </g>
        ))}
      </LineChart>
    </div>
  );
};

export default FinancialTrendChart;
