import {
  collection,
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import { db } from "../firebase";

export const newActivity = async (
  orderType: string,
  orderId: string,
  activity: string
) => {
  try {
    const orderRef = doc(db, orderType, orderId);

    // Get the current document
    const orderSnapshot = await getDoc(orderRef);

    if (orderSnapshot.exists()) {
      const currentOrderData = orderSnapshot.data();

      // Add the new activity to the current list of activities
      const newActivity = {
        id: currentOrderData.activity ? currentOrderData.activity.length + 1 : -1,
        content: activity,
        date: new Date().toLocaleDateString(),
        dateTime: new Date().toISOString(),
      };

      const updatedActivities = currentOrderData.activity
        ? [...currentOrderData.activity, newActivity]
        : [newActivity]; // If no activity array exists, create one

      // Update the document with the new activity
      await updateDoc(orderRef, {
        activity: updatedActivities, // Update the activity field
      });

      console.log("Order updated successfully with new activity");
    } else {
      console.log("Document does not exist");
    }
  } catch (error) {
    console.error("Error adding new activity:", error);
  }
};
