import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from "./locales/en.json";
import noTranslations from "./locales/no.json";

// Get stored language or default to Norwegian
const storedLanguage = localStorage.getItem("language") || "no";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslations },
    no: { translation: noTranslations },
  },
  lng: storedLanguage,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

// Save language selection when it changes
i18n.on("languageChanged", (lng) => {
  localStorage.setItem("language", lng);
});

export default i18n;
