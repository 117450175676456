import React, { useRef, useState } from "react";

import ModalTile from "../components/ModalTile";
import { ReactComponent as ChevronRightIcon } from "../images/icons-fikse/chevron.svg";

interface SwipeBottomBarProps {
  onSwipe: () => void;
  text: string;
  visible?: boolean;
  finishedOrder?: boolean;
}

const SwipeBottomBar: React.FC<SwipeBottomBarProps> = ({
  onSwipe,
  text,
  visible = true,
  finishedOrder = false,
}) => {
  const [swipePosition, setSwipePosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const touchStartX = useRef(0);
  const containerRef = useRef<HTMLDivElement>(null);

  if (!visible) return null;

  const handleTouchStart = (e: React.TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    touchStartX.current = e.touches[0].clientX;
    setIsDragging(true);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging) return;
    e.preventDefault();
    e.stopPropagation();

    const touch = e.touches[0];
    const containerRect = containerRef.current?.getBoundingClientRect();
    if (!containerRect) return;

    // Only handle touch events within the container bounds
    if (
      touch.clientX < containerRect.left ||
      touch.clientX > containerRect.right ||
      touch.clientY < containerRect.top ||
      touch.clientY > containerRect.bottom
    ) {
      handleTouchEnd(e);
      return;
    }

    const currentX = touch.clientX;
    const diff = touchStartX.current - currentX; // Changed: reversed difference calculation
    const containerWidth = containerRect.width;
    const maxSwipe = containerWidth - 64;

    const newPosition = Math.max(0, Math.min(diff, maxSwipe));
    setSwipePosition(newPosition);

    if (newPosition >= maxSwipe * 0.9) {
      setIsDragging(false);
      setSwipePosition(0);
      onSwipe();
    }
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    setSwipePosition(0);
  };

  const handleDesktopClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirm = () => {
    setIsModalOpen(false);
    onSwipe();
  };

  return (
    <>
      {/* Desktop Button - hidden on mobile and tablet */}
      <button
        onClick={handleDesktopClick}
        className="fixed bottom-0 left-0 hidden w-full items-center justify-between border-b border-t border-black bg-fikseGreen p-4 px-8 py-4 text-xl lg:flex"
      >
        <span className="lg:mx-auto">{text}</span>
        {!finishedOrder && <ChevronRightIcon aria-hidden="true" />}
      </button>

      {/* Mobile/Tablet Swipe Bar - hidden on larger desktop */}
      <div className="fixed bottom-0 left-0 w-full touch-none select-none lg:hidden">
        <div
          ref={containerRef}
          className="relative h-16 overflow-hidden border-b border-t border-black bg-white"
        >
          <div
            className="pointer-events-none absolute inset-0 flex items-center justify-center pr-16" // Changed: pl-16 to pr-16
            style={{
              background: `linear-gradient(to left, #D3FF8C ${swipePosition}px, white ${swipePosition}px)`, // Changed: to left
              transition: isDragging ? "none" : "background 0.3s ease-out",
            }}
          >
            <span className="truncate px-4 text-center text-xl">{text}</span>
          </div>

          <div
            className="absolute right-0 top-0 h-full w-16 cursor-grab touch-pan-x border-black bg-fikseGreen active:cursor-grabbing" // Changed: left-0 to right-0
            style={{
              transform: `translateX(-${swipePosition}px)`, // Changed: added negative
              transition: isDragging ? "none" : "transform 0.3s ease-out",
              touchAction: "pan-x",
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div className="flex h-full items-center justify-center">
              <ChevronRightIcon className="h-10 w-10 rotate-180" />{" "}
              {/* Added: rotate-180 */}
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <ModalTile
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Confirm Status Update"
      >
        <div className="space-y-6">
          <p className="p-4 text-base">
            Are you sure you want to update the status to the next step?
          </p>
          <div className="flex w-full border-t border-black">
            <button
              type="button"
              className="flex-1 border-r border-black bg-fikseBeige p-4 text-base hover:bg-fikseBeigeHover"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="hover:bg-fikseGreenHover flex-1 bg-fikseGreen p-4 text-base"
              onClick={handleConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </ModalTile>
    </>
  );
};

export default SwipeBottomBar;
