import { useEffect, useState } from "react";

import { get } from "http";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getCurrentUserEmail, signOutUser } from "../../auth";
import { ReactComponent as FikseFamily } from "../../images/Illustration/familie.svg";
import { ReactComponent as WorkerBoy } from "../../images/Illustration/workerboy.svg";
import jjLogo from "../../images/Logoer/JJ logo.png";
import acneLogo from "../../images/Logoer/acne.png";
import cirkularLogo from "../../images/Logoer/cirkulær.jpg";
import dapperLogo from "../../images/Logoer/dapper logo.webp";
import ensembleLogo from "../../images/Logoer/ensemble.png";
import heimenLogo from "../../images/Logoer/heimen.png";
import maleneBirgerLogo from "../../images/Logoer/logoMaleneBirger.png";
import sveanLogo from "../../images/Logoer/svean.png";
import { ReactComponent as FikseLogo } from "../../images/fikse-logo.svg";
import { ReactComponent as ChevronRightIcon } from "../../images/icons-fikse/chevron.svg";
import AllOrder from "../AllOrder";
import DeleteOrdersButton from "../DeleteBulkOrdersButton";
import ModalTile from "../ModalTile";
import SignInWorkaround from "../SignInWorkaround";
import ContactUsModal from "../newOrder/ContactUsModal";
import OrderModal from "../newOrder/OrderModal";
import WorkwearModal from "../newOrder/WorkwearModal";

const OsloKommuneLandingPage = () => {
  const isLoggedIn = !!localStorage.getItem("uid");
  const [showSignIn, setShowSignIn] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showWorkwearModal, setShowWorkwearModal] = useState(false);
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const [unAuthorizedEmail, setUnAuthorizedEmail] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const logos = [
    acneLogo,
    cirkularLogo,
    dapperLogo,
    ensembleLogo,
    heimenLogo,
    jjLogo,
    maleneBirgerLogo,
    sveanLogo,
  ];

  useEffect(() => {
    if (window.location.hash === "#workwear") {
      setShowWorkwearModal(true);
    }
  }, []);

  const authorizedEmailDomains = (email: string): boolean => {
    return (
      email.endsWith("oslo.kommune.no") ||
      email.endsWith("osloskolen.no") ||
      email.endsWith("fikse.co") ||
      email.endsWith("ntnu.no") ||
      email.endsWith("egms.no")
    );
  };

  const isWorkWearDomain: boolean = (() => {
    const workwearDomains = [
      "oslo.kommune.fikse.co",
      "osloskolen.fikse.co",
      "ntnu.fikse.co",
    ];

    return workwearDomains.some((domain) => window.location.hostname.includes(domain));
  })();

  const isWorkWear: boolean = (() => {
    const email = localStorage.getItem("verifiedUid");

    return email
      ? email.endsWith("oslo.kommune.no") ||
          email.endsWith("osloskolen.no") ||
          email.endsWith("fikse.co") ||
          email.endsWith("ntnu.no") ||
          email.endsWith("egms.no")
      : false;
  })();

  const handleCategoryClick = (category: string) => {
    let serviceCategory = "";
    switch (category) {
      case "Clothes":
        serviceCategory = i18n.language === "no" ? "NO-Clothes-0924" : "EN-Clothes-0924";
        break;
      case "Shoes":
        serviceCategory = i18n.language === "no" ? "NO-Shoes-0924" : "EN-Shoes-0924";
        break;
      case "Accessories":
        serviceCategory =
          i18n.language === "no" ? "NO-Accessories-0924" : "EN-Accessories-0924";
        break;
      case "Bunad":
        serviceCategory = i18n.language === "no" ? "NO-Bunad-0924" : "EN-Bunad-0924";
        break;
      case "Workwear":
        serviceCategory =
          i18n.language === "no"
            ? "NO-Workwear/Ahlsell-0924"
            : "EN-Workwear/Ahlsell-0924";
        break;
      case "ContactUs":
        serviceCategory = i18n.language === "no" ? "NO-Contact" : "EN-Contact";
        break;
      default:
        serviceCategory = "EN-Clothes-0924";
    }
    console.log("category", category);
    console.log("serviceCategory", serviceCategory);
    localStorage.setItem("serviceCategory", serviceCategory);
    if (category === "Workwear") {
      setShowOrderModal(true);
    } else if (category === "ContactUs") {
      setShowContactUsModal(true);
      console.log("showContactUsModal", showContactUsModal);
    } else {
      setShowOrderModal(true);
    }
  };

  const handleCloseModal = (closeModalFn: () => void) => {
    setTimeout(() => {
      closeModalFn();
    }, 300);
  };

  const handleActiveOrderClick = () => {
    const activeOrder = localStorage.getItem("activeOrder");
    if (activeOrder) {
      navigate(`/orders/${activeOrder}`);
    }
  };

  const loggedInWithAlhSell =
    localStorage.getItem("verifiedUid")?.endsWith("ahlsell.no") ||
    localStorage.getItem("verifiedUid")?.endsWith("ntnu.no");

  return (
    <div className={`bg-white`}>
      <div className="py-4">
        <div className="flex flex-col items-center justify-center bg-white p-4 lg:flex-row lg:items-end lg:justify-between">
          <div className="flex w-full flex-grow flex-col items-center pl-0 lg:w-auto lg:flex-row lg:items-end lg:pl-12">
            <FikseLogo className="h-32 w-80 flex-shrink-0 pb-2" />
            <span className="mt-4 flex-grow whitespace-nowrap text-3xl lg:ml-4 lg:mt-0 lg:text-4xl">
              for Oslo kommune
            </span>
          </div>
          <div className="mt-4 h-72 w-72 flex-shrink-0 lg:mt-0">
            <WorkerBoy className="h-full w-full" />
          </div>
        </div>

        <div className="mt-4">
          {loggedInWithAlhSell && (
            <a
              href="#"
              className="flex items-center justify-between border-t border-black bg-fikseGreen p-4 px-8 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
              onClick={() => navigate(`/orders`)}
            >
              <span>Ahlsell dashboard</span>
              <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
            </a>
          )}
          <a
            href="#"
            className="flex items-center justify-between border-b border-t border-black p-4 px-8 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
            onClick={() => {
              if (getCurrentUserEmail() == "Anonymous") {
                //will be false if the user is not logged in
                setShowSignIn(true);
              } else if (authorizedEmailDomains(getCurrentUserEmail())) {
                handleCategoryClick("Workwear");
              } else {
                setUnAuthorizedEmail(true);
              }
            }}
          >
            <span>{t("mobileView.workwearB2W")}</span>
            <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          </a>
          {/*<a
            href="#"
            className="flex items-center justify-between border-b border-black p-4 px-8 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
            onClick={() => {}}
          >
            <span>{t("mobileView.yourPage")}</span>
            <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          </a>*/}
          <a
            href="#"
            className="flex items-center justify-between border-b border-black p-4 px-8 text-lg sm:pl-16 sm:text-3xl sm:hover:bg-gray-50"
            onClick={() => handleCategoryClick("ContactUs")}
          >
            <span>{t("mobileView.contactUS")}</span>
            <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          </a>
        </div>
      </div>
      <AllOrder />

      {/*
      <h2 className="mt-12 p-8 text-4xl sm:ml-8">Mine aktive bestillinger</h2>
      <a
        href="#"
        className="flex items-center justify-between border-b border-t border-black bg-fikseBlue p-4 px-8 text-lg sm:pl-16 sm:text-3xl"
        onClick={() => {}}
      >
        <span>Jakke med ødelagt glidelås</span>
        <ChevronRightIcon className="mt-1 h-5 w-5 text-gray-500" aria-hidden="true" />
      </a>
*/}

      {/* prettier-ignore */}
      <p className="sm:mx-16 mx-10 mt-24 text-s sm:text-base whitespace-pre-line">
      {`Slik fungerer det:

        1. Trykk på "Bestill fiks av arbeidsklær" og logg inn med eposten din.

        2. Ta et bilde eller velg manuell registrering av det du vil reparere.

        3. Fullfør bestillingen. Husk å registrere ressursnummer og oppgi tydelig opphentingssted.

        4. Skriv koden på pakken.

        5. Lever til opphentingsstedet og len deg tilbake!

        Du får oppdateringer på SMS, slik at du kan følge med på bestillingen din. Plagget leveres tilbake ferdig reparert innen 7 virkedager.`}
       
      </p>

      <p className="mb-24 ml-10 whitespace-pre-line pt-20 text-base sm:mx-16 sm:text-base">
        Spørsmål? Ta kontakt på hei@fikse.co eller ring +47 484 50 390
      </p>

      <SignInWorkaround
        open={showSignIn}
        onClose={() => handleCloseModal(() => setShowSignIn(false))}
      />
      <OrderModal
        open={showOrderModal}
        onClose={() => handleCloseModal(() => setShowOrderModal(false))}
      />
      <WorkwearModal
        open={showWorkwearModal}
        onClose={() =>
          handleCloseModal(() => {
            setShowWorkwearModal(false);
          })
        }
      />
      <ContactUsModal
        open={showContactUsModal}
        onClose={() => handleCloseModal(() => setShowContactUsModal(false))}
      />
      <ModalTile
        open={unAuthorizedEmail}
        onClose={() => setUnAuthorizedEmail(false)}
        title="Manglende tilgang"
      >
        <div className="flex flex-col">
          <p className="mb-6 p-6 text-lg">
            {getCurrentUserEmail()}: E-posten har foreløpig ikke tilgang til denne
            tjenesten. Vi er et lite team som med glede tar imot telefoner, enten det
            gjelder lavterskel tilbakemeldinger eller annet. Ring oss gjerne på +47 484 50
            390.
          </p>
          <button
            onClick={async () => {
              await signOutUser();
              window.location.reload();
            }}
            className="mt-auto flex w-full flex-row justify-between rounded-none border-b border-t border-black bg-fikseGreen px-6 py-4 text-left text-lg"
          >
            Log out
            <ChevronRightIcon className="mt-1 h-5 w-5 text-gray-500" aria-hidden="true" />
          </button>
        </div>
      </ModalTile>
    </div>
  );
};

export default OsloKommuneLandingPage;
