import React, { useEffect, useState } from "react";

import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";

import { db } from "../firebase";
import { generateAndDownloadTailorNote } from "../tools/tailorNotes";

interface Order {
  id: string;
  activity: Array<{
    content: string;
    date: string;
    dateTime: string;
  }>;
  orderInfo: Array<{
    item: string;
    problem: string;
  }>;
  contactInfo: {
    name: string;
    phone: string;
  };
  date: string;
  deliveryMethod: string;
  retrieveMethod: string;
  delegatedTailor?: string; // New field
  paid?: boolean;
}

interface Tailor {
  id: string;
  name: string;
}

interface PendingTailorChange {
  orderId: string;
  oldTailor: string | undefined;
  newTailor: string;
}

export default function LogisticsOrderTailorDeligation() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [tailors, setTailors] = useState<Tailor[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [sankeyData, setSankeyData] = useState<any[]>([["From", "To", "Weight"]]);
  const [deliveryMethodData, setDeliveryMethodData] = useState<any[]>([
    ["Delivery Method", "Count"],
  ]);
  const [retrieveMethodData, setRetrieveMethodData] = useState<any[]>([
    ["Retrieve Method", "Count"],
  ]);
  const [tailorOrdersData, setTailorOrdersData] = useState<any[]>([["Tailor", "Orders"]]);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [selectedRetrieveMethods, setSelectedRetrieveMethods] = useState<string[]>([]);
  const [selectedDeliveryMethods, setSelectedDeliveryMethods] = useState<string[]>([]);
  const [selectedTailors, setSelectedTailors] = useState<string[]>([]);
  const [orderToDelete, setOrderToDelete] = useState<string | null>(null);
  const [pendingTailorChange, setPendingTailorChange] =
    useState<PendingTailorChange | null>(null);

  // Replace the handleTailorAssignment function
  const handleTailorAssignment = async (orderId: string, newTailorId: string) => {
    const order = orders.find((o) => o.id === orderId);
    if (order) {
      setPendingTailorChange({
        orderId,
        oldTailor: order.delegatedTailor,
        newTailor: newTailorId,
      });
    }
  };

  const confirmTailorChange = async () => {
    if (!pendingTailorChange) return;

    try {
      const orderRef = doc(db, "b2cOrders", pendingTailorChange.orderId);
      await updateDoc(orderRef, {
        delegatedTailor: pendingTailorChange.newTailor,
      });

      // Update local state
      setOrders(
        orders.map((order) =>
          order.id === pendingTailorChange.orderId
            ? { ...order, delegatedTailor: pendingTailorChange.newTailor }
            : order
        )
      );
      setPendingTailorChange(null);
    } catch (error) {
      console.error("Error updating delegated tailor:", error);
    }
  };

  // Add function to check for special IDs
  const isSpecialId = (id: string) => {
    const specialIds = [
      "ADMINBYPASS",
      "COURIERtoDropOffLocation",
      "TAILORtoFikseFerdig",
      "TRANSPORTERtoTailor",
    ];
    return specialIds.includes(id);
  };

  // Add unique values helper function
  const getUniqueValues = (array: Order[], key: "deliveryMethod" | "retrieveMethod") => {
    return Array.from(new Set(array.map((item) => item[key])));
  };

  // Add new helper functions
  const getFilteredCount = (
    key: "retrieveMethod" | "deliveryMethod",
    value: string,
    filteredOrders: Order[]
  ) => {
    return filteredOrders.filter((order) => order[key] === value).length;
  };

  const getTailorCount = (tailorId: string, filteredOrders: Order[]) => {
    if (tailorId === "no-tailor") {
      return filteredOrders.filter((order) => !order.delegatedTailor).length;
    }
    return filteredOrders.filter((order) => order.delegatedTailor === tailorId).length;
  };

  const handleDelete = async (orderId: string) => {
    try {
      await deleteDoc(doc(db, "b2cOrders", orderId));
      setOrderToDelete(null);
      // Refresh orders
    } catch (error) {
      console.error("Error deleting order:", error);
      alert("Failed to delete order");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch tailors first
        const tailorsSnapshot = await getDocs(collection(db, "tailors"));
        const tailorList = tailorsSnapshot.docs
          .filter((doc) => !isSpecialId(doc.id))
          .map((doc) => ({
            id: doc.id,
            name: doc.data().name || doc.id,
          }));
        setTailors(tailorList);

        // Then fetch orders
        const ordersSnapshot = await getDocs(
          query(
            collection(db, "b2cOrders"),
            where("paid", "==", true),
            where("activity", "not-in", [
              { content: "orderStateDelivered" },
              { content: "Cancelled" },
              { content: "orderStateCancelled" },
            ]),
            orderBy("date", "asc")
          )
        );

        const fetchedOrders: Order[] = [];
        ordersSnapshot.forEach((doc) => {
          const data = doc.data() as Order;
          const lastActivity = data.activity?.[data.activity.length - 1]?.content;
          if (
            lastActivity !== "orderStateDelivered" &&
            lastActivity !== "Cancelled" &&
            lastActivity !== "orderStateCancelled"
          ) {
            fetchedOrders.push({ ...data, id: doc.id });
          }
        });

        setOrders(fetchedOrders);
        setFilteredOrders(fetchedOrders);

        // Process Sankey data after both orders and tailors are available
        const flowMap = new Map<string, number>();
        fetchedOrders.forEach((order) => {
          const deliveryMethod = order.deliveryMethod;
          const tailorName =
            tailorList.find((t) => t.id === order.delegatedTailor)?.name ||
            "No Tailor Assigned";
          const retrieveMethod = order.retrieveMethod;

          const fromDelivery = deliveryMethod;
          const toRetrieve = retrieveMethod + " ";

          const deliveryToTailor = `${fromDelivery}->${tailorName}`;
          flowMap.set(deliveryToTailor, (flowMap.get(deliveryToTailor) || 0) + 1);

          const tailorToRetrieve = `${tailorName}->${toRetrieve}`;
          flowMap.set(tailorToRetrieve, (flowMap.get(tailorToRetrieve) || 0) + 1);
        });

        const sankeyDataArray: Array<[string, string, string | number]> = [
          ["From", "To", "Weight"],
        ];

        flowMap.forEach((weight, key) => {
          const [from, to] = key.split("->");
          sankeyDataArray.push([from, to, weight]);
        });

        setSankeyData(sankeyDataArray);
        console.log("Sankey data:", sankeyDataArray);

        // Process data for charts after orders are fetched
        const deliveryMethods = new Map<string, number>();
        const retrieveMethods = new Map<string, number>();
        const tailorOrders = new Map<string, number>();

        fetchedOrders.forEach((order) => {
          // Count delivery methods
          deliveryMethods.set(
            order.deliveryMethod,
            (deliveryMethods.get(order.deliveryMethod) || 0) + 1
          );

          // Count retrieve methods
          retrieveMethods.set(
            order.retrieveMethod,
            (retrieveMethods.get(order.retrieveMethod) || 0) + 1
          );

          // Count orders per tailor
          if (order.delegatedTailor) {
            const tailorName =
              tailorList.find((t) => t.id === order.delegatedTailor)?.name ||
              order.delegatedTailor;
            tailorOrders.set(tailorName, (tailorOrders.get(tailorName) || 0) + 1);
          }
        });

        // Convert to chart data format
        setDeliveryMethodData([
          ["Delivery Method", "Count"],
          ...Array.from(deliveryMethods).map(([method, count]) => [method, count]),
        ]);

        setRetrieveMethodData([
          ["Retrieve Method", "Count"],
          ...Array.from(retrieveMethods).map(([method, count]) => [method, count]),
        ]);

        // Convert to chart data format with reversed array order and numbers for values
        setTailorOrdersData([
          // First row defines column types
          [
            { type: "string", label: "Tailor" },
            { type: "number", label: "Orders" },
          ],
          // Data rows
          ...Array.from(tailorOrders).map(([tailor, count]) => [tailor, count]),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array since we only want to fetch once

  // Add filter effect
  useEffect(() => {
    let result = [...orders];

    if (selectedRetrieveMethods.length > 0) {
      result = result.filter((order) =>
        selectedRetrieveMethods.includes(order.retrieveMethod)
      );
    }

    if (selectedDeliveryMethods.length > 0) {
      result = result.filter((order) =>
        selectedDeliveryMethods.includes(order.deliveryMethod)
      );
    }

    if (selectedTailors.length > 0) {
      if (selectedTailors.includes("no-tailor")) {
        result = result.filter(
          (order) =>
            !order.delegatedTailor || selectedTailors.includes(order.delegatedTailor)
        );
      } else {
        result = result.filter(
          (order) =>
            order.delegatedTailor && selectedTailors.includes(order.delegatedTailor)
        );
      }
    }

    setFilteredOrders(result);
  }, [orders, selectedRetrieveMethods, selectedDeliveryMethods, selectedTailors]);

  const getLastActivityStatus = (order: Order) => {
    if (!order.activity || order.activity.length === 0) return "No activity";

    if (order.activity[order.activity.length - 1].content == "Order created")
      return "Order created";

    return t(`workwearOrderPage.${order.activity[order.activity.length - 1].content}`);
  };

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="text-lg font-semibold">Loading orders...</div>
      </div>
    );
  }

  // Handler for checkbox changes
  const handleCheckboxChange = (
    value: string,
    isChecked: boolean,
    setter: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    setter((prev) =>
      isChecked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6 flex items-center justify-between">
        <h1 className="text-2xl font-bold">{t("Orders")}</h1>
        {!window.location.pathname.includes("TailorDeligationDashboard") && (
          <button
            onClick={() => (window.location.href = "/TailorDeligationDashboard")}
            className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
          >
            {t("Advanced Options")}
          </button>
        )}
      </div>

      {/* Add z-index to the chart container */}
      <div className="relative mb-8 h-[600px] w-full rounded border shadow">
        <div className="absolute inset-0" style={{ zIndex: 70 }}>
          <Chart
            chartType="Sankey"
            width="100%"
            height="100%"
            data={sankeyData}
            options={{
              tooltip: {
                textStyle: { fontSize: 14 },
                trigger: "both",
                isHtml: true, // Enable HTML tooltips
              },
              sankey: {
                link: {
                  interactivity: true,
                  tooltip: {
                    trigger: "both",
                    textStyle: { fontSize: 14 },
                  },
                },
                node: {
                  interactivity: true,
                  width: 20,
                  nodePadding: 10,
                },
              },
            }}
          />
        </div>
      </div>

      {/* Three charts side by side */}
      <div className="mb-8 grid grid-cols-3 gap-4">
        <div className="rounded border p-4 shadow">
          <Chart
            chartType="PieChart"
            data={deliveryMethodData}
            options={{
              title: "Delivery Methods Distribution",
              pieHole: 0.4,
              is3D: false,
            }}
            width="100%"
            height="400px"
          />
        </div>

        <div className="rounded border p-4 shadow">
          <Chart
            chartType="PieChart"
            data={retrieveMethodData}
            options={{
              title: "Retrieve Methods Distribution",
              pieHole: 0.4,
              is3D: false,
            }}
            width="100%"
            height="400px"
          />
        </div>

        <div className="rounded border p-4 shadow">
          <Chart
            chartType="BarChart"
            data={tailorOrdersData}
            options={{
              title: "Orders per Tailor",
              chartArea: { width: "50%" },
              hAxis: {
                title: "Number of Orders",
                minValue: 0,
                format: "0",
              },
              vAxis: {
                title: "Tailor",
              },
              legend: { position: "none" }, // Remove legend since it's clear what the bars represent
            }}
            width="100%"
            height="400px"
          />
        </div>
      </div>

      {/* Updated Multi-select filter section */}
      <div className="mb-8 grid grid-cols-3 gap-4">
        <div className="rounded border p-4 shadow">
          <h3 className="mb-2 font-medium">Filter by Retrieve Methods</h3>
          <div className="max-h-48 overflow-y-auto">
            {getUniqueValues(orders, "retrieveMethod").map((method) => {
              const count = getFilteredCount("retrieveMethod", method, orders);
              return (
                <label
                  key={method}
                  className={`flex items-center space-x-2 p-1 ${
                    count === 0 ? "opacity-50" : ""
                  }`}
                >
                  <span className="w-8 text-right text-gray-500">{count}</span>
                  <input
                    type="checkbox"
                    checked={selectedRetrieveMethods.includes(method)}
                    onChange={(e) =>
                      handleCheckboxChange(
                        method,
                        e.target.checked,
                        setSelectedRetrieveMethods
                      )
                    }
                    className="ml-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    disabled={count === 0}
                  />
                  <span>{method}</span>
                </label>
              );
            })}
          </div>
        </div>

        <div className="rounded border p-4 shadow">
          <h3 className="mb-2 font-medium">Filter by Delivery Methods</h3>
          <div className="max-h-48 overflow-y-auto">
            {getUniqueValues(orders, "deliveryMethod").map((method) => {
              const count = getFilteredCount("deliveryMethod", method, orders);
              return (
                <label
                  key={method}
                  className={`flex items-center space-x-2 p-1 ${
                    count === 0 ? "opacity-50" : ""
                  }`}
                >
                  <span className="w-8 text-right text-gray-500">{count}</span>
                  <input
                    type="checkbox"
                    checked={selectedDeliveryMethods.includes(method)}
                    onChange={(e) =>
                      handleCheckboxChange(
                        method,
                        e.target.checked,
                        setSelectedDeliveryMethods
                      )
                    }
                    className="ml-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    disabled={count === 0}
                  />
                  <span>{method}</span>
                </label>
              );
            })}
          </div>
        </div>

        <div className="rounded border p-4 shadow">
          <h3 className="mb-2 font-medium">Filter by Tailors</h3>
          <div className="max-h-48 overflow-y-auto">
            {/* No Tailor Option */}
            <label
              className={`flex items-center space-x-2 p-1 ${
                getTailorCount("no-tailor", orders) === 0 ? "opacity-50" : ""
              }`}
            >
              <span className="w-8 text-right text-gray-500">
                {getTailorCount("no-tailor", orders)}
              </span>
              <input
                type="checkbox"
                checked={selectedTailors.includes("no-tailor")}
                onChange={(e) =>
                  handleCheckboxChange("no-tailor", e.target.checked, setSelectedTailors)
                }
                className="ml-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                disabled={getTailorCount("no-tailor", orders) === 0}
              />
              <span>No Tailor Assigned</span>
            </label>
            {/* Tailor Options */}
            {tailors.map((tailor) => {
              const count = getTailorCount(tailor.id, orders);
              return (
                <label
                  key={tailor.id}
                  className={`flex items-center space-x-2 p-1 ${
                    count === 0 ? "opacity-50" : ""
                  }`}
                >
                  <span className="w-8 text-right text-gray-500">{count}</span>
                  <input
                    type="checkbox"
                    checked={selectedTailors.includes(tailor.id)}
                    onChange={(e) =>
                      handleCheckboxChange(
                        tailor.id,
                        e.target.checked,
                        setSelectedTailors
                      )
                    }
                    className="ml-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    disabled={count === 0}
                  />
                  <span>{tailor.name}</span>
                </label>
              );
            })}
          </div>
        </div>
      </div>

      {/* Update the orders list to use filteredOrders */}
      <ul>
        {filteredOrders.map((order) => (
          <li key={order.id} className="mb-4 rounded border p-4 shadow">
            {/* Add buttons at the top right */}
            <div className="mb-4 flex justify-end gap-2">
              <button
                onClick={() => window.open(`/a/${order.id.slice(0, 5)}`, "_blank")}
                className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
              >
                View Order
              </button>
              <button
                onClick={() => setOrderToDelete(order.id)}
                className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600"
              >
                Delete Order
              </button>
            </div>
            <div className="mb-2">
              <strong>{t("Order ID")}:</strong> {order.id}
            </div>
            <div className="mb-2">
              <strong>{t("Contact Name")}:</strong> {order.contactInfo.name}
            </div>
            <div className="mb-2">
              <strong>{t("Contact Phone")}:</strong> {order.contactInfo.phone}
            </div>
            <div className="mb-2">
              <strong>{t("Last Activity")}:</strong> {getLastActivityStatus(order)}
            </div>
            <div className="mb-2">
              <strong>{t("Delivery Method")}:</strong> {order.deliveryMethod}
            </div>
            <div className="mb-2">
              <strong>{t("Retrieve Method")}:</strong> {order.retrieveMethod}
            </div>
            <div className="mb-2">
              <strong>{t("Order Date")}:</strong> {order.date}
            </div>
            {!(order.paid == true) && (
              <strong className="text-red-500">Order Not Paid</strong>
            )}

            <div className="mt-4 flex items-center justify-between gap-4">
              <div className="flex-grow">
                <label htmlFor={`tailor-${order.id}`} className="mb-2 block font-medium">
                  Assign Tailor:
                </label>
                <select
                  id={`tailor-${order.id}`}
                  className="block w-full rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  value={order.delegatedTailor || ""}
                  onChange={(e) => handleTailorAssignment(order.id, e.target.value)}
                >
                  <option value="">Select a tailor</option>
                  {tailors.map((tailor) => (
                    <option key={tailor.id} value={tailor.id}>
                      {tailor.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Update the orders list with new tailor note generation */}
              <div className="flex flex-col gap-2">
                {order.orderInfo.length === 1 ? (
                  <button
                    onClick={() => generateAndDownloadTailorNote(order.id)}
                    className="rounded bg-indigo-500 px-4 py-2 font-medium text-white hover:bg-indigo-600"
                  >
                    Generate Tailor Note
                  </button>
                ) : (
                  order.orderInfo.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => generateAndDownloadTailorNote(order.id, index)}
                      className="rounded bg-indigo-500 px-4 py-2 font-medium text-white hover:bg-indigo-600"
                    >
                      Generate Note {index + 1}
                    </button>
                  ))
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>

      {/* Add confirmation modal */}
      {orderToDelete && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="rounded-lg bg-white p-6 shadow-xl">
            <h3 className="mb-4 text-lg font-bold">Confirm Deletion</h3>
            <p className="mb-6">
              Are you sure you want to delete order {orderToDelete.slice(0, 5)}? This
              action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setOrderToDelete(null)}
                className="rounded bg-gray-300 px-4 py-2 hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDelete(orderToDelete)}
                className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Add this before the closing div */}
      {pendingTailorChange && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="rounded-lg bg-white p-6 shadow-xl">
            <h3 className="mb-4 text-lg font-bold">Confirm Tailor Assignment</h3>
            <p className="mb-6">
              {pendingTailorChange.oldTailor
                ? `Update tailor from ${tailors.find((t) => t.id === pendingTailorChange.oldTailor)?.name || "Unknown"} to ${tailors.find((t) => t.id === pendingTailorChange.newTailor)?.name}`
                : `Set tailor to ${tailors.find((t) => t.id === pendingTailorChange.newTailor)?.name}`}
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setPendingTailorChange(null)}
                className="rounded bg-gray-300 px-4 py-2 hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={confirmTailorChange}
                className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
