import { useCallback, useState } from "react";

import { Dialog } from "@headlessui/react";
import { Autocomplete, GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { GeoPoint, doc, updateDoc } from "firebase/firestore";

import { db } from "../../firebase";

type MapOptions = google.maps.MapOptions;
type LatLngLiteral = google.maps.LatLngLiteral;

interface Retail {
  id: string;
  name: string;
  orgNumber: string;
  addresses: string[];
  contactPerson: {
    name: string;
    phone: string;
    email: string;
  };
  preVerifiedEmails: string[];
  moderatorEmails?: string[];
  location?: GeoPoint;
}

interface EditRetailModalProps {
  retail: Retail;
  onClose: () => void;
  onSave: (updatedRetail: Retail) => void;
}

const isContactPersonKey = (key: string): key is keyof Retail["contactPerson"] => {
  return ["name", "phone", "email"].includes(key);
};

const defaultCenter = {
  lat: 59.9139, // Oslo coordinates as default
  lng: 10.7522,
};

const mapContainerStyle = {
  width: "100%",
  height: "300px",
};

const libraries: "places"[] = ["places"];

export function EditRetailModal({ retail, onClose, onSave }: EditRetailModalProps) {
  const [formData, setFormData] = useState(retail);
  const [newEmail, setNewEmail] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState(
    retail.location
      ? { lat: retail.location.latitude, lng: retail.location.longitude }
      : defaultCenter
  );

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [searchBox, setSearchBox] = useState<google.maps.places.Autocomplete | null>(
    null
  );
  const [isSearching, setIsSearching] = useState(false);

  const mapOptions: MapOptions = {
    disableDefaultUI: false,
    clickableIcons: true,
    scrollwheel: true,
  };

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name.startsWith("contactPerson.")) {
      const key = name.split(".")[1];
      if (isContactPersonKey(key)) {
        setFormData({
          ...formData,
          contactPerson: { ...formData.contactPerson, [key]: value },
        });
      }
    } else if (name === "addresses") {
      setFormData({
        ...formData,
        addresses: value.split(",").map((address) => address.trim()),
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleAddEmail = (e: React.MouseEvent) => {
    e.preventDefault();
    const lowerCaseEmail = newEmail.toLowerCase();
    if (lowerCaseEmail && !formData.preVerifiedEmails.includes(lowerCaseEmail)) {
      setFormData({
        ...formData,
        preVerifiedEmails: [...formData.preVerifiedEmails, lowerCaseEmail],
      });
      setNewEmail("");
    }
  };

  const handleRemoveEmail = (emailToRemove: string) => {
    setFormData({
      ...formData,
      preVerifiedEmails: formData.preVerifiedEmails.filter(
        (email) => email !== emailToRemove
      ),
    });
  };

  const handleAddAddress = (e: React.MouseEvent) => {
    e.preventDefault();
    if (newAddress && !formData.addresses.includes(newAddress)) {
      setFormData({
        ...formData,
        addresses: [...formData.addresses, newAddress],
      });
      setNewAddress("");
    }
  };

  const handleRemoveAddress = (addressToRemove: string) => {
    setFormData({
      ...formData,
      addresses: formData.addresses.filter((address) => address !== addressToRemove),
    });
  };

  const handleMapClick = useCallback((event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const newPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setMarkerPosition(newPosition);
    }
  }, []);

  const handlePlaceSelect = () => {
    const place = searchBox?.getPlace();
    if (place?.geometry?.location) {
      const newPosition = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setMarkerPosition(newPosition);
      map?.panTo(newPosition);
      map?.setZoom(15);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const retailRef = doc(db, "retail", formData.id);

    // Create GeoPoint from marker position
    const location = new GeoPoint(markerPosition.lat, markerPosition.lng);

    const updateData = {
      ...formData,
      location,
    };

    await updateDoc(retailRef, updateData);
    onSave({ ...formData, location });
  };

  return (
    <Dialog open={true} onClose={onClose} className="relative z-10">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <Dialog.Panel className="relative w-full max-w-2xl transform overflow-hidden bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
            <div className="">
              <Dialog.Title
                as="h3"
                className="text-lg font-semibold leading-6 text-gray-900"
              >
                Edit Retailer
              </Dialog.Title>
              <form className="mt-2 space-y-4" onSubmit={handleSubmit}>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Org Number
                  </label>
                  <input
                    type="text"
                    name="orgNumber"
                    value={formData.orgNumber}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Addresses
                  </label>
                  <div className="flex gap-2">
                    <input
                      type="text"
                      value={newAddress}
                      onChange={(e) => setNewAddress(e.target.value)}
                      className="flex-1 rounded border border-gray-300 px-2 py-1 text-sm"
                      placeholder="Add address"
                    />
                    <button
                      type="button"
                      onClick={handleAddAddress}
                      className="rounded bg-blue-500 px-3 py-1 text-sm text-white hover:bg-blue-600"
                    >
                      Add
                    </button>
                  </div>
                  <div className="mt-2 space-y-2">
                    {formData.addresses.map((address, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between rounded bg-gray-50 px-3 py-2"
                      >
                        <span className="text-sm">{address}</span>
                        <button
                          type="button"
                          onClick={() => handleRemoveAddress(address)}
                          className="ml-2 text-sm text-red-500 hover:text-red-700"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Contact Person Name
                  </label>
                  <input
                    type="text"
                    name="contactPerson.name"
                    value={formData.contactPerson.name}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Contact Person Phone
                  </label>
                  <input
                    type="text"
                    name="contactPerson.phone"
                    value={formData.contactPerson.phone}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-1">
                  <label className="block text-sm font-medium text-gray-700">
                    Contact Person Email
                  </label>
                  <input
                    type="email"
                    name="contactPerson.email"
                    value={formData.contactPerson.email}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Personnel
                  </label>
                  <div className="flex gap-2">
                    <input
                      type="email"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value.toLowerCase().trim())}
                      className="flex-1 rounded border border-gray-300 px-2 py-1 text-sm"
                      placeholder="Add email address"
                    />
                    <button
                      type="button"
                      onClick={handleAddEmail}
                      className="rounded bg-blue-500 px-3 py-1 text-sm text-white hover:bg-blue-600"
                    >
                      Add
                    </button>
                  </div>
                  <div className="mt-2 space-y-2">
                    {formData.preVerifiedEmails.map((email, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between rounded bg-gray-50 px-3 py-2"
                      >
                        <span className="text-sm">{email}</span>
                        <button
                          type="button"
                          onClick={() => handleRemoveEmail(email)}
                          className="ml-2 text-sm text-red-500 hover:text-red-700"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mb-4">
                  <h3 className="mb-2 text-lg font-semibold">Location</h3>
                  <div className="rounded-lg border border-gray-300 p-2">
                    <LoadScript
                      googleMapsApiKey={"AIzaSyAO7CKqdqzsY9SHQY1ZXlmfsPSra_s3M-k"}
                      libraries={libraries}
                    >
                      <div className="mb-2">
                        <Autocomplete
                          onLoad={(autocomplete) => setSearchBox(autocomplete)}
                          onPlaceChanged={handlePlaceSelect}
                          options={{
                            types: ["address"],
                            componentRestrictions: { country: "no" }, // Restrict to Norway
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Search for an address"
                            className="w-full rounded border border-gray-300 p-2 text-sm"
                            onFocus={() => setIsSearching(true)}
                            onBlur={() => setIsSearching(false)}
                          />
                        </Autocomplete>
                      </div>
                      <div className={`relative ${isSearching ? "z-50" : ""}`}>
                        <GoogleMap
                          mapContainerStyle={mapContainerStyle}
                          center={markerPosition}
                          zoom={13}
                          onLoad={onLoad}
                          onUnmount={onUnmount}
                          onClick={handleMapClick}
                          options={mapOptions}
                        >
                          {markerPosition && <Marker position={markerPosition} />}
                        </GoogleMap>
                      </div>
                      <div className="mt-2 text-sm text-gray-500">
                        Search for an address or click on the map to set the location.
                        <br />
                        Current position: Lat: {markerPosition.lat.toFixed(6)}, Lng:{" "}
                        {markerPosition.lng.toFixed(6)}
                      </div>
                    </LoadScript>
                  </div>
                </div>
                <div className="mt-5 flex justify-between space-x-4 sm:mt-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded bg-[#8CD7FF] px-3 py-2 text-sm font-semibold shadow-sm hover:bg-[#7bc5ee]"
                  >
                    Save Changes
                  </button>
                  <button
                    type="button"
                    onClick={onClose}
                    className="inline-flex w-full justify-center rounded border border-gray-300 bg-white px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
