import React, { useEffect, useState } from "react";

import { collection, doc, getDocs, query } from "firebase/firestore";
import { t } from "i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

import { getCurrentUserEmail } from "../auth";
import { db } from "../firebase";
import { Order } from "../pages/OrderPage";

interface OrderSummary extends Order {
  pageName: string | null;
  retailName?: string;
}

interface Retail {
  id: string;
  name: string;
  contactPerson: {
    email: string;
  };
  moderatorEmails?: string[];
}

interface RetailOption {
  value: string;
  label: string;
}

const RetailOrders: React.FC = () => {
  const [userRetails, setUserRetails] = useState<Retail[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date("2023-10-15"));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [orders, setOrders] = useState<OrderSummary[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRetails, setSelectedRetails] = useState<string[]>([]);

  const getPageName = (url: string | undefined) => {
    if (!url) return null;
    try {
      const urlWithoutHash = url.split("#")[0];
      const urlWithoutQuery = urlWithoutHash.split("?")[0];
      const segments = urlWithoutQuery.split("/").filter(Boolean);
      const lastSegment = segments[segments.length - 1];
      return lastSegment || null;
    } catch (error) {
      console.error("Error parsing URL:", error);
      return null;
    }
  };

  const fetchUserRetails = async () => {
    const userEmail = getCurrentUserEmail();
    if (!userEmail) return;

    const retailRef = collection(db, "retail");
    const retailSnapshot = await getDocs(retailRef);

    const userRetails = retailSnapshot.docs
      .map(
        (doc) =>
          ({
            id: doc.id,
            ...doc.data(),
          }) as Retail
      )
      .filter(
        (retail) =>
          retail.contactPerson?.email === userEmail ||
          retail.moderatorEmails?.includes(userEmail)
      );

    setUserRetails(userRetails);
    return userRetails;
  };

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const userRetails = await fetchUserRetails();
      if (!userRetails?.length) {
        setOrders([]);
        return;
      }

      const retailIds = userRetails.map((retail) => retail.id);
      const ordersRef = collection(db, "b2cOrders");
      const q = query(ordersRef);
      const querySnapshot = await getDocs(q);

      const fetchedOrders = querySnapshot.docs
        .map((doc) => {
          const data = doc.data() as Order;
          // Only include orders that have a matching retailId
          if (data.retailId && retailIds.includes(data.retailId)) {
            const retail = userRetails.find((r) => r.id === data.retailId);
            return {
              ...data,
              id: doc.id,
              pageName: getPageName(data.orderCreationUrl),
              retailName: retail?.name,
            };
          }
          return null;
        })
        .filter((order): order is NonNullable<typeof order> => order !== null);

      setOrders(fetchedOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const filteredOrders = orders
    .filter((order) => {
      const orderDate = new Date(order.date);
      const dateFilter = orderDate >= startDate && orderDate <= endDate;

      const searchFilter =
        searchQuery === "" ||
        order.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        order.contactInfo?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        order.contactInfo?.phone?.toLowerCase().includes(searchQuery.toLowerCase());

      const retailFilter =
        selectedRetails.length === 0 || selectedRetails.includes(order.retailId || "");

      return dateFilter && searchFilter && retailFilter;
    })
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const retailOptions: RetailOption[] = userRetails.map((retail) => ({
    value: retail.id,
    label: retail.name,
  }));

  const calculateStats = (orders: OrderSummary[]) => {
    const totalOrders = orders.length;
    const totalPrice = orders.reduce(
      (sum, order) => sum + parseFloat(order.totalPrice || "0"),
      0
    );
    const averagePrice = totalOrders > 0 ? totalPrice / totalOrders : 0;

    return {
      totalOrders,
      totalPrice: totalPrice.toFixed(2),
      averagePrice: averagePrice.toFixed(2),
    };
  };

  return (
    <div className="min-h-screen p-4">
      <div className="mb-8 grid grid-cols-1 gap-4 sm:grid-cols-3">
        <div className="rounded-lg border border-black p-4">
          <h3 className="text-sm font-medium text-gray-500">Total Orders</h3>
          <p className="mt-1 text-3xl font-semibold">
            {calculateStats(filteredOrders).totalOrders}
          </p>
        </div>
        <div className="rounded-lg border border-black p-4">
          <h3 className="text-sm font-medium text-gray-500">Total Sales</h3>
          <p className="mt-1 text-3xl font-semibold">
            {calculateStats(filteredOrders).totalPrice} NOK
          </p>
        </div>
        <div className="rounded-lg border border-black p-4">
          <h3 className="text-sm font-medium text-gray-500">Average Order Value</h3>
          <p className="mt-1 text-3xl font-semibold">
            {calculateStats(filteredOrders).averagePrice} NOK
          </p>
        </div>
      </div>

      <div className="mb-8 space-y-4 border-b border-black pb-4">
        <div className="mb-4 w-full">
          <input
            type="text"
            placeholder="Search by customer name, phone, or order ID..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full rounded border border-black p-2 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
        </div>

        <div className="flex flex-wrap gap-4">
          <div className="w-full md:w-auto">
            <label className="block text-sm font-medium text-gray-700">From Date</label>
            <DatePicker
              selected={startDate}
              onChange={(date: Date | null) => date && setStartDate(date)}
              className="w-full rounded border border-black p-2"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="w-full md:w-auto">
            <label className="block text-sm font-medium text-gray-700">To Date</label>
            <DatePicker
              selected={endDate}
              onChange={(date: Date | null) => date && setEndDate(date)}
              className="w-full rounded border border-black p-2"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          {userRetails.length > 1 && (
            <div className="w-full md:w-64">
              <label className="block text-sm font-medium text-gray-700">
                Filter by Retail
              </label>
              <Select
                isMulti
                options={retailOptions}
                onChange={(selected) =>
                  setSelectedRetails(selected.map((option) => option.value))
                }
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </div>
          )}
        </div>
      </div>

      {loading ? (
        <div className="text-center">Loading...</div>
      ) : (
        <div className="space-y-4">
          {filteredOrders.map((order) => (
            <div key={order.id} className="border-b border-black p-4">
              <div className="flex flex-wrap justify-between gap-4">
                <div>
                  <h3 className="text-lg font-medium">
                    Order ID:{" "}
                    <a
                      href={`/orders/${order.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 hover:underline"
                    >
                      {order.id.slice(0, 5)}
                    </a>
                  </h3>
                  <p>Customer: {order.contactInfo?.name || "N/A"}</p>
                  <p>Phone: {order.contactInfo?.phone}</p>
                  <p>
                    Date:{" "}
                    {new Date(order.date).toLocaleString("en-GB", {
                      hour: "2-digit",
                      minute: "2-digit",
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </p>
                  <p>Total Price: {order.totalPrice} NOK</p>
                  {order.retailName && <p>Retail: {order.retailName}</p>}
                </div>
                <div>
                  <p>
                    Status:{" "}
                    {order.activity && order.activity.length > 0
                      ? t(
                          `workwearOrderPage.${order.activity[order.activity.length - 1].content}`
                        )
                      : "No status"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RetailOrders;
