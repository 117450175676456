import axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";

import { db } from "../firebase";
import { Order } from "../pages/OrderPage";

async function generateQRCodePDF(orderId: string, order: Order, itemIndex: number) {
  const pdfWidth = 48 * 2.8346; // 48mm to points
  const pdfHeight = 100 * 2.8346; // 100mm to points
  const margin = 5; // Reduced from 10 to 5
  const effectiveWidth = pdfWidth - 2 * margin;

  try {
    const pdfDoc = await PDFDocument.create();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    let page = pdfDoc.addPage([pdfWidth, pdfHeight]);
    let yOffset = pdfHeight;

    // Generate QR Code URL for this specific item
    const qrCodeUrl = `https://fikse.co/orders/b2c/${orderId}#${itemIndex + 1}`;

    // Add QR Code
    const qrResponse = await axios.get(
      `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(qrCodeUrl)}&size=500x500`,
      { responseType: "arraybuffer" }
    );
    const qrImage = await pdfDoc.embedPng(qrResponse.data);
    const qrDimension = (pdfWidth - margin * 2) * 0.7; // 70% of original size

    page.drawImage(qrImage, {
      x: margin,
      y: yOffset - qrDimension - margin,
      width: qrDimension,
      height: qrDimension,
    });
    yOffset -= qrDimension + margin + 15;

    // Helper function to draw horizontal line with padding
    const drawLine = (y: number) => {
      const heightY = y + 5;
      page.drawLine({
        start: { x: margin, y: heightY },
        end: { x: pdfWidth - margin, y: heightY },
        thickness: 1,
        color: rgb(0, 0, 0),
      });
      return y - 20;
    };

    // Helper function to draw text with auto-scaling
    const drawScaledText = (
      text: string,
      y: number,
      maxWidth: number,
      baseFontSize = 15
    ) => {
      let fontSize = baseFontSize;
      let textWidth = font.widthOfTextAtSize(text, fontSize);

      while (textWidth > maxWidth && fontSize > 6) {
        fontSize--;
        textWidth = font.widthOfTextAtSize(text, fontSize);
      }

      page.drawText(text, {
        x: margin,
        y,
        size: fontSize,
        font,
        color: rgb(0, 0, 0),
      });
      return y - (fontSize + 5);
    };

    // Helper function to draw customer text with controlled height
    const drawCustomerText = (
      text: string,
      y: number,
      maxWidth: number,
      maxHeight = 40
    ) => {
      const maxFontSize = 15;
      let fontSize = maxFontSize;

      const approxCharsPerLine = Math.floor(maxWidth / (fontSize * 0.5));
      const estimatedLines = Math.ceil(text.length / approxCharsPerLine);

      if (estimatedLines > 1) {
        fontSize = Math.max(6, fontSize - estimatedLines * 2);
      }

      const lines: string[] = [];
      let words = text.split(" ");
      let currentLine = "";

      words.forEach((word) => {
        const testLine = currentLine ? `${currentLine} ${word}` : word;
        if (font.widthOfTextAtSize(testLine, fontSize) <= maxWidth) {
          currentLine = testLine;
        } else {
          if (currentLine) lines.push(currentLine);
          currentLine = word;
        }
      });
      if (currentLine) lines.push(currentLine);

      const lineHeight = fontSize + 2;
      lines.forEach((line, index) => {
        page.drawText(line, {
          x: margin,
          y: y - lineHeight * index,
          size: fontSize,
          font,
          color: rgb(0, 0, 0),
        });
      });

      return y - Math.max(maxHeight, lineHeight * lines.length);
    };

    // Draw item details
    const item = order.orderInfo[itemIndex];
    yOffset = drawScaledText(`${orderId.slice(0, 5)}`, yOffset, effectiveWidth);
    yOffset = drawScaledText(
      `${item.item} med ${item.work || item.problem}`,
      yOffset,
      effectiveWidth
    );
    yOffset = drawCustomerText(item.customerComment || "", yOffset, effectiveWidth);

    yOffset = drawLine(yOffset);

    // Draw contact info and delivery method
    yOffset = drawScaledText(
      `${order.brand ? order.brand : order.contactInfo.name}`,
      yOffset,
      effectiveWidth
    );

    // Format delivery/retrieve method text
    const methodText =
      order.retrieveMethod === order.deliveryMethod
        ? order.retrieveMethod
        : `${order.retrieveMethod} > ${order.deliveryMethod}`; // Changed "→" to ">"

    yOffset = drawScaledText(methodText, yOffset, effectiveWidth);

    yOffset = drawLine(yOffset);

    // Calculate completion date
    const lastActivity = order.activity[order.activity.length - 1];
    const activityDate = new Date(lastActivity.dateTime);
    const fixDate = new Date(activityDate);

    // Add 5 working days
    let workingDays = 0;
    while (workingDays < 5) {
      fixDate.setDate(fixDate.getDate() + 1);
      if (fixDate.getDay() !== 0) {
        // Skip Sundays
        workingDays++;
      }
    }

    const formattedDate = fixDate.toLocaleDateString("no-NO");
    yOffset = drawScaledText(`Fikse før ${formattedDate}`, yOffset, effectiveWidth);

    return await pdfDoc.save();
  } catch (error) {
    console.error("Error generating PDF:", error);
    throw error;
  }
}

export async function generateAndDownloadTailorNote(
  orderId: string,
  itemIndex: number = 0
) {
  try {
    // Fetch order data from Firestore
    const orderRef = doc(db, "b2cOrders", orderId);
    const orderSnap = await getDoc(orderRef);

    if (!orderSnap.exists()) {
      throw new Error(`Order ${orderId} not found`);
    }

    const order = { id: orderId, ...orderSnap.data() } as Order;
    const pdfBytes = await generateQRCodePDF(orderId, order, itemIndex);

    // Create blob and download link
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `skredderlapp-${orderId.slice(0, 5)}${order.orderInfo.length > 1 ? `-${itemIndex + 1}` : ""}.pdf`;

    // Trigger download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error generating and downloading tailor note:", error);
    throw error;
  }
}
