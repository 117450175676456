// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, OAuthProvider, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Legg til denne linjen

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const prodConfig = {
  apiKey: "AIzaSyDLtQUa-vpahe_L9wihla09OfTFmoSo94w",
  authDomain: "fiksefirstapp.firebaseapp.com",
  projectId: "fiksefirstapp",
  storageBucket: "fiksefirstapp.appspot.com",
  messagingSenderId: "1036858581379",
  appId: "1:1036858581379:web:61cda6ab68f35d32b016b6",
  measurementId: "G-KYK57EB4GQ",
};

const stagingConfig = {
  apiKey: "AIzaSyDMcdxYHIg0_6rZR0MMYqRr5COXs9utvQo",
  authDomain: "bravo-fikse-staging.firebaseapp.com",
  projectId: "bravo-fikse-staging",
  storageBucket: "bravo-fikse-staging.firebasestorage.app",
  messagingSenderId: "310554292892",
  appId: "1:310554292892:web:66e8caacdb00bd6c613359",
};

const cyberConfig = {
  apiKey: "AIzaSyDXgD7DzoHYVOjrWMC5-jhTRaxw_JT5Smc",
  authDomain: "cyber-fikse-sandbox.firebaseapp.com",
  projectId: "cyber-fikse-sandbox",
  storageBucket: "cyber-fikse-sandbox.firebasestorage.app",
  messagingSenderId: "1025099652114",
  appId: "1:1025099652114:web:c770e03cca21f10995cccb",
  measurementId: "G-735TQXK6YM",
};

const isStaging =
  window.location.hostname === "bravo-fikse-staging.web.app" ||
  window.location.hostname === "bravo-fikse-staging.firebaseapp.com" ||
  window.location.hostname === "dev.oslo.kommune.fikse.co" ||
  window.location.hostname.includes("localhost");

const isCyber =
  window.location.hostname === "cyber-fikse-sandbox.web.app" ||
  window.location.hostname === "cyber-fikse-sandbox.firebaseapp.com";

// Initialize Firebase
const config = isCyber ? cyberConfig : isStaging ? stagingConfig : prodConfig;
const app = initializeApp(config);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Legg til denne linjen

// Connect to Firestore emulator
/*
if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "dev.oslo.kommune.fikse.co"
) {
  connectFirestoreEmulator(db, "127.0.0.1", 8081); // Note the port is 8081 from your emulator output
}*/

export const googleProvider = new GoogleAuthProvider();
export const microsoftProvider = new OAuthProvider("microsoft.com");

export { db, app, auth, storage, config };
