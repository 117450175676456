import React, { Fragment, useEffect, useState } from "react";

import { collection, doc, getDocs, query, updateDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";

import { getCurrentUserEmail } from "../auth";
import ModalTile from "../components/ModalTile";
import { db } from "../firebase";
import { ActivityItem, Order, orderStates } from "./OrderPage";

const defaultImageUrl: string =
  "https://us.123rf.com/450wm/koblizeek/koblizeek2208/koblizeek220800254/190563481-no-image-vector-symbol-missing-available-icon-no-gallery-for-this-moment-placeholder.jpg";
const statuses = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Overdue: "text-red-700 bg-red-50 ring-red-600/10",
};

const completedStates = ["orderStateDelivered", "orderStateCanceled"];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function AhlsellPage() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [showDetails, setShowDetails] = useState<{ [key: string]: boolean }>({});
  const [activeTab, setActiveTab] = useState<"ongoing" | "completed">("ongoing");
  const userEmail = localStorage.getItem("uid");
  const { t } = useTranslation();
  const [confirmationModal, setConfirmationModal] = useState<{
    isOpen: boolean;
    orderId: string;
    newStatus: string;
  }>({ isOpen: false, orderId: "", newStatus: "" });

  useEffect(() => {
    const fetchOrders = async () => {
      if (!userEmail) return;

      const q = query(collection(db, "workwearOrders"));
      const querySnapshot = await getDocs(q);
      let userOrders: Order[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Order[];

      // Filter out orders where test == true
      userOrders = userOrders.filter(
        (order) =>
          !order.test &&
          (order.email.endsWith("oslo.kommune.no") ||
            order.email.endsWith("osloskolen.no") ||
            order.email.endsWith("fikse.co") ||
            order.email.endsWith("ntnu.no"))
      );

      // Sort orders by date in descending order
      userOrders.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

      // Sort orders by date in descending order
      setOrders(userOrders);
    };

    fetchOrders();
  }, [userEmail]);

  const toggleDetails = (orderId: string) => {
    setShowDetails((prevDetails) => ({
      ...prevDetails,
      [orderId]: !prevDetails[orderId],
    }));
  };

  const confirmOrder = (orderId: string) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === orderId ? { ...order, status: "confirmed" } : order
      )
    );
  };

  // Update the handleStatusUpdate to first show confirmation
  const handleStatusChange = (orderId: string, newStatus: string) => {
    setConfirmationModal({
      isOpen: true,
      orderId,
      newStatus,
    });
  };

  // Actual status update function
  const confirmStatusUpdate = async () => {
    const { orderId, newStatus } = confirmationModal;
    try {
      const orderRef = doc(db, "workwearOrders", orderId);
      const orderToUpdate = orders.find((order) => order.id === orderId);
      const userEmail = getCurrentUserEmail();

      if (!orderToUpdate) return;

      const updatedActivity = [
        ...orderToUpdate.activity,
        {
          id: orderToUpdate.activity.length + 1,
          content: newStatus,
          date: new Date().toLocaleDateString(),
          dateTime: new Date().toISOString(),
          iconBackground: "bg-blue-500",
          handler: userEmail, // Add handler field
        },
      ];

      await updateDoc(orderRef, {
        currentStatus: newStatus,
        activity: updatedActivity,
      });

      // Update local state
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId ? { ...order, activity: updatedActivity } : order
        )
      );
    } catch (error) {
      console.error("Error updating order status:", error);
      alert("Failed to update order status");
    } finally {
      setConfirmationModal({ isOpen: false, orderId: "", newStatus: "" });
    }
  };

  const filterOrders = (orders: Order[]) => {
    return orders.filter((order) => {
      const lastActivity = order.activity[order.activity.length - 1].content;
      if (activeTab === "ongoing") {
        return !completedStates.includes(lastActivity);
      } else {
        return completedStates.includes(lastActivity);
      }
    });
  };

  return (
    <div className="mx-8 my-8">
      <header className="mb-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900">
          {t("ahlsellPage.loggedInAs")}
        </h1>
        <p className="text-lg text-gray-600">{t("ahlsellPage.overview")}</p>
      </header>

      {/* Tabs */}
      <div className="mb-6 border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          <button
            onClick={() => setActiveTab("ongoing")}
            className={`${
              activeTab === "ongoing"
                ? "border-indigo-500 text-indigo-600"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
            } whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium`}
          >
            Pågående reparasjoner
          </button>
          <button
            onClick={() => setActiveTab("completed")}
            className={`${
              activeTab === "completed"
                ? "border-indigo-500 text-indigo-600"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
            } whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium`}
          >
            Tidligere ordere
          </button>
        </nav>
      </div>

      {/* Add confirmation modal */}
      <ModalTile
        open={confirmationModal.isOpen}
        onClose={() =>
          setConfirmationModal({ isOpen: false, orderId: "", newStatus: "" })
        }
        title={t("ahlsellPage.updateStatus")}
      >
        <div className="space-y-6">
          <p className="p-8 text-base">
            {t("ahlsellPage.confirmStatusUpdate", {
              status: t(`workwearOrderPage.${confirmationModal.newStatus}`),
            })}
          </p>
          <div className="flex w-full border-t border-black">
            <button
              type="button"
              className="flex-1 border-r border-black bg-fikseBeige p-4 text-base hover:bg-fikseBeigeHover"
              onClick={() =>
                setConfirmationModal({ isOpen: false, orderId: "", newStatus: "" })
              }
            >
              {t("cancel")}
            </button>
            <button
              type="button"
              className="flex-1 bg-fikseBlue p-4 text-base hover:bg-fikseBeigeHover"
              onClick={confirmStatusUpdate}
            >
              {t("confirm")}
            </button>
          </div>
        </div>
      </ModalTile>

      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {filterOrders(orders).map((order) => (
          <li
            key={order.id}
            className="flex flex-col justify-between overflow-hidden border border-gray-200 hover:shadow"
          >
            <div>
              <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                <div className="truncate text-lg font-medium leading-6 text-gray-900">
                  {t("ahlsellPage.orderId")}: {order.id.slice(0, 5)}
                </div>
                <a
                  href={`/orders/workwear/${order.id}`}
                  className="hover:bg-fikseBlueHover ml-auto rounded-md bg-fikseBlue px-3 py-2 text-sm font-semibold text-white shadow-sm"
                >
                  {t("ahlsellPage.orderPage")}
                </a>
              </div>
              <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-500">{t("ahlsellPage.date")}</dt>
                  <dd className="text-gray-700">
                    <time dateTime={new Date(order.date).toISOString()}>
                      {new Date(order.date).toLocaleDateString()}
                    </time>
                  </dd>
                </div>
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-500">{t("ahlsellPage.estimatedPrice")}</dt>
                  <dd className="flex items-start gap-x-2">
                    <div className="font-medium text-gray-900">{order.totalPrice}</div>
                  </dd>
                </div>

                <div className="flex justify-between gap-x-4 py-3">
                  <dd className="truncate text-gray-700">{order.contactInfo.name}</dd>
                </div>
                <div className="flex justify-between gap-x-4 py-3">
                  <dd className="truncate text-gray-700">{order.email}</dd>
                </div>
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="text-gray-500">{t("ahlsellPage.phoneNumber")}</dt>
                  <dd className="text-gray-700">{order.contactInfo.phone}</dd>
                </div>
                <div className="flex flex-wrap justify-between gap-x-4 py-3">
                  <dt className="w-full text-gray-500 sm:w-auto">
                    {t("ahlsellPage.returnAddress")}
                  </dt>
                  <dd className="w-full whitespace-pre-wrap break-words text-gray-700 sm:w-auto sm:text-right">
                    {order.deliveryAddress?.trim()}
                  </dd>
                </div>
                <div className="flex flex-wrap justify-between gap-x-4 py-3">
                  <dt className="w-full text-gray-500 sm:w-auto">Status</dt>
                  <dd className="w-full sm:w-auto sm:text-right">
                    <select
                      value={order.activity[order.activity.length - 1].content}
                      onChange={(e) => handleStatusChange(order.id, e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      {orderStates.map((status) => (
                        <option key={status} value={status}>
                          {t(`workwearOrderPage.${status}`)}
                        </option>
                      ))}
                    </select>
                  </dd>
                </div>
                <div className="flex flex-wrap justify-between gap-x-4 py-3">
                  <dt className="w-full text-gray-500 sm:w-auto">
                    {t("workwearOrderPage.department")}
                  </dt>
                  <dd className="w-full break-words text-gray-700 sm:w-auto sm:text-right">
                    {order.deliveryDepartment}
                  </dd>
                </div>
              </dl>
            </div>
            <div>
              <button
                className="mt-4 w-full bg-gray-200 py-2 text-gray-900 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                onClick={() => toggleDetails(order.id)}
              >
                {showDetails[order.id]
                  ? t("ahlsellPage.hideItems")
                  : t("ahlsellPage.showItems")}
              </button>
              {showDetails[order.id] && (
                <div className="m-4 space-y-4">
                  {order.orderInfo.map((item, index) => (
                    <div key={index} className="py-3">
                      <dt className="whitespace-normal break-words font-medium text-gray-500">
                        {index + 1}: {item.item}
                      </dt>
                      {/*prettier-ignore*/}
                      <dd className="whitespace-normal break-words text-gray-700">
                        {t("ahlsellPage.description")}:   {item.description}, {item.fix_type}
                      </dd>
                      {/*prettier-ignore*/}
                      <dd className="whitespace-normal break-words text-gray-700">
                        {t("ahlsellPage.problem")}: {item.problem}, {item.work}
                      </dd>
                      <dd className="whitespace-normal break-words text-gray-700">
                        {t("ahlsellPage.customerComment")}: {item.customerComment}
                      </dd>
                      <dd className="whitespace-normal break-words text-gray-700">
                        {t("ahlsellPage.price_nok")}: {item.price_nok} NOK
                      </dd>

                      {item.imageUrl && item.imageUrl !== defaultImageUrl ? (
                        <img
                          src={item.imageUrl || "https://via.placeholder.com/48"}
                          className="mt-2 h-28 w-28 flex-none bg-white object-cover ring-1 ring-gray-900/10"
                          alt={item.work}
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
