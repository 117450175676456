// HorizontalGauge.tsx
import React from "react";

interface HorizontalGaugeProps {
  value: number;
  total: number;
  name: string;
  label?: string; // Optional right-side label (like "Repair" in the example)
}

const HorizontalGauge: React.FC<HorizontalGaugeProps> = ({
  value,
  total,
  name,
  label,
}) => {
  // Calculate percentage
  const percentage: number = Math.min(Math.round((value / total) * 100), 100);

  return (
    <div className="flex flex-col gap-2">
      {/* Name */}
      <div className="text-lg text-gray-700">{name}</div>

      {/* Gauge and percentage container */}
      <div className="flex items-center gap-4">
        {/* Gauge bar */}
        <div className="relative h-8 w-64 overflow-hidden rounded-full">
          {/* Background */}
          <div className="absolute inset-0 bg-[#8CD7FF]" />

          {/* Filled portion */}
          <div
            className="absolute inset-y-0 left-0 rounded-r-full bg-[#D3FF8C]"
            style={{ width: `${percentage}%` }}
          />
        </div>

        {/* Percentage and label */}
        <div className="flex items-center gap-2">
          <span className="text-lg font-semibold">{percentage}%</span>
          {label && <span className="text-lg text-gray-600">{label}</span>}
        </div>
      </div>
    </div>
  );
};

// Example usage
const ExampleUsage: React.FC = () => {
  return (
    <div className="p-8">
      <HorizontalGauge value={76} total={100} name="Paleet" label="Repair" />
    </div>
  );
};

export default HorizontalGauge;
