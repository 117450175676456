import React, { useEffect, useState } from "react";

import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import QRCode from "qrcode";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Add this import
import { app, db, storage } from "../../firebase";
import { ReactComponent as FikseCatLady } from "../../images/Illustration/FikseFigures/footer-woman-cat.svg";
import { ReactComponent as CheckIcon } from "../../images/icons-fikse/checkmark.svg";
import { ReactComponent as ChevronDownIcon } from "../../images/icons-fikse/dropdown.svg";
import { getFormalName, sendSMS } from "../contact";

// Oppdatert `OrderInfo`-type basert på `RecognitionResult`
interface OrderInfo {
  id: string;
  item: string;
  problem: string;
  fix_type: string;
  description: string;
  price_nok: number;
  work: string;
  placeholder: string;
  imageUrl: string;
  customerComment: string;
}

interface ContactInfo {
  department: string;
  name: string;
  phone: string;
  address: string;
}

interface ApiResponse {
  firstName: string;
  middleName?: string;
  lastName: string;
  address: string;
  postalCode: string;
  city: string;
}

interface CheckoutB2CProps {
  garments: OrderInfo[];
  contactInfo: ContactInfo;
  onClose: () => void;
  onBackToCart: () => void;
  emailPassedTrough?: string;
  customDropOff?: string[];
  attributes?: string[];
}

const CheckoutB2C: React.FC<CheckoutB2CProps> = ({
  garments,
  contactInfo,
  onClose,
  onBackToCart,
  emailPassedTrough,
  customDropOff,
  attributes,
}) => {
  // Add new state for retail connections
  const [retailConnections, setRetailConnections] = useState<string[]>([]);
  const [selectedRetailId, setSelectedRetailId] = useState<string>("");
  const [selectedRetailName, setSelectedRetailName] = useState<string>("");

  // Add new state for retail names mapping
  const [retailNames, setRetailNames] = useState<{ [key: string]: string }>({});

  const [phoneNumber, setPhoneNumber] = useState(contactInfo.phone || "");
  const [deliveryMethod, setDeliveryMethod] = useState("DEFAULT");
  const [retrieveMethod, setRetrieveMethod] = useState("DEFAULT");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [receiveMarketing, setReceiveMarketing] = useState(false);
  const [marketingEmail, setMarketingEmail] = useState(localStorage.getItem("uid") || "");
  const [isFormValid, setIsFormValid] = useState(false);
  const [lookupData, setLookupData] = useState<ApiResponse | null>(null);
  const [lookupStatus, setLookupStatus] = useState("");

  //@andreas-dity hva er hensikt med å defoulte til contactInfo.name? :)
  //Slettes hvis ingen respons er gitt innen 1. Februar 2024
  //const [fullName, setFullName] = useState(contactInfo.name || "");
  const [fullName, setFullName] = useState("");

  const [address, setAddress] = useState(contactInfo.address || "");
  const [uploading, setUploading] = useState(false);
  const [claimStatus, setClaimStatus] = useState(false);
  const [discount, setDiscount] = useState<any>(null);
  const [discountCode, setDiscountCode] = useState("");
  const [discountStatus, setDiscountStatus] = useState<string | null>(null);
  const [claimNote, setClaimNote] = useState("");

  const [dropoffLocations, setDropoffLocations] = useState<string[]>([]);
  const [deliveryLocations, setDeliveryLocations] = useState<string[]>([]);
  const { t } = useTranslation();

  const navigate = useNavigate();

  console.log("customPipeline", customDropOff, attributes, emailPassedTrough);

  const checkDiscountValidity = async () => {
    try {
      const discountQuery = query(
        collection(db, "discount"),
        where("name", "==", discountCode)
      );
      const querySnapshot = await getDocs(discountQuery);

      if (querySnapshot.empty) {
        setDiscountStatus("Code does not exist.");
        return;
      }

      const discount = querySnapshot.docs[0].data();
      const now = new Date();

      const isTimedValid =
        discount.timed &&
        discount.validFrom &&
        discount.validTo &&
        now >= new Date(discount.validFrom.seconds * 1000) &&
        now <= new Date(discount.validTo.seconds * 1000);

      const hasRemaining = discount.count > 0;

      if (hasRemaining && (!discount.timed || isTimedValid)) {
        setDiscountStatus("Discount applied.");
        setDiscount(discount);
      } else {
        setDiscountStatus("Code is expired or out of uses.");
      }
    } catch (error) {
      console.error("Error checking discount code:", error);
      setDiscountStatus("An error occurred while checking the code.");
    }
  };

  useEffect(() => {
    const acceptTermStatus = Boolean(acceptTerms || attributes?.includes("Brands"));

    const phoneNumberStatus = Boolean(
      phoneNumber.length === 8 ||
        phoneNumber.includes("+") ||
        attributes?.includes("Brands")
    );

    const nameStatus = Boolean(fullName !== "" || attributes?.includes("Brands"));

    const adressStatus = Boolean(address !== "" || attributes?.includes("Brands"));
    setIsFormValid(
      phoneNumberStatus &&
        nameStatus &&
        adressStatus &&
        acceptTermStatus &&
        garments.length > 0
    );
  }, [phoneNumber, acceptTerms, fullName, garments, address]);

  useEffect(() => {
    if (phoneNumber.length === 8) {
      handleLookupClick();
    } else {
      setLookupData(null);
    }
  }, [phoneNumber]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const docRef = doc(collection(db, "dynamicPages"), "locations");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const currentUrl = window.location.href;

          if (customDropOff) {
            setDropoffLocations(customDropOff);
            setDeliveryLocations(customDropOff);
          } else {
            console.log("Setting regular locations:", data.locationsList);
            setDropoffLocations(data.locationsList);
            setDeliveryLocations(data.locationsList);
          }
        } else {
          console.error("No such document in Firestore!");
        }
      } catch (error) {
        console.error("Error fetching locations: ", error);
      }
    };
    fetchLocations();
  }, []);

  // Second useEffect to handle setting initial delivery/retrieve methods
  // This will run whenever dropoffLocations or deliveryLocations change
  useEffect(() => {
    setDeliveryMethod(dropoffLocations[0]);
    setRetrieveMethod(dropoffLocations[0]);
  }, [dropoffLocations, deliveryLocations]);

  // Optional: Third useEffect to log state changes (for debugging)
  useEffect(() => {
    console.log("dropoffLocations updated:", dropoffLocations);
  }, [dropoffLocations]);
  useEffect(() => {
    console.log("deliveryLocations updated:", deliveryLocations);
  }, [deliveryLocations]);

  // Add new useEffect to handle retail connections
  useEffect(() => {
    const fetchRetailConnections = async () => {
      const connections = localStorage.getItem("retailConnections");
      if (connections) {
        const connectionArray = connections.split(",");
        setRetailConnections(connectionArray);
        if (connectionArray.length > 0) {
          setSelectedRetailId(connectionArray[0]);

          // Fetch all retail names
          const namesMap: { [key: string]: string } = {};
          await Promise.all(
            connectionArray.map(async (retailId) => {
              try {
                const retailDoc = await getDoc(doc(db, "retail", retailId));
                if (retailDoc.exists()) {
                  namesMap[retailId] = retailDoc.data().name;
                }
              } catch (error) {
                console.error("Error fetching retail name:", error);
              }
            })
          );
          setRetailNames(namesMap);
        }
      }
    };
    fetchRetailConnections();
  }, []);

  // Add new useEffect to fetch retail name when selectedRetailId changes
  useEffect(() => {
    const fetchRetailName = async () => {
      if (selectedRetailId) {
        try {
          const retailDoc = await getDoc(doc(db, "retail", selectedRetailId));
          if (retailDoc.exists()) {
            setSelectedRetailName(retailDoc.data().name);
          }
        } catch (error) {
          console.error("Error fetching retail name:", error);
        }
      }
    };
    fetchRetailName();
  }, [selectedRetailId]);

  const handlePaymentMethodClick = async (method: string, orderId: string) => {
    const functions = getFunctions(app, "europe-west1");

    try {
      console.log(`Processing payment with ${method} for order ID: ${orderId}`);

      if (method === "vipps") {
        const orderRef = doc(db, "b2cOrders", orderId);
        const orderSnap = await getDoc(orderRef);
        if (!orderSnap.exists()) {
          throw new Error("Order does not exist");
        }
        const orderData = orderSnap.data();
        console.log("Order data", orderData.contactInfo.phone);

        const createVippsCheckout = httpsCallable(
          functions,
          "createVippsCheckoutFiksePortal"
        );
        const result = await createVippsCheckout(orderData);

        // Ensure TypeScript understands the expected structure of the result
        if (result.data && typeof result.data === "object" && "url" in result.data) {
          const paymentUrl = (result.data as { url: string }).url;
          window.location.href = paymentUrl;
        } else {
          throw new Error("Unexpected response from Vipps checkout function");
        }
      } else {
        // Simulate a payment processing delay for other methods
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Update Firestore document to mark the order as paid
        const orderRef = doc(db, "b2cOrders", orderId);
        await updateDoc(orderRef, {
          paid: true,
        });

        console.log(`Order ${orderId} marked as paid using ${method}.`);
        console.log("Sending SMS to notify the customer...", phoneNumber);
        await sendSMS(
          phoneNumber,
          orderId,
          "Your order has been successfully paid for. We will notify you when it is ready for pickup."
        );
        // Trigger the payment success callback to update the UI
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      alert("An error occurred while processing the payment. Please try again.");
    }
  };

  const functions = getFunctions(undefined, "europe-west1");
  const fetchPhoneNumberData = httpsCallable<{ phone: string }, ApiResponse>(
    functions,
    "fetchPhoneNumberData"
  );

  const handleLookupClick = async () => {
    if (phoneNumber.length === 8) {
      setLookupStatus("Looking up...");
      try {
        const response = await fetchPhoneNumberData({ phone: phoneNumber });

        if (response.data && Object.keys(response.data).length > 0) {
          const data: ApiResponse = response.data;
          setLookupData(data);
          setFullName(
            `${data.firstName}${data.middleName ? " " + data.middleName : ""}${
              data.lastName ? " " + data.lastName : ""
            }`
          );
          setAddress(`${data.address}, ${data.postalCode} ${data.city}`);
          setLookupStatus("Lookup successful");
        } else {
          setLookupData(null);
          setFullName(""); // Clear the fields to show placeholders if no data
          setAddress("");
          setLookupStatus("No information found");
        }
      } catch (error) {
        console.error("Error fetching lookup data: ", error);
        setLookupStatus("Lookup failed");
      }
    } else {
      setLookupStatus("Phone number must be 8 digits");
    }
  };

  const calculateTotalPrice = (orderInfo: OrderInfo[]) => {
    const total = orderInfo.reduce((sum, item) => sum + item.price_nok, 0);

    if (discount !== null) {
      if (discount.value < 1) {
        // Prosent-rabatt
        return Math.round(total - total * discount.value);
      } else if (discount.value >= 1) {
        // Krone-rabatt
        return Math.round(Math.max(total - discount.value, 0)); // Sørg for at totalen ikke blir negativ
      }
    }

    return Math.round(total); // Ingen rabatt
  };

  const uploadImagesAndGetUrls = async (orderInfo: OrderInfo[]) => {
    const uploadPromises = orderInfo.map(async (item, index) => {
      if (item.imageUrl.startsWith("data:image")) {
        const storageRef = ref(storage, `orders/${Date.now()}_${index}.jpg`);
        await uploadString(storageRef, item.imageUrl, "data_url");
        const url = await getDownloadURL(storageRef);
        return { ...item, imageUrl: url };
      }
      return item;
    });

    return Promise.all(uploadPromises);
  };

  const generateCustomOrderId = () => {
    const letters = Array.from({ length: 5 }, () =>
      String.fromCharCode(65 + Math.floor(Math.random() * 26))
    ).join("");

    const numbers = Array.from({ length: 6 }, () => Math.floor(Math.random() * 10)).join(
      ""
    );

    return `${letters}${numbers}B2C`; // Using "B2C" as the suffix
  };

  const handlePaymentClick = async () => {
    if (isFormValid) {
      try {
        setUploading(true);

        // Get email based on priority: emailPassedTrough > Firebase Auth > null
        let userEmail = null;
        if (emailPassedTrough) {
          userEmail = emailPassedTrough;
        } else {
          const auth = getAuth();
          userEmail = auth.currentUser?.email || null;
        }

        const sanitizedOrderInfo = await uploadImagesAndGetUrls(garments);

        const initialActivity = {
          id: 1,
          content: "orderStateCreated",
          date: new Date().toLocaleDateString(),
          dateTime: new Date().toISOString(), //Todo: Ved å fjerne denne går ikke bestillinger til vipps. fiks dette forløpende
          timestamp: new Date().toISOString(),
        };

        const customOrderId = generateCustomOrderId();

        const orderData = {
          id: customOrderId, // Include the generated order ID
          email: userEmail, // Use the determined email
          orderInfo: sanitizedOrderInfo,
          contactInfo: {
            ...contactInfo,
            phone: phoneNumber,
            name: fullName,
            address,
          },
          date: new Date().toISOString(),
          totalPrice: `${calculateTotalPrice(sanitizedOrderInfo)} kr`,
          deliveryMethod,
          retrieveMethod,
          receiveMarketing,
          marketingEmail: receiveMarketing ? marketingEmail : null,
          activity: [initialActivity],
          orderCreationUrl: window.location.href,
          discount: discount,
        };
        const docRef = doc(db, "b2cOrders", customOrderId);

        if (attributes?.includes("Brands")) {
          //adding extra information just for Brands page

          const retailId = localStorage.getItem("retailConnections");
          let retailName = "";

          if (retailId) {
            try {
              const retailDoc = await getDoc(doc(db, "retail", retailId));
              if (retailDoc.exists()) {
                retailName = retailDoc.data().name;
              }
            } catch (error) {
              console.error("Error fetching retail name:", error);
            }
          }

          const brandOrderData = {
            ...orderData,
            paid: true,
            checkoutAttributes: attributes,
            claim: claimStatus,
            ...(claimStatus && { claimNote: claimNote }),
            brand: selectedRetailName, // Use the selected retail name
            retailId: selectedRetailId, // Add the selected retail ID
          };
          await setDoc(docRef, brandOrderData);
        } else {
          await setDoc(docRef, orderData);
          const orderUrl = `https://fiksefirstapp.web.app/orders/b2c/${customOrderId}#scanned`;
          const qrCodeDataUrl = await QRCode.toDataURL(orderUrl);
          const qrCodeStorageRef = ref(storage, `qrcodes/${customOrderId}.png`);
          await uploadString(qrCodeStorageRef, qrCodeDataUrl, "data_url");
          const qrCodeUrl = await getDownloadURL(qrCodeStorageRef);
          await updateDoc(docRef, { qrCodeUrl });
          // HARDCODE workaround to just skip to vipps payment
          await handlePaymentMethodClick("vipps", customOrderId);

          localStorage.setItem("activeOrder", `b2c/${customOrderId}`);
        }

        navigate(`/orders/${customOrderId}`);

        onClose();
      } catch (error) {
        console.error("Error saving order: ", error);
        alert("An error occurred while saving the order.");
      } finally {
        setUploading(false);
      }
    }
  };

  console.log("atributess", attributes);

  return (
    <div className="flex h-full items-center justify-center bg-black bg-opacity-50">
      <div className="flex max-h-[calc(100vh)] w-full max-w-lg flex-col overflow-auto bg-white shadow-xl">
        <div className="z-10 flex items-center justify-between border-b border-black bg-white">
          <div className="flex items-center">
            <button
              type="button"
              className="p-4 text-2xl text-gray-500"
              onClick={onBackToCart}
            >
              &larr;
            </button>
            <h1 className="text-2xl">{t("checkout.orderTitle")}</h1>
          </div>
          <button type="button" className="p-4 text-2xl text-gray-500" onClick={onClose}>
            ×
          </button>
        </div>

        <div className="flex-1 overflow-y-auto">
          <div className="border-b border-black">
            <label className="mt-2 block px-4 pt-4 text-sm font-medium text-gray-700">
              {!attributes?.includes("Brands")
                ? t("checkout.updatePrompt")
                : "Kundens informasjon (Valgfritt)"}
            </label>

            <label className="block px-4 pb-4 pt-1 text-sm font-medium text-gray-400">
              {t("checkout.forenNumber")}
            </label>

            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder={t("checkout.phoneNumber")}
              className="text-md block w-full border-t border-black bg-[#f7f6eb] p-4"
            />
          </div>
          {phoneNumber.length >= 8 && (
            <>
              <div className="border-b border-black">
                <input
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder={t("checkout.namePlaceholder")}
                  className="text-md block w-full border-b border-black bg-[#f7f6eb] p-4"
                />
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder={t("checkout.address")}
                  className="text-md block w-full border-black bg-[#f7f6eb] p-4"
                />
              </div>
            </>
          )}
          {/*localStorage.getItem("retailConnections")?.includes(",") checks if there are mutiple retail connections */}
          {/*attributes?.includes("Brands") && localStorage.getItem("retailConnections")?.includes(",")
          This is the start of enabling emails with mutiple retail connection from here have a dropdown menue that can swtich between the brands*/}

          {emailPassedTrough && (
            <div className="border-b border-black bg-fikseDisabled p-4">
              <p className="text-gray-800">{emailPassedTrough}</p>
            </div>
          )}

          {/* Move retail selection here, before dropoff section */}
          {attributes?.includes("Brands") && retailConnections.length > 1 && (
            <div className="border-b border-black">
              <label className="block px-4 pt-4 text-sm font-medium text-gray-700">
                Velg merke
              </label>
              <div className="relative">
                <select
                  value={selectedRetailId}
                  onChange={async (e) => {
                    const newRetailId = e.target.value;
                    setSelectedRetailId(newRetailId);
                  }}
                  className="text-md block w-full appearance-none rounded-none border-t border-black bg-[#f7f6eb] p-4"
                >
                  {retailConnections.map((retailId) => (
                    <option key={retailId} value={retailId}>
                      {retailNames[retailId] || retailId}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <ChevronDownIcon className="mr-4 h-6 w-6" aria-hidden="true" />
                </div>
              </div>
            </div>
          )}

          {/* DELIVERY COMPONENT - TODO: DECOMPOSE*/}
          <div className="pb-4">
            <label className="mt-8 block p-4 text-sm font-medium text-gray-700">
              {t("checkout.deliverySectionTitle")}{" "}
            </label>
            <p className="border-b border-black p-4 pb-8 text-sm text-gray-500">
              {t("checkout.deliveryDescription")}
            </p>
            <div className="relative">
              <select
                value={deliveryMethod}
                onChange={(e) => {
                  const value = e.target.value;
                  setDeliveryMethod(value);
                  // Only synchronize if the other method is a drop-off location
                  if (
                    dropoffLocations.includes(value) &&
                    dropoffLocations.includes(retrieveMethod)
                  ) {
                    setRetrieveMethod(value);
                  }
                }}
                className={`text-md block w-full appearance-none rounded-none border-b border-black p-4 ${
                  deliveryMethod === "Send it by courier + 89.-"
                    ? "bg-white"
                    : "bg-[#8cd7ff]"
                }`}
              >
                {dropoffLocations.map((location, index) => (
                  <option className="p-4" key={index} value={location}>
                    {location}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                {dropoffLocations.length > 1 && (
                  <ChevronDownIcon className="mr-4 h-6 w-6" aria-hidden="true" />
                )}{" "}
              </div>
            </div>
            {/* Sendet med bud knapp
            !attributes?.includes("Office") && !attributes?.includes("Brands") && (
              <button
                type="button"
                onClick={() => setDeliveryMethod("Send it by courier + 89.-")}
                className={`text-md block w-full rounded-none border-b border-black p-4 text-left ${
                  deliveryMethod === "Send it by courier + 89.-"
                    ? "bg-[#8cd7ff]"
                    : "bg-white"
                }`}
              >
                Send it by courier + 89.-
              </button>
            )*/}
          </div>
          <div className="">
            <label className="mt-8 block p-4 text-sm font-medium text-gray-700">
              {t("checkout.retrievalPrompt")}
            </label>
            <div className="relative">
              <select
                value={retrieveMethod}
                onChange={(e) => {
                  const value = e.target.value;
                  setRetrieveMethod(value);
                  // Only synchronize if the other method is a drop-off location
                  if (
                    dropoffLocations.includes(value) &&
                    dropoffLocations.includes(deliveryMethod)
                  ) {
                    setDeliveryMethod(value);
                  }
                }}
                className={`text-md mt-4 block w-full appearance-none rounded-none border-b border-t border-black p-4 ${
                  retrieveMethod === "Home delivery + 89.-" ? "bg-white" : "bg-[#8cd7ff]"
                }`}
              >
                {dropoffLocations.map((location, index) => (
                  <option className="p-4" key={index} value={location}>
                    {location}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                {dropoffLocations.length > 1 && (
                  <ChevronDownIcon className="mr-4 h-6 w-6" aria-hidden="true" />
                )}
              </div>
            </div>
            {/* Hjemmelevering knapp
            !attributes?.includes("Office") && !attributes?.includes("Brands") && (
              <button
                type="button"
                onClick={() => setRetrieveMethod("Home delivery + 89.-")}
                className={`text-md mb-8 block w-full rounded-none border-b border-black p-4 text-left ${
                  retrieveMethod === "Home delivery + 89.-" ? "bg-[#8cd7ff]" : "bg-white"
                }`}
              >
                Home delivery + 89.-
              </button>
            )*/}
          </div>

          {attributes?.includes("Brands") && (
            <div
              className={`text-md black mt-16 flex cursor-pointer items-center justify-between border-t border-black p-4 ${claimStatus ? `border-b border-b-gray-300` : `border-b`} `}
              onClick={() => setClaimStatus(!claimStatus)}
            >
              <label htmlFor="acceptTerms" className="text-md mr-4 w-2/3 text-gray-900">
                Reklamasjon/Claim
              </label>
              <div className="relative h-12 w-12">
                <input
                  id="acceptTerms"
                  type="checkbox"
                  checked={claimStatus}
                  readOnly
                  className="h-full w-full cursor-pointer appearance-none rounded-full border-2 border-black"
                />
                <div
                  className={`absolute inset-0 m-[1pt] flex items-center justify-center rounded-full transition-colors duration-300 ${
                    claimStatus ? "bg-[#8cd7ff]" : ""
                  }`}
                >
                  {claimStatus && <CheckIcon className="h-5 w-5" />}
                </div>
              </div>
            </div>
          )}
          {claimStatus && (
            <textarea
              className="max-h-40 min-h-5 w-full resize-y overflow-y-auto border-b border-black p-4 text-left transition-[height] duration-300 ease-in-out"
              placeholder="Skriv beskrivelse her"
              value={claimNote}
              onChange={(e) => setClaimNote(e.target.value)}
            />
          )}

          {!attributes?.includes("Brands") && (
            <>
              <div className="border-b border-black">
                <h2 className="mt-8 p-4 text-lg font-medium text-gray-900">
                  {t("checkout.termsAndConditions")}{" "}
                </h2>
                <div
                  className="text-md flex cursor-pointer items-center justify-between border-t border-black p-4"
                  onClick={() => setAcceptTerms(!acceptTerms)}
                >
                  <label
                    htmlFor="acceptTerms"
                    className="text-md mr-4 w-2/3 text-gray-900"
                  >
                    {t("checkout.acceptTerms1")}
                    <a href="/kjopsvilkar" className="text-[#5c6ef8] underline">
                      {t("checkout.acceptTerms2")}
                    </a>
                    {t("checkout.acceptTerms3")}
                  </label>
                  <div className="relative h-12 w-12">
                    <input
                      id="acceptTerms"
                      type="checkbox"
                      checked={acceptTerms}
                      readOnly
                      className="h-full w-full cursor-pointer appearance-none rounded-full border-2 border-black"
                    />
                    <div
                      className={`absolute inset-0 m-[1pt] flex items-center justify-center rounded-full transition-colors duration-300 ${
                        acceptTerms ? "bg-[#8cd7ff]" : ""
                      }`}
                    >
                      {acceptTerms && <CheckIcon className="h-5 w-5" />}
                    </div>
                  </div>
                </div>

                <div
                  className="text-md flex cursor-pointer items-center justify-between border-t border-black p-4"
                  onClick={() => setReceiveMarketing(!receiveMarketing)}
                >
                  <label
                    htmlFor="receiveMarketing"
                    className="text-md mr-4 w-2/3 text-gray-900"
                  >
                    {t("checkout.marketingOptIn")}
                  </label>
                  <div className="relative h-12 w-12">
                    <input
                      id="receiveMarketing"
                      type="checkbox"
                      checked={receiveMarketing}
                      readOnly
                      className="h-full w-full cursor-pointer appearance-none rounded-full border-2 border-black"
                    />
                    <div
                      className={`absolute inset-0 m-[1pt] flex items-center justify-center rounded-full transition-colors duration-300 ${
                        receiveMarketing ? "bg-[#8cd7ff]" : ""
                      }`}
                    >
                      {receiveMarketing && <CheckIcon className="h-5 w-5" />}
                    </div>
                  </div>
                </div>

                {receiveMarketing && (
                  <div className="border-t border-black">
                    <input
                      type="email"
                      value={marketingEmail}
                      onChange={(e) => setMarketingEmail(e.target.value)}
                      placeholder="What is your email?"
                      className="text-md block w-full border-b border-black bg-[#f7f6eb] p-4"
                    />
                  </div>
                )}
              </div>
            </>
          )}

          <div className="border-b border-black pb-4">
            <h2 className="mt-4 p-4 text-lg font-medium text-gray-900">
              {t("checkout.orderTitle")}
            </h2>
            {garments.map((item, index) => (
              <div key={index} className="mt-2 flex items-center justify-between p-4">
                <span>
                  {item.item} - {item.problem} ({item.fix_type})
                </span>
                <span>{item.price_nok} kr</span>
              </div>
            ))}
            {discount && (
              <div className="mt-2 flex items-center justify-between bg-green-200">
                <span className="p-4"> {discount.name} aktivert</span>
                <span className="p-4">
                  -{" "}
                  {discount.value < 1
                    ? discount.value * 100 + "%"
                    : discount.value + " kr"}
                </span>
              </div>
            )}
            <div className="mt-2 flex items-center justify-between">
              <span className="p-4"> {t("checkout.total")}</span>
              <span className="p-4">{calculateTotalPrice(garments)} kr</span>
            </div>
          </div>

          <div className="flex border-b border-black">
            <input
              type="text"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
              placeholder="Har du en rabattkode?"
              className="flex-grow p-4 sm:text-sm"
            />
            <button
              onClick={checkDiscountValidity}
              className="border-grey-500 flex-none whitespace-nowrap border-l bg-fikseBeige px-4"
            >
              Bruk kode
            </button>
          </div>
          {discountStatus && discountStatus != "Discount applied." && (
            <p
              className={`bg-pink-200 p-4 text-sm ${
                discountStatus.startsWith("Discount applied")
                  ? "bg-green-200"
                  : "text-black"
              }`}
            >
              {discountStatus}
            </p>
          )}

          <div className="my-2 mt-6 flex justify-center pb-4">
            <FikseCatLady className="h-auto w-24" />
          </div>
          <div className="border-black px-4 pb-4">
            <p className="text-center text-sm text-black">{t("checkout.deliveryTime")}</p>
          </div>
          <button
            type="button"
            onClick={handlePaymentClick}
            className={`mb-12 mt-auto w-full rounded-none border-b border-t border-black py-4 pl-4 text-left text-lg ${
              isFormValid
                ? "bg-[#D3FF8C] text-black"
                : "cursor-not-allowed bg-[#e0ffd0] text-gray-500"
            }`}
            disabled={!isFormValid || uploading}
          >
            {uploading
              ? "Uploading..."
              : !attributes?.includes("Brands")
                ? t("checkout.proceedToPayment")
                : "Send inn bestilling"}
          </button>
        </div>

        <div className="my-2 text-center text-xs">
          <span>fikse.co ©</span>
        </div>
      </div>
    </div>
  );
};

export default CheckoutB2C;
