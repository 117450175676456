import React, { useEffect, useState } from "react";

import {
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { sendSMS } from "../../components/contact";
import { db } from "../../firebase";
import { Order } from "../../pages/OrderPage";

interface OrderSummary extends Order {
  pageName: string | null;
}

const FikseAdminOverview: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(new Date("2024-10-15"));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [orders, setOrders] = useState<OrderSummary[]>([]);
  const [loading, setLoading] = useState(true);
  const [uniquePages, setUniquePages] = useState<string[]>([]);
  const [selectedPages, setSelectedPages] = useState<string[]>([]);
  const [excludeTestOrders, setExcludeTestOrders] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [orderToDelete, setOrderToDelete] = useState<string | null>(null);

  const { t } = useTranslation();

  const getPageName = (url: string | undefined) => {
    if (!url) return null;
    try {
      const urlWithoutHash = url.split("#")[0];
      const urlWithoutQuery = urlWithoutHash.split("?")[0];
      const segments = urlWithoutQuery.split("/").filter(Boolean);
      const lastSegment = segments[segments.length - 1];
      return lastSegment || null;
    } catch (error) {
      console.error("Error parsing URL:", error);
      return null;
    }
  };

  const isTestOrder = (order: Order) => {
    const testPhone = "97811811";
    const testEmails = ["simon@ntnu.no", "@fikse.co"];

    return (
      order.contactInfo?.phone === testPhone ||
      testEmails.some((email) => order.email?.toLowerCase().includes(email.toLowerCase()))
    );
  };

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const ordersRef = collection(db, "b2cOrders");
      const q = query(ordersRef);
      const querySnapshot = await getDocs(q);

      const fetchedOrders = querySnapshot.docs.map((doc) => {
        const data = doc.data() as Order;
        return {
          ...data,
          id: doc.id,
          pageName: getPageName(data.orderCreationUrl),
        };
      });

      // Extract and deduplicate page names, removing nulls and undefined values
      const pageNames = fetchedOrders
        .map((order) => order.pageName)
        .filter(
          (pageName): pageName is string => pageName !== null && pageName !== undefined
        );
      const uniquePages = Array.from(new Set(pageNames));

      setUniquePages(uniquePages);
      setOrders(fetchedOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const filteredOrders = orders
    .filter((order) => {
      const orderDate = new Date(order.date);
      const dateFilter = orderDate >= startDate && orderDate <= endDate;
      const pageFilter =
        selectedPages.length === 0 || selectedPages.includes(order.pageName || "");
      const testOrderFilter = !excludeTestOrders || !isTestOrder(order);

      // Enhanced search filter
      const searchLower = searchQuery.toLowerCase();
      const searchFilter =
        searchQuery === "" ||
        order.id.toLowerCase().includes(searchLower) ||
        order.contactInfo?.name?.toLowerCase().includes(searchLower) ||
        order.contactInfo?.phone?.toLowerCase().includes(searchLower) ||
        // Search through orderInfo array
        order.orderInfo?.some(
          (info) =>
            info.item?.toLowerCase().includes(searchLower) ||
            info.description?.toLowerCase().includes(searchLower) ||
            info.work?.toLowerCase().includes(searchLower) ||
            info.problem?.toLowerCase().includes(searchLower)
        );

      return dateFilter && pageFilter && testOrderFilter && searchFilter;
    })
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()); // Sort by date descending

  const pageOptions = uniquePages.map((page) => ({
    value: page,
    label: page,
  }));

  const calculateStats = (orders: OrderSummary[]) => {
    const totalOrders = orders.length;
    const totalPrice = orders.reduce(
      (sum, order) => sum + parseFloat(order.totalPrice || "0"),
      0
    );
    const averagePrice = totalOrders > 0 ? totalPrice / totalOrders : 0;

    return {
      totalOrders,
      totalPrice: totalPrice.toFixed(2),
      averagePrice: averagePrice.toFixed(2),
    };
  };

  const generatePageStats = (orders: OrderSummary[]) => {
    const pageStats: {
      [key: string]: { orders: number; totalSales: number; avgOrderValue: number };
    } = {};

    orders.forEach((order) => {
      const pageName = order.pageName || "Unknown";
      if (!pageStats[pageName]) {
        pageStats[pageName] = { orders: 0, totalSales: 0, avgOrderValue: 0 };
      }
      pageStats[pageName].orders++;
      pageStats[pageName].totalSales += parseFloat(order.totalPrice || "0");
    });

    // Calculate averages
    Object.keys(pageStats).forEach((page) => {
      pageStats[page].avgOrderValue = pageStats[page].totalSales / pageStats[page].orders;
    });

    return pageStats;
  };

  const generateMonthlyStats = (orders: OrderSummary[]) => {
    // Get unique years and months from filtered orders
    const yearMonths = new Map<number, Set<string>>();
    orders.forEach((order) => {
      const date = new Date(order.date);
      const year = date.getFullYear();
      if (!yearMonths.has(year)) {
        yearMonths.set(year, new Set());
      }
      yearMonths.get(year)?.add(date.toLocaleString("default", { month: "short" }));
    });

    // Convert to sorted arrays
    const years = Array.from(yearMonths.keys()).sort();
    const monthsByYear = new Map(
      years.map((year) => [
        year,
        Array.from(yearMonths.get(year) || []).sort((a, b) => {
          return new Date(`${a} 1`).getTime() - new Date(`${b} 1`).getTime();
        }),
      ])
    );

    // Initialize stats object with filtered pages only
    const selectedPageNames = selectedPages.length > 0 ? selectedPages : uniquePages;
    const stats: {
      [page: string]: {
        total: number;
        yearlyTotals: { [yearMonth: string]: number };
        yearlyAverages: { [yearMonth: string]: number };
        yearlyOrders: { [yearMonth: string]: number };
      };
    } = {};

    selectedPageNames.forEach((page) => {
      stats[page] = {
        total: 0,
        yearlyTotals: {},
        yearlyAverages: {},
        yearlyOrders: {},
      };

      years.forEach((year) => {
        monthsByYear.get(year)?.forEach((month) => {
          const key = `${year}-${month}`;
          stats[page].yearlyTotals[key] = 0;
          stats[page].yearlyAverages[key] = 0;
          stats[page].yearlyOrders[key] = 0;
        });
      });
    });

    // Calculate stats
    orders.forEach((order) => {
      const page = order.pageName || "Unknown";
      if (!selectedPageNames.includes(page)) return;

      const date = new Date(order.date);
      const year = date.getFullYear();
      const month = date.toLocaleString("default", { month: "short" });
      const key = `${year}-${month}`;
      const price = parseFloat(order.totalPrice || "0");

      stats[page].total += price;
      stats[page].yearlyTotals[key] = (stats[page].yearlyTotals[key] || 0) + price;
      stats[page].yearlyOrders[key] = (stats[page].yearlyOrders[key] || 0) + 1;
    });

    // Calculate averages
    Object.keys(stats).forEach((page) => {
      Object.keys(stats[page].yearlyTotals).forEach((key) => {
        if (stats[page].yearlyOrders[key] > 0) {
          stats[page].yearlyAverages[key] =
            stats[page].yearlyTotals[key] / stats[page].yearlyOrders[key];
        }
      });
    });

    return { stats, years, monthsByYear };
  };

  const downloadCSV = () => {
    const { stats, years, monthsByYear } = generateMonthlyStats(filteredOrders);
    let csvContent = "";

    // Create year headers
    let yearHeader = "Metric,Total";
    let monthHeader = ",";
    years.forEach((year) => {
      const monthsInYear = monthsByYear.get(year) || [];
      yearHeader += "," + new Array(monthsInYear.length).fill(year).join(",");
      monthHeader += "," + monthsInYear.join(",");
    });
    csvContent = yearHeader + "\n" + monthHeader + "\n\n";

    // Helper function to get all year-month combinations
    const getAllPeriods = () => {
      const periods: string[] = [];
      years.forEach((year) => {
        monthsByYear.get(year)?.forEach((month) => {
          periods.push(`${year}-${month}`);
        });
      });
      return periods;
    };

    // Group statistics by page
    Object.keys(stats).forEach((page) => {
      csvContent += "\n";
      const periods = getAllPeriods();

      // Total sales
      const salesRow = [`${page} - Total Sales`, stats[page].total.toFixed(2)];
      periods.forEach((period) => {
        salesRow.push((stats[page].yearlyTotals[period] || 0).toFixed(2));
      });
      csvContent += salesRow.join(",") + "\n";

      // Average price
      const avgRow = [
        `${page} - Average Price`,
        (
          stats[page].total /
            Object.values(stats[page].yearlyOrders).reduce((a, b) => a + b, 0) || 0
        ).toFixed(2),
      ];
      periods.forEach((period) => {
        avgRow.push((stats[page].yearlyAverages[period] || 0).toFixed(2));
      });
      csvContent += avgRow.join(",") + "\n";

      // Order count
      const countRow = [
        `${page} - Order Count`,
        Object.values(stats[page].yearlyOrders).reduce((a, b) => a + b, 0),
      ];
      periods.forEach((period) => {
        countRow.push((stats[page].yearlyOrders[period] || 0).toString());
      });
      csvContent += countRow.join(",") + "\n";
    });

    // Add blank line before totals
    csvContent += "\n\n";

    // Calculate and add totals for all pages
    const totalStats = {
      sales: Object.values(stats).reduce((acc, page) => acc + page.total, 0),
      orders: Object.values(stats).reduce(
        (acc, page) => acc + Object.values(page.yearlyOrders).reduce((a, b) => a + b, 0),
        0
      ),
    };

    // Total sales across all pages
    const totalSalesRow = ["TOTAL - All Pages Sales", totalStats.sales.toFixed(2)];
    const periods = getAllPeriods();
    periods.forEach((period) => {
      const monthTotal = Object.values(stats).reduce(
        (acc, page) => acc + (page.yearlyTotals[period] || 0),
        0
      );
      totalSalesRow.push(monthTotal.toFixed(2));
    });
    csvContent += totalSalesRow.join(",") + "\n";

    // Average price across all pages
    const totalAvgRow = [
      "TOTAL - All Pages Average",
      (totalStats.sales / totalStats.orders).toFixed(2),
    ];
    periods.forEach((period) => {
      const monthOrders = Object.values(stats).reduce(
        (acc, page) => acc + (page.yearlyOrders[period] || 0),
        0
      );
      const monthTotal = Object.values(stats).reduce(
        (acc, page) => acc + (page.yearlyTotals[period] || 0),
        0
      );
      totalAvgRow.push(monthOrders > 0 ? (monthTotal / monthOrders).toFixed(2) : "0.00");
    });
    csvContent += totalAvgRow.join(",") + "\n";

    // Total order count across all pages
    const totalOrdersRow = ["TOTAL - All Pages Orders", totalStats.orders];
    periods.forEach((period) => {
      const monthOrders = Object.values(stats).reduce(
        (acc, page) => acc + (page.yearlyOrders[period] || 0),
        0
      );
      totalOrdersRow.push(monthOrders.toString());
    });
    csvContent += totalOrdersRow.join(",") + "\n";

    // Add final blank line
    csvContent += "\n";

    // Download the file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "order_statistics.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDelete = async (orderId: string) => {
    try {
      await deleteDoc(doc(db, "b2cOrders", orderId));
      setOrderToDelete(null);
      // Refresh the orders list
      await fetchOrders();
    } catch (error) {
      console.error("Error deleting order:", error);
      alert("Failed to delete order");
    }
  };

  return (
    <div className="min-h-screen p-4">
      <div className="mb-8 grid grid-cols-1 gap-4 sm:grid-cols-3">
        <div className="rounded-lg border border-black p-4">
          <h3 className="text-sm font-medium text-gray-500">Total Orders</h3>
          <p className="mt-1 text-3xl font-semibold">
            {calculateStats(filteredOrders).totalOrders}
          </p>
        </div>
        <div className="rounded-lg border border-black p-4">
          <h3 className="text-sm font-medium text-gray-500">Total Sales</h3>
          <p className="mt-1 text-3xl font-semibold">
            {calculateStats(filteredOrders).totalPrice} NOK
          </p>
        </div>
        <div className="rounded-lg border border-black p-4">
          <h3 className="text-sm font-medium text-gray-500">Average Order Value</h3>
          <p className="mt-1 text-3xl font-semibold">
            {calculateStats(filteredOrders).averagePrice} NOK
          </p>
        </div>
      </div>

      <div className="mb-8 space-y-4 border-b border-black pb-4">
        {/* Add search bar above the date filters */}
        <div className="mb-4 w-full">
          <input
            type="text"
            placeholder="Search by customer name, phone, or order ID..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full rounded border border-black p-2 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
        </div>

        <div className="flex flex-wrap gap-4">
          <div className="w-full md:w-auto">
            <label className="block text-sm font-medium text-gray-700">From Date</label>
            <DatePicker
              selected={startDate}
              onChange={(date: Date | null) => date && setStartDate(date)}
              className="w-full rounded border border-black p-2"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="w-full md:w-auto">
            <label className="block text-sm font-medium text-gray-700">To Date</label>
            <DatePicker
              selected={endDate}
              onChange={(date: Date | null) => date && setEndDate(date)}
              className="w-full rounded border border-black p-2"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="w-full md:w-64">
            <label className="block text-sm font-medium text-gray-700">
              Filter by Page
            </label>
            <Select
              isMulti
              options={pageOptions}
              onChange={(selected) =>
                setSelectedPages(selected.map((option) => option.value))
              }
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
          <div className="flex w-full items-center gap-2 md:w-auto">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <input
                type="checkbox"
                checked={excludeTestOrders}
                onChange={(e) => setExcludeTestOrders(e.target.checked)}
                className="h-4 w-4 rounded border-gray-300"
              />
              Exclude Test Orders
            </label>
          </div>
        </div>
      </div>

      <button
        onClick={downloadCSV}
        className="mb-4 rounded bg-fikseGreen px-4 py-2 text-black hover:bg-green-300"
      >
        Download CSV
      </button>

      {loading ? (
        <div className="text-center">Loading...</div>
      ) : (
        <div className="space-y-4">
          {filteredOrders.map((order) => (
            <div key={order.id} className="border-b border-black p-4">
              <div className="flex flex-wrap justify-between gap-4">
                <div>
                  <h3 className="text-lg font-medium">
                    Order ID:{" "}
                    <a
                      href={`/orders/${order.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 hover:underline"
                    >
                      {order.id.slice(0, 5)}
                    </a>
                  </h3>
                  <p>Customer: {order.contactInfo?.name || "N/A"}</p>
                  <p>Phone: {order.contactInfo?.phone}</p>
                  <p>
                    Date:{" "}
                    {new Date(order.date).toLocaleString("en-GB", {
                      hour: "2-digit",
                      minute: "2-digit",
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </p>
                  <p>Total Price: {order.totalPrice} NOK</p>
                  {!order.paid && <p className="text-red-500">Order Not Paid</p>}
                </div>
                <div>
                  <p>Source: {order.pageName || "Unknown"}</p>
                  <p>
                    Status:{" "}
                    {order.activity && order.activity.length > 0
                      ? t(
                          `workwearOrderPage.${order.activity[order.activity.length - 1].content}`
                        )
                      : "No status"}
                  </p>
                  <button
                    onClick={() => setOrderToDelete(order.id)}
                    className="mt-2 rounded bg-fikseRed px-3 py-1 text-black hover:bg-red-400"
                  >
                    Delete Order
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Confirmation Modal */}
      {orderToDelete && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="rounded-lg bg-white p-6 shadow-xl">
            <h3 className="mb-4 text-lg font-bold">Confirm Deletion</h3>
            <p className="mb-6">
              Are you sure you want to delete order {orderToDelete.slice(0, 5)}? This
              action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setOrderToDelete(null)}
                className="rounded bg-gray-300 px-4 py-2 hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDelete(orderToDelete)}
                className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FikseAdminOverview;
