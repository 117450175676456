import React, { useState } from "react";

import ModalTile from "../components/ModalTile";
import { sendSMS } from "../components/contact";

export default function SendSMS() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const generateRandomSlug = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "MANUAL_";
    for (let i = 0; i < 20; i++) {
      // Generate 12 chars (MANUAL_ + 12 = 19 total)
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setMessageTitle(result);
  };

  const handleSendSMS = async () => {
    await sendSMS(phoneNumber, messageTitle, messageContent);
    setIsConfirmModalOpen(false);

    // Clear fields after sending
    setPhoneNumber("");
    setMessageTitle("");
    setMessageContent("");
  };

  return (
    <div className="min-h-screen p-8">
      <h2 className="mb-6 text-2xl font-bold">SMS Dashboard</h2>
      <div className="max-w-2xl border border-black p-6">
        <div className="grid grid-cols-1 gap-4">
          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700">
              Phone Number
            </label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="w-full rounded border border-black p-2"
              placeholder="Enter phone number"
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700">
              Database ID (Bare generer en Slug hvis du ikke vet hva du skal skrive her)
            </label>
            <div className="flex gap-2">
              <input
                type="text"
                value={messageTitle}
                onChange={(e) => setMessageTitle(e.target.value)}
                className="flex-1 rounded border border-black p-2"
                placeholder="Firebase_Notation_NO_SPACES"
              />
              <button
                onClick={generateRandomSlug}
                className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-600"
              >
                Generate Slug
              </button>
            </div>
          </div>

          <div>
            <label className="mb-2 block text-sm font-medium text-gray-700">
              Message Content
            </label>
            <textarea
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
              className="h-32 w-full resize-y rounded border border-black p-2"
              placeholder="Enter message content"
            />
          </div>

          <button
            onClick={() => setIsConfirmModalOpen(true)}
            className="mt-4 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
          >
            Send SMS
          </button>
        </div>
      </div>

      <ModalTile
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        title="Confirm SMS"
      >
        <div className="p-6">
          <h3 className="mb-4 text-lg font-medium">Please confirm SMS details:</h3>

          <div className="mb-4 space-y-2">
            <p>
              <strong>To:</strong> {phoneNumber}
            </p>
            <p>
              <strong>Title:</strong> {messageTitle}
            </p>
            <p>
              <strong>Message:</strong>
            </p>
            <p className="whitespace-pre-wrap rounded bg-gray-50 p-2">{messageContent}</p>
          </div>

          <div className="mt-6 flex justify-end gap-4">
            <button
              onClick={() => setIsConfirmModalOpen(false)}
              className="rounded border border-gray-300 bg-white px-4 py-2 text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handleSendSMS}
              className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            >
              Confirm & Send
            </button>
          </div>
        </div>
      </ModalTile>
    </div>
  );
}
