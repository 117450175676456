import React from "react";

import { Bar, BarChart, CartesianGrid, Cell, XAxis, YAxis } from "recharts";

//creation: https://claude.ai/chat/0ffe000e-b108-4e64-bb20-24c962719891

interface DataPoint {
  name: string;
  value: number;
  color?: string;
}

interface ClaimsBarChartProps {
  data: DataPoint[];
  title: string;
  width?: number;
  height?: number;
  className?: string;
  rotateLabels?: boolean;
}

const ClaimsBarChart: React.FC<ClaimsBarChartProps> = ({
  data,
  title,
  width = 600,
  height = 400,
  className = "",
  rotateLabels = true,
}) => {
  const defaultColors = [
    "#419329", // Paleet - darker green
    "#9cd5fb", // Høyer woman - light blue
    "#275cae", // Storo - navy blue
    "#a3d83a", // Online - light green
  ];

  const margin = { top: 20, right: 30, left: 40, bottom: rotateLabels ? 60 : 20 };
  const chartWidth = width - margin.left - margin.right;
  const BOTTOM_TEXT_OFFSET = 45; // Offset from bottom of bar

  const enrichedData = data.map((point, index) => ({
    ...point,
    fill: point.color || defaultColors[index % defaultColors.length],
  }));

  const maxValue = Math.ceil(Math.max(...data.map((d) => d.value)) * 1.2);
  const barWidth = chartWidth / enrichedData.length;

  const RoundedBar = (props: any) => {
    const { fill, x, y, width, height } = props;
    const radius = 10;

    return (
      <path
        d={`
          M${x},${y + height}
          L${x},${y + radius}
          Q${x},${y} ${x + radius},${y}
          L${x + width - radius},${y}
          Q${x + width},${y} ${x + width},${y + radius}
          L${x + width},${y + height}
          Z
        `}
        fill={props.fill}
      />
    );
  };

  return (
    <div className={`w-full max-w-2xl ${className}`}>
      <h2 className="mb-4 text-xl font-medium">{title}</h2>
      <div className="relative">
        <BarChart
          width={width}
          height={height}
          data={enrichedData}
          margin={margin}
          barSize={barWidth * 0.8}
        >
          <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            interval={0}
            axisLine={false}
            tickLine={false}
            tick={(props) => {
              const { x, y, payload, index } = props;
              return (
                <text x={x} y={y + 20} textAnchor="middle" fill="black" fontSize={14}>
                  {enrichedData[index].value}
                </text>
              );
            }}
          />
          <YAxis
            domain={[0, maxValue]}
            ticks={[
              0,
              Math.ceil(maxValue / 4),
              Math.ceil(maxValue / 2),
              Math.ceil((maxValue * 3) / 4),
              maxValue,
            ]}
          />
          <Bar dataKey="value" isAnimationActive={false} shape={<RoundedBar />}>
            {enrichedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.fill} />
            ))}
          </Bar>
          {/* Rotated name labels inside bars */}
          {enrichedData.map((entry, index) => {
            const barHeight =
              (entry.value / maxValue) * (height - margin.top - margin.bottom);
            const barX = (chartWidth / enrichedData.length) * (index + 0.5) + margin.left;
            // Position text at bottom of bar with offset
            const yPos = height - BOTTOM_TEXT_OFFSET - margin.bottom;

            // Calculate rightward offset that decreases as we move right
            const offsetMultiplier = 2 - index / (enrichedData.length - 2);
            const rightwardOffset = 20 * offsetMultiplier;

            return (
              <text
                key={`label-${index}`}
                x={barX + rightwardOffset}
                y={yPos}
                textAnchor="start" // Changed to start to align from bottom
                dominantBaseline="middle"
                fill="white"
                fontSize={14}
                fontWeight="bold"
                transform={`rotate(270, ${barX + rightwardOffset}, ${yPos})`}
                style={{ userSelect: "none" }}
              >
                {entry.name}
              </text>
            );
          })}
        </BarChart>
      </div>
    </div>
  );
};

export default ClaimsBarChart;
